import reducer from './reducer';

export default reducer;

/*
Account page

Update name
Update home airport

Loyalty page

Update loyalty program balance
Add loyalty program
Delete loyalty program

Trips / Credit Cards n/a
*/
