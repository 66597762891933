import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';

import DialogTitleWithCloseButton from 'components/Shared/DialogTitleWithCloseButton';

export default function SelectedPopper({
  children,
  dialogId,
  selected,
  trigger,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      {trigger(handleClickOpen)}
      <Dialog
        className={dialogId}
        onClose={handleClose}
        aria-labelledby={dialogId}
        open={open}
      >
        <DialogTitleWithCloseButton
          onClose={handleClose}
          title={`${selected.size} program${
            selected.size === 1 ? '' : 's'
          } selected`}
          className={dialogId}
        />
        <div id={dialogId}>{children}</div>
      </Dialog>
    </Fragment>
  );
}

SelectedPopper.propTypes = {
  children: PropTypes.node.isRequired,
  dialogId: PropTypes.string.isRequired,
  selected: PropTypes.instanceOf(Set).isRequired,
  trigger: PropTypes.func.isRequired,
};
