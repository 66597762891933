import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { NavLink as Link } from 'react-router-dom';
import clsx from 'clsx';

import Divider from '@material-ui/core/Divider';
// import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { ACCOUNT, LOGIN, ROOT, SIGNUP } from 'components/App/routeConstants';
import MenuLink from 'components/Shared/MenuLink';
import { logoutUser as logoutUserAction } from 'modules/user/actions';
import { isLoggedIn } from 'modules/user/utils';
import { userStatePropType } from 'utils/propTypes';

import { routesArray } from '../AccountRoutes/routesArray';

const headerNavItemClass = 'text-bold';
const navItemClassBase = 'nav-item-text';
const navItemPadded = 'nav-item-padded';
const navItemMuted = 'text-muted';
const activeClass = 'text-active';

const getMenuLink = (text, { activeClassName, className, key, url }) => (
  <MenuLink
    {...{
      childrenProps: {
        className,
      },
      linkProps: {
        activeClassName,
        exact: true,
        to: url,
      },
      key,
      menuProps: {
        // className,
        disableGutters: true,
      },
    }}
  >
    {text}
  </MenuLink>
);

const AccountMenuCommon = (props) => {
  const { className, logoutUser, userState } = props;

  return (
    <div className={clsx('account-menu-common', className)}>
      <Divider />
      {isLoggedIn(userState) ? (
        <Fragment>
          <MenuList className="account-menu-list">
            {routesArray.map((navItem) => {
              const { index: key, name, url } = navItem;
              const isHeader = url === ACCOUNT;
              const displayName = isHeader ? 'My Account' : name;

              return getMenuLink(displayName, {
                activeClassName: activeClass,
                className: clsx(
                  navItemClassBase,
                  isHeader ? headerNavItemClass : navItemPadded
                ),
                key,
                url,
              });
            })}
            {getMenuLink('Home', {
              activeClassName: activeClass,
              className: clsx(navItemClassBase, headerNavItemClass),
              url: ROOT,
            })}
            <Divider className="hr-bott" />
            <MenuLink
              {...{
                childrenProps: {
                  className: clsx(navItemClassBase, navItemMuted),
                },
                menuProps: {
                  disableGutters: true,
                  onClick: logoutUser,
                },
              }}
            >
              Sign Out
            </MenuLink>
          </MenuList>
        </Fragment>
      ) : (
        <MenuList className="account-menu-list">
          {[
            {
              index: 0,
              cssClass: headerNavItemClass,
              name: 'Log In',
              url: LOGIN,
            },
            {
              index: 1,
              cssClass: headerNavItemClass,
              name: 'Sign Up',
              url: SIGNUP,
            },
            {
              index: 2,
              name: 'Home',
              url: ROOT,
            },
          ].map((navItem) => {
            const { cssClass, index: key, name, url } = navItem;

            return getMenuLink(name, {
              activeClassName: activeClass,
              className: clsx(navItemClassBase, cssClass),
              key,
              url,
            });
          })}
        </MenuList>
      )}
    </div>
  );
};

AccountMenuCommon.propTypes = {
  className: PropTypes.string,
  logoutUser: PropTypes.func.isRequired,
  userState: userStatePropType.isRequired,
};

AccountMenuCommon.defaultProps = {
  className: '',
};

const mapStateToProps = (state) => ({
  userState: state.user,
});

const mapDispatchToProps = { logoutUser: logoutUserAction };

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenuCommon);
