import moment from 'moment';

/** If prev or next is null, coerce to 0 */
export const getMomentDiff = (prev, next) => moment(prev || 0).diff(next || 0);

/** Compare date changes (with moment.diff()) if their corresponding props are changed
 *  @example
 *  componentDidUpdate(prevProps) {
 *      compareMoment(prevProps, this.props);
 *  }
 * @param {object} prevProps - required object with optional properties:
 *    { startDate, endDate, date }
 * @param {object} nextProps - see above.
 * @return {object|boolean} - returns object with nextProps keys that are different or `false`
 */
export const compareMoment = (prevProps, nextProps) => {
  const keys = ['startDate', 'endDate', 'date'];
  let diff;
  const compare = keys.reduce((acc, key) => {
    const prev = prevProps[key];
    const next = nextProps[key];
    if (getMomentDiff(prev, next) !== 0) {
      acc[key] = next;
      diff = true;
    }
    return acc;
  }, {});
  return diff ? compare : false;
};

export default compareMoment;
