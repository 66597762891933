import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const ErrorDialog = ({ onClose, title, content }) => (
  <Dialog open onClose={onClose} aria-labelledby="error-dialog-title">
    <DialogTitle id="error-dialog-title">
      <ErrorIcon />
      {title}
    </DialogTitle>
    <DialogContent>
      {content.map((paragraph, i) => (
        <p key={i}>{paragraph}</p>
      ))}
      <p>Please close and try again.</p>
      <Button onClick={onClose} variant="outlined">
        Close
      </Button>
    </DialogContent>
  </Dialog>
);

ErrorDialog.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};

ErrorDialog.defaultProps = {
  content: ['There was an error processing your request.'],
  title: 'Error',
};

export default ErrorDialog;
