import { tripTypeData } from 'utils/forms/formData';

const { oneWay } = tripTypeData.values;

export const getDatepickerType = ({ device, tripType, width }) => {
  const isOneWay = tripType === oneWay;
  const desktop = isOneWay ? 'DesktopSingle' : 'DesktopRange';
  const mobile = isOneWay ? 'MobileSingle' : 'MobileRange';

  if (device === 'landscape' && width >= 655) {
    return desktop;
  }
  if (width < 655) {
    return mobile;
  }
  return desktop;
};
