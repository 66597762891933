export const baseUrlDev = 'https://api.dev.treklee.com';
export const baseUrlStaging = 'https://api.staging.treklee.com';

export const baseUrl =
  process.env.REACT_APP_SERVICES_URL === 'dev' ? baseUrlDev : baseUrlStaging;

const baseAirports = `${baseUrl}/airports`;
const baseFlights = `${baseUrl}/flights`;
const baseUsers = `${baseUrl}/users`;

export const ENDPOINTS = {
  airports: baseAirports,
  airportsSearch: `${baseAirports}/search?q=`,
  accounts: `${baseUsers}/accounts`, // accounts are loyalty programs for user
  accountsBatch: `${baseUsers}/accounts/batch`, // might be replaced by accounts
  baseUrl,
  flightSearch: `${baseFlights}/search`,
  loyaltyPrograms: `${baseFlights}/loyaltyPrograms`,
  users: baseUsers,
  userLocation: `${baseUrlDev}/airports/closest`, // this will eventually be dynamic
};

export default ENDPOINTS;
