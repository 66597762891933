import produce from 'immer';

import {
  displayNameAPIConstants,
  homeAirportAPIConstants,
  LOGIN_SUCCESS,
  logoutAPIConstants,
  LOYALTY_PROGRAM_BALANCE_UPDATE,
  LOYALTY_PROGRAM_STATUS_UPDATE,
  LOYALTY_PROGRAMS_UPDATE,
} from './constants';
import { updateProgramsByProp } from './utils';

// todo: add all MOCK_USER properties here
// homeAirport: mockAirport,
// programs: mockPrograms,
// searchHistory: mockSearches,
export const initialState = {
  displayName: undefined, // {string} firebase result
  emailVerified: undefined, // bool
  error: null, // null or object
  homeAirport: undefined, // object
  lastLogin: undefined, // date number
  programs: undefined,
  providerData: undefined, // array of AuthProvider objects
  status: 'init', // string
  token: undefined, // string
  userEmail: undefined, // string
  userName: undefined, // {string} editable user name
  userPhotoURL: undefined, // string
};

export default (state = initialState, action) => {
  return produce(state, (draft) => {
    /* eslint-disable no-param-reassign */
    const { data = {}, error, type = '' } = action;

    if (type.toLowerCase().endsWith('fail')) {
      // this may need more subtle handling
      draft.error = error;
      return draft;
    }

    // immer doesn't like this defined after draft is modified.
    if (type === logoutAPIConstants.success) {
      return initialState;
    }

    // this may cause error not to persist long enough?
    draft.error = null;

    switch (type) {
      case displayNameAPIConstants.success: {
        draft.userName = data.name;
        return draft;
      }

      case homeAirportAPIConstants.success: {
        draft.homeAirport = data.airport;
        return draft;
      }

      case LOGIN_SUCCESS: {
        const { firebaseData, parsed } = data;
        draft.firebaseData = firebaseData;
        Object.assign(draft, parsed);
        return draft;
      }

      case LOYALTY_PROGRAMS_UPDATE: {
        draft.programs = data.programs;
        return draft;
      }

      case LOYALTY_PROGRAM_BALANCE_UPDATE: {
        updateProgramsByProp(draft, data, 'points');
        return draft;
      }

      case LOYALTY_PROGRAM_STATUS_UPDATE: {
        updateProgramsByProp(draft, data, 'status');
        return draft;
      }

      default:
        return draft;
    }
  });
};
