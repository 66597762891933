import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';

import { userProgramsToSet } from 'mock/mockAccountData';
import { loyaltyStatePropType, userStatePropType } from 'utils/propTypes';

import LoyaltySelection from 'components/Shared/Loyalty/LoyaltySelection';

import LoyaltyPointsForm from './LoyaltyPointsForm';

const LoyaltyPrograms = (props) => {
  const {
    loyaltyState,
    updateLoyaltyProgramBalance,
    updateLoyaltyPrograms,
    updateLoyaltyProgramStatus,
    userState,
  } = props;

  const fakePrograms = userState.programs;
  const showTable = fakePrograms.length > 0;

  const btn = (
    <LoyaltySelection
      onChange={updateLoyaltyPrograms}
      programs={loyaltyState.programs}
      programsDict={loyaltyState.dict}
      saveButtonText="Save"
      selected={userProgramsToSet(userState)}
      showValidation={false}
      status={loyaltyState.status}
      title="Add or remove programs"
      trigger={({ onOpen }) => (
        <Button className={clsx('btn-dialog-plain')} onClick={onOpen}>
          {showTable ? 'Add or remove' : 'Start adding programs'}
        </Button>
      )}
      // value={programs}
    />
  );

  return (
    <Fragment>
      <div className="loyalty-header flex-container-sb">
        <h2 className="margin-reset">Loyalty Programs</h2>
        {showTable && btn}
      </div>
      {showTable ? (
        <LoyaltyPointsForm
          {...{
            loyaltyDict: loyaltyState.dict,
            programs: loyaltyState.programs,
            status: loyaltyState.status,
            updateLoyaltyProgramBalance,
            updateLoyaltyProgramStatus,
            userPrograms: userState.programs,
          }}
        />
      ) : (
        <div className="no-programs text-center">
          <div>No programs found</div>
          {btn}
        </div>
      )}
    </Fragment>
  );
};

LoyaltyPrograms.propTypes = {
  loyaltyState: loyaltyStatePropType.isRequired,
  updateLoyaltyProgramBalance: PropTypes.func.isRequired,
  updateLoyaltyPrograms: PropTypes.func.isRequired,
  updateLoyaltyProgramStatus: PropTypes.func.isRequired,
  userState: userStatePropType.isRequired,
};

export default LoyaltyPrograms;
