import { combineReducers } from 'redux';
import loyalty from './loyalty/reducer';
import modals from './modals';
import searchLoadingBar from './search-loading-bar';
import user from './user';
import windowModule from './window';

export default combineReducers({
  loyalty,
  modals,
  searchLoadingBar,
  user,
  window: windowModule,
});
