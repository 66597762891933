import { any, arrayOf, bool, number, func, shape, string } from 'prop-types';

/** Reusable prop types */

/** Redux */
export const loyaltyStatePropType = shape({
  dict: shape({ id: number }),
  programs: arrayOf(shape({})),
});

export const userStatePropType = shape({
  emailVerified: bool,
  lastLogin: number,
  token: string,
  userEmail: string,
  userPhotoURL: string,
});

export const windowStatePropType = shape({
  device: string,
  height: number,
  width: number,
});

/* History */
export const historyPropType = shape({
  push: func,
});

/* React Router */
export const locationPropType = shape({
  pathname: string.isRequired,
  state: any,
});
