import is from '@sindresorhus/is';

/**
 *
 * @param {*} item
 * @returns {string} lowercase. can be used in
 *   example: is['object'] -> returns a function to check if it's an object.
 *   (see "is" API)
 */
const getType = (item) => {
  if (Number.isNaN(item)) {
    return 'nan';
  }
  return is(item).toLowerCase();
};

const isType = (item, typeString) => getType(item) === typeString;

/**
 * is.any / oneOfType
 * @example is.any(is.string, {}, true, '🦄'); //=> true
 * @example is.any([is.string, is.number], {}, true, '🦄'); //=> true
 */

export { getType, is, isType };
