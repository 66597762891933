import { findAndUpdate } from 'utils/dataUtils';

import { getProvider, lastLoginSelector } from './selectors';

/**
 * @module Utils - specific to user
 */

export const getPhotoURLAlternate = ({ email, name } = {}) => {
  const alt = name || email;

  if (alt) {
    return alt.slice(0, 1).toUpperCase();
  }

  return '';
};

// todo: pass in state, not userState
export const isEmailProvider = (userState) =>
  getProvider({ user: userState }) === 'password';

export const isLoggedIn = (userState) =>
  userState && typeof userState.userEmail === 'string';

/* eslint-disable no-param-reassign */
export const updateProgramsByProp = (draft, data, prop) =>
  findAndUpdate(draft.programs, (program) => {
    if (program.id === data.programId) {
      program[prop] = data[prop];
    }
  });
/* eslint-enable no-param-reassign */

// arbitrarily chose 'lastLogin', which is initially undefined
export const userNeedsStoreInit = (state) => !lastLoginSelector(state);

// User hasn't logged in yet, set token
export const handleLoginToken = (token) => {
  localStorage.setItem('token', token);
};

// check if user has verified their email, remove token if so
// todo: user feedback that their account is verified
export const handleEmailVerification = (firebaseUserData) => {
  const { email, emailVerified } = firebaseUserData;
  const emailNotVerified = localStorage.getItem('emailNotVerified');

  // If user has _just_ verified their email, firebase will
  // be "ahead" of our local storage
  if (emailVerified && emailNotVerified === email) {
    // what's the right condition for persisting this?
    localStorage.removeItem('emailNotVerified');
    // make sure that this is set in redux / backend
    // console.log('rejoice! user hath been verified for first time!');
  }
  // else {
  //   // eslint-disable-next-line no-lonely-if
  //   if (!emailVerified) {
  //     // make sure that this is set in redux / backend and limit access
  //     console.log('sadly, user not verified');
  //   } else {
  //     console.log('user verified previously');
  //   }
  // }
};
