import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icons from '../../utils/Icons';

const styles = () => ({
  paper: {
    width: '80%',
    maxHeight: 435,
  },
});

const classes = {};
classes['1'] = 'economyRate';
classes['2'] = 'premiumEconomyRate';
classes['3'] = 'businessRate';
classes['4'] = 'firstRate';

class Flight extends Component {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.number,
    }),
  };

  static defaultProps = {
    data: {
      id: 0,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      showAgentDetails: false,
      showLoyalyDetails: false,
      detailsMode: 'schedule',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {};
    // Data has arrive and default price and loyalty are selected
    if (nextProps.data !== prevState.data) {
      const { data } = nextProps;
      state.data = data;
      state.price = data.pricingOptions[0];
      state.loyalty = data.awardOptionsFilter[0];
      state.pricingOptions = data.pricingOptions;
      state.awardOptionsFilter = data.awardOptionsFilter;
    }
    // Loyalties have been filtered and is selecting first one
    if (nextProps.awardOptionsFilter !== prevState.awardOptionsFilter) {
      const { awardOptionsFilter } = nextProps;
      state.awardOptionsFilter = awardOptionsFilter;
      state.loyalty = nextProps.awardOptionsFilter[0];
    }
    // Sorting by price occurs so min price is preselected
    if (nextProps.showMinPrice !== prevState.showMinPrice) {
      const { data } = nextProps;
      for (let iPrice = 0; iPrice < data.pricingOptions.length; iPrice += 1) {
        if (data.pricingOptions[iPrice].Price === data.minPrice) {
          state.price = data.pricingOptions[iPrice];
          break;
        }
      }
      state.showMinPrice = nextProps.showMinPrice;
    }
    // Sorting by points occurs so min points is preselected
    if (nextProps.showMinPoints !== prevState.showMinPoints) {
      const { data, search } = nextProps;
      for (
        let iLoyalty = 0;
        iLoyalty < data.awardOptionsFilter.length;
        iLoyalty += 1
      ) {
        if (
          data.awardOptionsFilter[iLoyalty].awardChart[
            classes[search.flightClass]
          ].rate === data.minPoints
        ) {
          state.loyalty = data.awardOptionsFilter[iLoyalty];
          break;
        }
      }
      state.showMinPoints = nextProps.showMinPoints;
    }

    return state;
  }

  // Render item only when it update to boost performance
  shouldComponentUpdate(nextProps, nextState) {
    // console.log(nextState, this.state);
    const { pricingOptions, data, expanded, showLoyalyDetails } = this.state;
    const { showAgentDetails, price, loyalty, detailsMode } = this.state;
    if (
      nextState.data.bookingDetailsLink.Body !== data.bookingDetailsLink.Body
    ) {
      return true;
    }
    if (nextState.pricingOptions.length !== pricingOptions.length) {
      return true;
    }
    if (nextState.detailsMode !== detailsMode) {
      return true;
    }
    if (nextState.expanded !== expanded) {
      return true;
    }
    if (nextState.showLoyalyDetails !== showLoyalyDetails) {
      return true;
    }
    if (nextState.showAgentDetails !== showAgentDetails) {
      return true;
    }
    if (nextState.price !== price) {
      return true;
    }
    if (nextState.loyalty !== loyalty) {
      return true;
    }

    let update = false;
    for (
      let iPricing = 0;
      iPricing < nextState.pricingOptions.length;
      iPricing += 1
    ) {
      if (nextState.pricingOptions[iPricing] !== pricingOptions[iPricing]) {
        update = true;
      }
    }
    return update;
  }

  // Show details of flight
  tooglePanel = () => {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  };

  // Show the extra options of loyalty programs on the details popup/panel
  showLoyaltyPopup = () => {
    const { showLoyalyDetails, data } = this.state;
    if (data.awardOptionsFilter.length > 1) {
      this.setState({ showLoyalyDetails: !showLoyalyDetails });
    }
    this.setState({ expanded: true, detailsMode: 'fares' });
  };

  // Show the extra options of agent on the details popup/panel
  showAgentPopup = () => {
    const { showAgentDetails, data } = this.state;
    if (data.pricingOptions.length > 1) {
      this.setState({ showAgentDetails: !showAgentDetails });
    }
    this.setState({ expanded: true, detailsMode: 'fares' });
  };

  // Tranform time to human readable format
  extractHourFromTime = time => {
    const times = time.split('T')[1].split(':');
    let hours = Number(times[0]);
    let minutes = Number(times[1]);
    const amPm = hours < 12 ? 'AM' : 'PM';

    hours %= 12;
    hours = hours === 0 ? 12 : hours;
    minutes = (minutes < 10 ? '0' : '') + minutes;
    return `${hours}:${minutes} ${amPm}`;
  };

  // Extract Date from time
  extractDateFromTime = time => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const times = time.split('T')[0].split('-');
    const year = Number(times[0]);
    const date = times[2];
    const month = months[Number(times[1]) - 1];
    return `${month} ${date}, ${year}`;
  };

  // Calculate the difference in days between departure and arrival
  calculateDiffDays = (departure, arrival) => {
    const departureP = departure.split('T')[0];
    const arrivalP = arrival.split('T')[0];
    const seconds =
      new Date(arrivalP).getTime() - new Date(departureP).getTime();
    const days = Math.round(seconds / (24 * 60 * 60 * 1000));
    return days === 0 ? '' : <b>{(days > 0 ? '+' : '') + days}</b>;
  };

  // Transform time in minutes to 00h 00m format
  hoursToString = timeInHours => {
    const time = Math.abs(timeInHours);

    const minutes = time % 60;
    const hours = Math.floor(time / 60);

    let text = '';
    text += `${hours}h `;
    text += `${(minutes < 10 ? '0' : '') + minutes}m`;
    return text;
  };

  // Log selected loyalty program
  onRedeem = loyalty => () => {
    /**
     * This will be relevant once we figure out what we're doing with the loyalty
     */
    console.log(loyalty);
  };

  // Get time diff between stops by using the arrive of the previous segement and the
  // departure of the after segment, aka layover time
  stayTime = (departure, arrive) => {
    const time = Math.abs(
      new Date(departure).getTime() - new Date(arrive).getTime(),
    );
    return this.hoursToString(time / 60000);
  };

  // Render a Segment
  renderSegments = segments =>
    segments.map((segment, iSegment) => (
      <div className="flight-detail" key={segment.Id}>
        {iSegment !== 0 && (
          <div className="stay">
            {`${this.stayTime(
              segment.DepartureDateTime,
              segments[iSegment - 1].ArrivalDateTime,
            )} `}
            layover in {segment.origin.Name} ({segment.origin.Code})
          </div>
        )}
        <div className="row">
          <b>{this.extractHourFromTime(segment.DepartureDateTime)}</b>
          <div className="date">
            {this.extractDateFromTime(segment.DepartureDateTime)}
          </div>
        </div>
        <div className="airport">
          {segment.origin.Name} ({segment.origin.Code})
        </div>
        <div className="airline">
          <img
            src={segment.OperatingCarrier.ImageUrl}
            alt={segment.OperatingCarrier.Name}
          />
          {segment.OperatingCarrier.Name}
          <br />
          {this.hoursToString(segment.Duration)} flight
        </div>
        <div className="row">
          <b>{this.extractHourFromTime(segment.ArrivalDateTime)}</b>
          <div className="date">
            {this.extractDateFromTime(segment.ArrivalDateTime)}
          </div>
        </div>
        <div className="airport">
          {segment.destination.Name} ({segment.destination.Code})
        </div>
      </div>
    ));

  renderLogos = carriers => {
    if (carriers.length > 1) {
      const url =
        'https://i.pinimg.com/564x/77/a5/32/77a5329f02b59886c51b8e44e942e468.jpg?b=t';
      return <img key={-1} src={url} alt="Multiple Airlines" />;
    }
    return carriers.map(carrier => (
      <img key={carrier.Id} src={carrier.ImageUrl} alt={carrier.Name} />
    ));
  };

  // schedule tab selected
  showSchedule = () => {
    this.setState({ detailsMode: 'schedule' });
  };

  // fares tab selected
  showFares = () => {
    this.setState({ detailsMode: 'fares' });
  };

  // flight details popup
  renderFlightDetails() {
    const {
      data,
      expanded,
      loyalty,
      price,
      showLoyalyDetails,
      showAgentDetails,
      detailsMode,
    } = this.state;
    const { search } = this.props;

    const segments = [];
    if (data.outboundLeg) {
      segments.push(
        <h2 key="depart-title">
          Depart<i>{this.hoursToString(data.outboundLeg.Duration)}</i>
        </h2>,
      );
      segments.push(
        <div key="depart-content" className="flight-detail-container">
          {this.renderSegments(data.outboundLeg.Segments)}
        </div>,
      );
    }
    if (data.inboundLeg) {
      segments.push(
        <h2 key="return-title">
          Return<i>{this.hoursToString(data.outboundLeg.Duration)}</i>
        </h2>,
      );
      segments.push(
        <div key="return-content" className="flight-detail-container">
          {this.renderSegments(data.inboundLeg.Segments)}
        </div>,
      );
    }

    if (!expanded) {
      return false;
    }

    const pointsHTML = [];
    if (loyalty) {
      pointsHTML.push(
        <div
          key={loyalty.id}
          className="points"
          style={{ borderBottom: showLoyalyDetails ? '' : '0' }}
        >
          <span>
            <span>
              {loyalty.awardChart[
                classes[search.flightClass]
              ].rate.toLocaleString()}
              <u> miles with</u>
            </span>
            <i>{loyalty.program.displayName}</i>
          </span>
          <b
            role="button"
            onKeyDown={this.onRedeem}
            onClick={this.onRedeem(loyalty)}
            tabIndex="0"
          >
            Redeem
          </b>
        </div>,
      );
      if (showLoyalyDetails) {
        for (
          let iLoyalty = 1;
          iLoyalty < data.awardOptionsFilter.length;
          iLoyalty++
        ) {
          pointsHTML.push(
            <div key={data.awardOptionsFilter[iLoyalty].id} className="points">
              <span key="points">
                <span>
                  {data.awardOptionsFilter[iLoyalty].awardChart[
                    classes[search.flightClass]
                  ].rate.toLocaleString()}
                  <u> miles with</u>
                </span>
                <i>{data.awardOptionsFilter[iLoyalty].program.displayName}</i>
              </span>
              <b
                key="button"
                role="button"
                onKeyDown={this.onRedeem}
                onClick={this.onRedeem(data.awardOptionsFilter[iLoyalty])}
                tabIndex="0"
              >
                Redeem
              </b>
            </div>,
          );
        }
      }
    }
    const pricesHTML = [];

    pricesHTML.push(
      <div
        key={price.Agents[0].Id}
        className="price"
        style={{ borderBottom: showAgentDetails ? '' : '0' }}
      >
        <span>
          <span>
            $
            {price.Price.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
            <u> USD</u>
          </span>
          <i>{price.Agents[0].Name}</i>
        </span>
        <b>
          <a href={price.DeeplinkUrl} target="_blank" rel="noopener noreferrer">
            Buy
          </a>
        </b>
      </div>,
    );
    if (showAgentDetails) {
      for (let iPrice = 1; iPrice < data.pricingOptions.length; iPrice++) {
        pricesHTML.push(
          <div key={data.pricingOptions[iPrice].Agents[0].Id} className="price">
            <span>
              <span>
                $
                {data.pricingOptions[iPrice].Price.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                })}
                <u> USD</u>
              </span>
              <i>{data.pricingOptions[iPrice].Agents[0].Name}</i>
            </span>
            <b>
              <a
                href={data.pricingOptions[iPrice].DeeplinkUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy
              </a>
            </b>
          </div>,
        );
      }
    }

    return (
      <div className={`flight-search-result-popup ${detailsMode}`}>
        <div>
          <ul>
            <li
              onClick={this.showSchedule}
              className={detailsMode === 'schedule' ? 'selected' : ''}
            >
              Trip Details
            </li>
            <li
              onClick={this.showFares}
              className={detailsMode === 'fares' ? 'selected' : ''}
            >
              Fares
            </li>
          </ul>
          <h1>
            <b onClick={this.tooglePanel}>{Icons.Close}</b>
            {data.outboundLeg.origin.Code} - {data.outboundLeg.destination.Code}
          </h1>
          <div className="content">
            <h3 className="blue">Book with points</h3>
            <div className="header table">
              <span>Loyalty Program</span>
              <span>Points</span>
            </div>
            {pointsHTML}
            <div
              className="center"
              style={{
                display: data.awardOptionsFilter.length > 1 ? '' : 'none',
              }}
            >
              <div
                className="more-button"
                role="button"
                onClick={this.showLoyaltyPopup}
                tabIndex="0"
              >
                {showLoyalyDetails
                  ? 'View Less'
                  : `View other ${data.awardOptionsFilter.length -
                      1} points options`}
              </div>
            </div>
            <h3 className="blue">Book with cash</h3>
            <div className="header table">
              <span>Booking Site</span>
              <span>Amount</span>
            </div>
            {pricesHTML}
            <div
              className="center"
              style={{ display: data.pricingOptions.length > 1 ? '' : 'none' }}
            >
              <div
                className="more-button"
                role="button"
                onClick={this.showAgentPopup}
                tabIndex="0"
              >
                {showAgentDetails
                  ? 'View Less'
                  : `View other ${data.pricingOptions.length - 1} cash options`}
              </div>
            </div>
            <h3>Trip Summary</h3>
            {segments}
          </div>
        </div>
      </div>
    );
  }

  // render a leg
  renderFlight(flight, leg) {
    let line = (
      <u className="two">
        <i />
        <i />
      </u>
    );
    let lineLabels = (
      <div className="two-cities stops-labels">
        <i>Nonstop</i>
      </div>
    );
    if (flight.Segments.length === 2) {
      line = (
        <u className="three">
          <i />
          <i />
          <i />
        </u>
      );
      lineLabels = (
        <div className="three-cities stops-labels">
          <i>{flight.Segments[1].origin.Code}</i>
        </div>
      );
    } else if (flight.Segments.length === 3) {
      line = (
        <u className="four">
          <i />
          <i />
          <i />
          <i />
        </u>
      );
      lineLabels = (
        <div className="four-cities stops-labels">
          <i>{flight.Segments[1].origin.Code}</i>
          <i>{flight.Segments[2].origin.Code}</i>
        </div>
      );
    } else if (flight.Segments.length > 3) {
      lineLabels = (
        <div className="two-cities stops-labels">
          <i>Multiple Stops</i>
        </div>
      );
    }

    // console.log("id:", flight.Id);

    const airlinesLogos = this.renderLogos(flight.OperatingCarriers);
    const airlinesLabel =
      airlinesLogos.length === 1
        ? flight.OperatingCarriers[0].Name
        : 'Multiple Airlines';
    return (
      <div key={flight.Id} className="flight-item">
        <div className="logos show">
          {airlinesLogos}
          <span>{airlinesLabel}</span>
        </div>
        <div>
          <div className="flight" onClick={this.tooglePanel}>
            <div className="airline">
              {airlinesLogos}
              <span>{airlinesLabel}</span>
            </div>
            <div className="summary">
              <span>
                <b>{this.extractHourFromTime(flight.Departure)}</b>
                <i>{flight.origin.Code}</i>
              </span>

              {line}
              <i>Nonstop</i>
              <i>
                {this.hoursToString(flight.Duration)}
                <span>
                  {' '}
                  {flight.Segments.length === 1
                    ? ''
                    : `(${flight.Segments.length - 1} stops)`}
                </span>
              </i>
              {lineLabels}
              <span>
                <b>
                  {this.extractHourFromTime(flight.Arrival)}
                  {this.calculateDiffDays(flight.Departure, flight.Arrival)}
                </b>
                <i>{flight.destination.Code}</i>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // render airline labels
  renderAirlineLabels() {
    const { data } = this.state;
    // console.log("Flight -> data.flights", JSON.stringify(data.flights))
    const labels = [];

    if (data.outboundLeg) {
      const carriers = data.outboundLeg.OperatingCarriers;
      const airlinesLabel =
        carriers.length === 1 ? carriers[0].Name : 'Multiple Airlines';
      labels.push(airlinesLabel);
    }
    if (data.inboundLeg) {
      const carriers = data.inboundLeg.OperatingCarriers;
      const airlinesLabel =
        carriers.length === 1 ? carriers[0].Name : 'Multiple Airlines';
      if (labels[0] !== airlinesLabel) {
        labels.push(airlinesLabel);
      }
    }
    return labels.join(', ');
  }

  // render legs
  renderFlights() {
    const { data } = this.state;
    // console.log("Flight -> data.flights", JSON.stringify(data.flights))
    const flights = [];

    if (data.outboundLeg) {
      flights.push(this.renderFlight(data.outboundLeg, 'outbound'));
    }
    if (data.inboundLeg) {
      flights.push(this.renderFlight(data.inboundLeg, 'inbound'));
    }
    return flights;
  }

  render() {
    const { loyalty, data, price, expanded } = this.state;
    const { search } = this.props;

    const pointsHTML = [];
    if (loyalty) {
      pointsHTML.push(
        <span key="points">
          <span>
            {loyalty.awardChart[
              classes[search.flightClass]
            ].rate.toLocaleString()}
            <u> miles with</u>
          </span>
          <i>{loyalty.program.displayName}</i>
        </span>,
      );
      pointsHTML.push(
        <b
          key="button"
          role="button"
          onKeyDown={this.onRedeem}
          onClick={this.onRedeem(loyalty)}
          tabIndex="0"
        >
          Redeem
          <u
            className="arrow"
            role="button"
            onClick={this.showLoyaltyPopup}
            tabIndex="0"
          />
        </b>,
      );
    }
    return [
      <div
        key={data.bookingDetailsLink.Body}
        className={`flight-search-result ${expanded ? 'expanded' : ''}`}
      >
        <div className="ariline-names">{this.renderAirlineLabels()}</div>
        <div className={`flight-items flight-items-${data.roundTrip ? 2 : 1}`}>
          {this.renderFlights()}
        </div>

        <div className="prices">
          <div className="points">{pointsHTML}</div>
          <div className="price">
            <span>
              $
              {price.Price.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
              <u> USD</u>
              <i>
                {price.Agents[0].Name}
                {
                  // <img src={data.pricingOptions[0].Agents[0].ImageUrl} alt={data.pricingOptions[0].Agents[0].Name}/>
                }
              </i>
            </span>
            <b>
              <a
                href={price.DeeplinkUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy
              </a>
              <u
                className="arrow"
                role="button"
                onClick={this.showAgentPopup}
                tabIndex="0"
              />
            </b>
          </div>
        </div>
      </div>,
      <div>{this.renderFlightDetails()}</div>,
    ];
  }
}

export default withStyles(styles)(Flight);
