import { OptionData } from './OptionData';

const tripTypeOptions = [
  { key: 'roundTrip', text: 'Round Trip', value: '1' },
  { key: 'oneWay', text: 'One Way', value: '2' },
];

export const tripTypeData = new OptionData(tripTypeOptions);

const flightClassOptions = [
  {
    key: 'economy',
    text: 'Economy',
    value: '1',
  },
  {
    key: 'premium',
    text: 'Premium Economy',
    value: '2',
  },
  {
    key: 'business',
    text: 'Business',
    value: '3',
  },

  {
    key: 'first',
    text: 'First Class',
    value: '4',
  },
];

export const flightClassData = new OptionData(flightClassOptions);
