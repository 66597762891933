import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  LOADING_START,
  // LOADING_CONTINUE,
  LOADING_END,
} from 'modules/search-loading-bar';

/** This equation uses limits to never approach 100%
 * The "magic" numbers inside are a "cocktail" that looks best for our purposes
 * @param {Object} state
 * @returns {number}
 */
const getProportion = ({ completed, request, time }) => {
  if (completed) {
    return 1;
  }

  return (
    // eslint-disable-next-line no-restricted-properties
    (1 - Math.pow(0.5, request)) * 0.65 + (1 - Math.pow(0.97, time)) * 0.35
  );
};

class SearchLoader extends Component {
  static propTypes = {
    loadingBarData: PropTypes.shape({
      time: PropTypes.number,
      type: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      request: 0,
      completed: false,
      time: 0,
      hide: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { loadingBarData } = this.props;
    const { loadingBarData: prevData } = prevProps;

    if (prevData.time !== loadingBarData.time) {
      this.receivedRequest(loadingBarData.type);
    }
  }

  receivedRequest = (type) => {
    if (type === LOADING_START) {
      this.setState({ request: 0, completed: false, time: 0, hide: false });
      setTimeout(() => {
        this.updateTime();
      }, 200);
      return;
    }

    this.setState((state) => ({
      completed: type === LOADING_END,
      request: state.request + 1,
    }));
  };

  updateTime = () => {
    const { completed } = this.state;

    if (completed) {
      setTimeout(() => {
        this.setState({ hide: true });
      }, 500);
    } else {
      setTimeout(() => {
        this.updateTime();
      }, 200);
    }

    this.setState((state) => ({ time: state.time + 1 }));
  };

  render() {
    const { hide } = this.state;

    return hide ? null : (
      <div className="search-loader">
        <div
          className="search-loader-bar"
          style={{ width: `${(getProportion(this.state) * 100).toFixed(2)}%` }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingBarData: state.searchLoadingBar,
});

export default connect(mapStateToProps, null)(SearchLoader);
