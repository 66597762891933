import 'react-dates/initialize';
import React from 'react';
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker';
import Button from '@material-ui/core/Button';

import { compareMoment, getInputValue, propTypesDatepicker } from './utils';

class MobileSingle extends React.Component {
  static propTypes = propTypesDatepicker.MobileSingle;

  static defaultProps = {
    onDateChange: () => {},
    onFocusChange: () => {},
  };

  constructor(props) {
    super(props);
    const { date, focused } = this.props;
    this.state = {
      date,
      focused,
      inputValue: getInputValue(date),
    };
  }

  componentDidUpdate(prevProps) {
    const { date, focused } = this.props;
    const diffs = compareMoment(prevProps, this.props);
    if (diffs) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ ...diffs, inputValue: getInputValue(date) });
    }
    if (prevProps.focused !== focused) {
      this.setState({ focused });
    }
  }

  /* eslint-disable react/sort-comp */

  onDateChange = (date) => {
    const { onDateChange } = this.props;
    this.setState({
      date,
      inputValue: getInputValue(date),
    });
    onDateChange(date);
  };

  clearDateState = () => {
    const { onDateChange } = this.props;
    this.setState({
      date: null,
      inputValue: '',
    });
    onDateChange(null);
  };

  closeCalendar = () => {
    const { onFocusChange } = this.props;
    this.setState({
      focused: null,
    });
    onFocusChange({ focused: false });
  };

  handleFocusChange = ({ focused }) => {
    const { onFocusChange } = this.props;
    this.setState({ focused });
    onFocusChange({ focused });
  };

  renderCalendarInfoFunc = () => {
    const { inputValue } = this.state;
    // Disabling irrelevant a11y rules (keyboard doesn't apply to mobile)
    /* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
    return (
      <div className="calendar-info">
        <div className="calendar-info-top">
          <div className="calendar-info-dates">
            <div
              className={['focused', inputValue ? '' : 'placeholder'].join(' ')}
              // onClick={() => this.handleFocusChange()}
              role="button"
            >
              {inputValue || 'Depart'}
            </div>
            <div
              style={{ visibility: 'hidden' }} // hidden to keep same placement as range_picker
              role="button"
            />
          </div>
        </div>
        <div className="calendar-info-bottom">
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={this.closeCalendar}
            disabled={!inputValue}
          >
            Save
          </Button>
        </div>
      </div>
    );
    /* eslint-enable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
  };

  render() {
    const { date, focused } = this.state;
    // destructure the props I don't want to pass through
    const { date: dateProp, focused: focusedProp, ...rest } = this.props;
    return (
      <SingleDatePicker
        {...rest}
        date={date}
        focused={focused}
        id="desktop_single_date_id"
        onClose={this.closeCalendar}
        onDateChange={this.onDateChange}
        onFocusChange={this.handleFocusChange}
        renderCalendarInfo={this.renderCalendarInfoFunc}
      />
    );
  }
}

export default MobileSingle;
