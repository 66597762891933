import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const inputStyles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    // width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
});

function SearchInput({
  classes,
  className,
  placeholder,
  onChange,
  onClear,
  value,
}) {
  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        {...{
          onChange,
          placeholder,
          value,
        }}
        className={clsx(classes.input, className)}
        fullWidth
        inputProps={{ 'aria-label': { placeholder } }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <IconButton
        aria-label="clear"
        className={classes.iconButton}
        onClick={onClear}
        type="submit"
      >
        <CloseIcon />
      </IconButton>
    </Paper>
  );
}

SearchInput.propTypes = {
  classes: PropTypes.shape({
    iconButton: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

SearchInput.defaultProps = {
  className: undefined,
  placeholder: 'Search',
};

export default withStyles(inputStyles)(SearchInput);
