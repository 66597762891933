import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import LogoLink from 'components/Shared/LogoLink';

import AccountMenuCommon from './AccountMenuCommon';

class AccountMenuMobile extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      mobileDrawerOpen: false,
    };
  }

  /* eslint-disable react/sort-comp */

  toggleMenu = (/* event */) => {
    // event.persist();
    this.setState((prevState) => ({
      mobileDrawerOpen: !prevState.mobileDrawerOpen,
    }));
  };

  closeMenu = () => {
    this.setState({ mobileDrawerOpen: false });
  };

  render() {
    const { className } = this.props;
    const { mobileDrawerOpen } = this.state;

    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    return (
      <div className={clsx('account-menu-mobile', className)}>
        <IconButton
          className="mobile-menu-icon-btn"
          aria-haspopup="true"
          color="inherit"
          onClick={this.toggleMenu}
        >
          <MenuIcon />
        </IconButton>
        <Drawer open={mobileDrawerOpen} onClose={this.closeMenu}>
          {
            // The div.onClick allows the click of any link to close parent drawer
          }
          <div className="account-menu-mobile-drawer" onClick={this.closeMenu}>
            <div className="menu-logo-container text-center">
              <LogoLink />
            </div>
            <AccountMenuCommon />
          </div>
        </Drawer>
      </div>
    );
  }
}

export default AccountMenuMobile;
