import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import store from './store';
import App from './components/App';

import './index.scss';

const theme = createMuiTheme({
  typography: {
    button: {
      // remove default capitalization
      textTransform: 'none',
    },
    // Use fonts here instead of the default Roboto font.
    fontFamily: [
      'Rubik',
      'Cabin',
      'Verdana',
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: 'inherit',
      },
    },
  },
});

// eslint-disable-next-line no-undef
const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <Router>
        <App />
      </Router>
    </MuiThemeProvider>
  </Provider>,
  target,
);
