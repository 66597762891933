export const LOADING_START = 'search-loading-bar/LOADING_START';
export const LOADING_CONTINUE = 'search-loading-bar/LOADING_CONTINUE';
export const LOADING_END = 'search-loading-bar/LOADING_END';

const getAction = (type) => () => ({
  time: Date.now(),
  type,
});

export const loadingStart = getAction(LOADING_START);
export const loadingContinue = getAction(LOADING_CONTINUE);
export const loadingEnd = getAction(LOADING_END);

const getInitialState = () => ({ time: Date.now() });

export default function (state = getInitialState(), action) {
  const { type } = action;

  switch (type) {
    case LOADING_START:
    case LOADING_CONTINUE:
    case LOADING_END:
      return action;
    default:
      return state;
  }
}
