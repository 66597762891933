import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/YoutubeSearchedFor';

import { ROOT } from 'components/App/routeConstants';
import { convertToMoment, isSameMonth } from 'utils/date';

import LinkButton from 'components/Shared/LinkButtonMUI';
import { mockSearches } from 'mock/mockAccountData';

const monthDayFormat = 'MMM D';
const dayOnlyFormat = 'D';

const getSearchCollection = (showAll, recentSearches) => {
  if (recentSearches.length <= 5 || showAll) {
    return recentSearches;
  }

  return recentSearches.slice(0, 5);
};

const getDisplayName = (airport) => {
  const { iata, shortDisplayName } = airport;
  return (
    <Fragment>
      <span className="airport-mobile">{iata}</span>
      <span className="airport-desktop">{shortDisplayName}</span>
    </Fragment>
  );
};

const getDisplayAirports = (origin, destination) => (
  <span className="airports">
    {getDisplayName(origin)} to {getDisplayName(destination)}
  </span>
);

const getDisplayDates = (startDate, endDate) => {
  const date1 = convertToMoment(startDate);
  const date2 = convertToMoment(endDate);

  const display2 = date2.format(
    isSameMonth(date1, date2) ? dayOnlyFormat : monthDayFormat
  );

  const formatted = `${date1.format(monthDayFormat)}-${display2}`;
  return <span>{formatted}</span>;
};

class RecentSearches extends Component {
  static propTypes = {
    userState: PropTypes.shape({ userEmail: PropTypes.string }),
  };

  static defaultProps = {
    userState: {
      userEmail: undefined,
    },
  };

  state = {
    showAll: false,
  };

  // componentDidMount() {
  // }

  toggleShowAll = () => {
    this.setState((state) => ({ showAll: !state.showAll }));
  };

  render() {
    const { showAll } = this.state;
    // const { userState } = this.props;
    // const { recentSearches = [] } = userState;
    // const { recentSearches = mockSearches } = userState;
    const recentSearches = mockSearches;

    const showShowAllToggle = recentSearches.length > 5 && !showAll;

    return (
      <div className="account-recent-searches-container">
        <h2>Recent Searches</h2>
        {recentSearches.length === 0 ? (
          <div className="text-center">
            <div>No search history found.</div>
            <div>
              <LinkButton className={clsx('btn-dialog-plain')} to={ROOT}>
                Start a flight search
              </LinkButton>
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="recent-searches-scrolly">
              {getSearchCollection(showAll, recentSearches).map((item) => {
                const {
                  cabin,
                  id,
                  origin,
                  destination,
                  startDate,
                  endDate,
                  url,
                } = item;

                return (
                  <LinkButton
                    className="recent-search-row-link"
                    key={id}
                    muiVariation="ButtonBase"
                    to={url}
                  >
                    <div className="recent-search-row flex-container-sb">
                      <div className="flex1">
                        {getDisplayAirports(origin, destination)}
                        {getDisplayDates(startDate, endDate)}, {cabin}
                      </div>
                      <div>
                        <SearchIcon className="btn-recent-search-icon" />
                      </div>
                    </div>
                  </LinkButton>
                );
              })}
            </div>
            {showShowAllToggle && (
              <Button
                className={clsx('recent-searches-show-all', 'btn-dialog-plain')}
                onClick={this.toggleShowAll}
              >
                Show All
              </Button>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userState: state.user,
});

const RecentSearchesConnected = connect(mapStateToProps, null)(RecentSearches);
export default RecentSearchesConnected;
