import React from 'react';
import clsx from 'clsx';

import Paper from '@material-ui/core/Paper';

export const withPaper = (Comp, props = {}, paperClassName) => (
  <Paper className={clsx('paper-layout', paperClassName)}>
    <Comp {...props} />
  </Paper>
);
