import React, { Component } from 'react';
import PropTypes from 'prop-types';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Slider from 'rc-slider';

class SliderComp extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    format: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
  };

  static defaultProps = {
    format: value => value,
    onChange: () => {},
    min: 0,
    max: 100,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {};
    if (nextProps.min) {
      state.min = nextProps.min;
    }
    if (nextProps.max !== prevState.max) {
      state.max = nextProps.max;
      const { defaultValue } = nextProps;
      let realValue = defaultValue;
      realValue = realValue === -1 ? nextProps.max : realValue;

      state.defaultValue = defaultValue;
      state.value = realValue;
      state.tmpValue = realValue;
    }
    if (nextProps.defaultValue !== prevState.defaultValue) {
      const { defaultValue } = nextProps;
      let realValue = defaultValue;
      realValue = realValue === -1 ? prevState.max || nextProps.max : realValue;

      state.defaultValue = defaultValue;
      state.value = realValue;
      state.tmpValue = realValue;
    }
    return state;
  }

  onChange = _value => {
    const { onChange, max } = this.props;
    const { value } = this.state;
    if (value !== _value) {
      this.setState({ value: _value, tmpValue: _value });
      let valueParse = _value;
      valueParse = valueParse === max ? -1 : valueParse;
      onChange(valueParse);
    }
  };

  onUpdate = data => {
    this.setState({ tmpValue: data });
  };

  render() {
    const { min, max, format } = this.props;
    const { tmpValue } = this.state;
    return (
      <div className="range-slider">
        <span>{`${format(tmpValue)}`}</span>
        <Slider
          min={min}
          max={max}
          value={tmpValue}
          tipFormatter={format}
          onChange={this.onUpdate}
          onAfterChange={this.onChange}
        />
      </div>
    );
  }
}
/*

*/
export default SliderComp;
