import firebase from 'firebase';

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_PROJECTID,
  projectId: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  storageBucket: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
};

// occurs in FirebaseLogin:
export const firebaseInit = () => firebase.initializeApp(FIREBASE_CONFIG);

/**
 * @return {Promise} - reject if no user found. resolve with user if found.
 */
export const getFirebaseUser = (curUser) =>
  new Promise((resolve, reject) => {
    if (curUser) {
      resolve(curUser);
      return;
    }

    const userObj = firebase.auth().currentUser;
    if (userObj) {
      resolve(userObj);
      return;
    }

    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        reject(new Error('No user found.'));
        return;
      }
      // User is signed in.
      resolve(user);
    });
  });

/**
 * Both email and services have different responses. This normalizes them.
 *  Google / Facebook:
 *    { email, photoURL } = data.user
 *  Email:
 *    { email, photoURL } = data
 * @param {Object} res firebase response
 * @returns {Object} user data
 */
export const getAnyUserData = (res) => res.user || res;

export const getUserIDToken = (curUser) =>
  getFirebaseUser(curUser).then((user) => user.getIdToken());

export const sendEmailVerification = () =>
  getFirebaseUser().then((user) => {
    if (!user.emailVerified) {
      // console.log('sending verification email');
      // TODO: get url based on environment
      // return user.sendEmailVerification({ url: 'http://localhost:3000' });
      return user.sendEmailVerification();
    }
    // console.log('Email is verified');
    return undefined;
  });

export const signOutUser = () => firebase.auth().signOut();

/**
 * Get firebase popup Promise
 * @param {string} provider - 'google'|'facebook'
 * @returns {Promise<UserObject>}
 */
export const firebasePopupSignin = (provider) => {
  let providerConfig;
  if (provider === 'google') {
    providerConfig = new firebase.auth.GoogleAuthProvider();
  } else if (provider === 'facebook') {
    providerConfig = new firebase.auth.FacebookAuthProvider();
  } else {
    throw new Error(`provider must be 'google' or 'facebook'`);
  }
  return firebase.auth().signInWithPopup(providerConfig);
};

/** @returns {Promise<UserObject>} */
export const firebaseSignupWithEmail = (email, password) =>
  firebase.auth().createUserWithEmailAndPassword(email, password);

/** @returns {Promise<UserObject>} */
export const firebaseLoginWithEmail = (email, password) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

/**
 * @param {string} email
 * @returns {Promise} resolves with success, rejects on fail
 */
export const firebaseSendPasswordResetEmail = (email) =>
  firebase.auth().sendPasswordResetEmail(email);

/**
 * https://firebase.google.com/docs/reference/js/firebase.auth.EmailAuthProvider#static-credential
 * @param {string} email
 * @param {string} password
 * @returns {Object} firebase.auth.AuthCredential
 *   https://firebase.google.com/docs/reference/js/firebase.auth.AuthCredential
 */
export const getFirebaseEmailAuthCredential = (email, password) =>
  firebase.auth.EmailAuthProvider.credential(email, password);
