import React from 'react';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const DesktopSingle = props => (
  <SingleDatePicker id="desktop_single_date_id" {...props} />
);

export default DesktopSingle;
