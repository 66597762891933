import React from 'react';
import PropTypes from 'prop-types';

const RoutesWithNavWrapper = props => {
  const { children, Navbar, className } = props;
  return (
    <div className={className}>
      <Navbar />
      {children}
    </div>
  );
};

RoutesWithNavWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
  className: PropTypes.string,
  Navbar: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.elementType]),
};

RoutesWithNavWrapper.defaultProps = {
  className: '',
  Navbar: null,
};

export default RoutesWithNavWrapper;
