import React, { Component } from 'react';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import DesktopRange from './DesktopRange';
import DesktopSingle from './DesktopSingle';
import MobileRange from './MobileRange';
import MobileSingle from './MobileSingle';
import { NUM_MONTHS_MOBILE, START_DATE } from './constants';
import {
  compareMoment,
  ensureMoment,
  defaultPropsDatepicker,
  propTypesDatepicker,
  SHARED_PROPS,
  SHARED_DESKTOP_PROPS,
} from './utils';

class Datepicker extends Component {
  static propTypes = propTypesDatepicker.Datepicker;

  static defaultProps = defaultPropsDatepicker.Datepicker;

  constructor(props) {
    super(props);
    this.state = {
      ...ensureMoment(this.props),
      focusedInput: null,
      focused: false,
    };
  }

  componentDidUpdate(prevProps) {
    const diffs = compareMoment(
      ensureMoment(prevProps),
      ensureMoment(this.props)
    );
    if (diffs) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState(diffs);
    }
  }

  onDateChange = (date) => {
    const { onDateChange } = this.props;
    this.setState({ date });
    onDateChange({ date });
  };

  onDatesChange = ({ startDate, endDate }) => {
    const { onDatesChange } = this.props;
    this.setState({ startDate, endDate });
    onDatesChange({ endDate, startDate });
  };

  onFocusChangeRange = (focusedInput) =>
    this.setState({ focusedInput, focused: focusedInput !== null });

  onFocusChangeSingle = ({ focused }) =>
    this.setState({ focused, focusedInput: focused ? START_DATE : null });

  calcDaySize = () => parseInt((window.innerWidth - 32) / 7, 10);

  render() {
    const { date, endDate, focused, focusedInput, startDate } = this.state;
    const { type } = this.props;

    const SHARED_SINGLE_PROPS = {
      date,
      focused,
      onDateChange: this.onDateChange,
      onFocusChange: this.onFocusChangeSingle,
    };

    const SHARED_RANGE_PROPS = {
      // customArrowIcon: PropTypes.node,
      customArrowIcon: (
        <div className="separator-container">
          <span className="separator" />
        </div>
      ),
      endDate,
      endDatePlaceholderText: 'Return',
      focusedInput,
      minimumNights: 0,
      onDatesChange: this.onDatesChange,
      onFocusChange: this.onFocusChangeRange,
      startDate,
      startDatePlaceholderText: 'Depart',
    };

    const SHARED_MOBILE_PROPS = {
      daySize: this.calcDaySize(),
      disableScroll: false,
      customCloseIcon: <ChevronLeftIcon />,
      numberOfMonths: NUM_MONTHS_MOBILE,
      keepOpenOnDateSelect: true,
      // orientation: 'vertical',
      orientation: 'verticalScrollable',
      readOnly: true,
      withFullScreenPortal: true,
    };

    let datepicker;
    if (type === 'DesktopSingle') {
      datepicker = (
        <DesktopSingle
          {...SHARED_PROPS}
          {...SHARED_DESKTOP_PROPS}
          {...SHARED_SINGLE_PROPS}
        />
      );
    } else if (type === 'MobileSingle') {
      datepicker = (
        <MobileSingle
          {...SHARED_PROPS}
          {...SHARED_MOBILE_PROPS}
          {...SHARED_SINGLE_PROPS}
        />
      );
    } else if (type === 'DesktopRange') {
      datepicker = (
        <DesktopRange
          {...SHARED_PROPS}
          {...SHARED_DESKTOP_PROPS}
          {...SHARED_RANGE_PROPS}
        />
      );
    } else if (type === 'MobileRange') {
      datepicker = (
        <MobileRange
          {...SHARED_PROPS}
          {...SHARED_MOBILE_PROPS}
          {...SHARED_RANGE_PROPS}
        />
      );
    }

    return <div className="Datepicker-wrapper">{datepicker}</div>;
  }
}

export default Datepicker;
