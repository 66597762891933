// { goals: Object[] }
export default {
  goals: [
    {
      id: 6,
      originIATA: 'SFO',
      destinationIATA: 'HKG',
      destination: 'Hong Kong',
      start: '04 April',
      end: '20 April',
      airline: 'Air France',
      programs: [
        {
          name: 'Amex',
          value: 33000,
          target: 120000,
        },
      ],
      image:
        'https://mx.hola.com/imagenes/viajes/20171120102214/viajes-Hong-kong-china-que-ver/0-509-123/hong-kong-tradicion-y-modernidad-t.jpg?interpolation=lanczos-normal&downsize=0.75xw:*&output-format=progressive-jpeg&output-quality=70',
    },
    {
      id: 5,
      originIATA: 'SFO',
      destinationIATA: 'HKG',
      destination: 'Hong Kong',
      start: '04 April',
      end: '20 April',
      airline: 'Air France',
      programs: [
        {
          name: 'Amex',
          value: 33000,
          target: 120000,
        },
      ],
      image:
        'https://mx.hola.com/imagenes/viajes/20171120102214/viajes-Hong-kong-china-que-ver/0-509-123/hong-kong-tradicion-y-modernidad-t.jpg?interpolation=lanczos-normal&downsize=0.75xw:*&output-format=progressive-jpeg&output-quality=70',
    },
    {
      id: 4,
      originIATA: 'SFO',
      destinationIATA: 'HKG',
      destination: 'Hong Kong',
      start: '04 April',
      end: '20 April',
      airline: 'Air France',
      programs: [
        {
          name: 'Amex',
          value: 33000,
          target: 120000,
        },
      ],
      image:
        'https://mx.hola.com/imagenes/viajes/20171120102214/viajes-Hong-kong-china-que-ver/0-509-123/hong-kong-tradicion-y-modernidad-t.jpg?interpolation=lanczos-normal&downsize=0.75xw:*&output-format=progressive-jpeg&output-quality=70',
    },
    {
      id: 3,
      originIATA: 'SFO',
      destinationIATA: 'HKG',
      destination: 'Hong Kong',
      start: '04 April',
      end: '20 April',
      airline: 'Air France',
      programs: [
        {
          name: 'Amex',
          value: 33000,
          target: 120000,
        },
      ],
      image:
        'https://mx.hola.com/imagenes/viajes/20171120102214/viajes-Hong-kong-china-que-ver/0-509-123/hong-kong-tradicion-y-modernidad-t.jpg?interpolation=lanczos-normal&downsize=0.75xw:*&output-format=progressive-jpeg&output-quality=70',
    },
    {
      id: 2,
      originIATA: 'SFO',
      destinationIATA: 'HKG',
      destination: 'Hong Kong',
      start: '04 April',
      end: '20 April',
      airline: 'Air France',
      programs: [
        {
          name: 'Amex',
          value: 33000,
          target: 120000,
        },
      ],
      image:
        'https://mx.hola.com/imagenes/viajes/20171120102214/viajes-Hong-kong-china-que-ver/0-509-123/hong-kong-tradicion-y-modernidad-t.jpg?interpolation=lanczos-normal&downsize=0.75xw:*&output-format=progressive-jpeg&output-quality=70',
    },
    {
      id: 1,
      originIATA: 'SFO',
      destinationIATA: 'HKG',
      destination: 'Hong Kong',
      start: '04 April',
      end: '20 April',
      airline: 'Air France',
      programs: [
        {
          name: 'Amex',
          value: 33000,
          target: 120000,
        },
      ],
      image:
        'https://mx.hola.com/imagenes/viajes/20171120102214/viajes-Hong-kong-china-que-ver/0-509-123/hong-kong-tradicion-y-modernidad-t.jpg?interpolation=lanczos-normal&downsize=0.75xw:*&output-format=progressive-jpeg&output-quality=70',
    },
  ],
};
