import { FETCH, FETCH_SUCCESS, FETCH_FAIL } from './constants';

export const initialState = {
  dict: {},
  error: false,
  programs: [],
  status: 'ready', // todo: use status enum
};

export default (state = initialState, action) => {
  const { dict, error, programs, status, type } = action;

  switch (type) {
    case FETCH:
      return { ...state, status };
    case FETCH_SUCCESS:
      return { dict, error, status, programs };
    case FETCH_FAIL:
      // don't bother to reset dict or programs in case already present
      return { dict: state.dict, error, programs: state.programs, status };
    default:
      return state;
  }
};
