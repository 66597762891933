import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import Icons from 'utils/Icons';

import sortResults from './sortResults';
import Flight from '../Flight';

const sortOptions = [
  { text: 'Cheapest (points)', value: 0 },
  { text: 'Cheapest (cash)', value: 1 },
  { text: 'Departure time', value: 2 },
  { text: 'Arrival time', value: 3 },
  { text: 'Duration', value: 4 },
];
const classes = {};
classes['1'] = 'economyRate';
classes['2'] = 'premiumEconomyRate';
classes['3'] = 'businessRate';
classes['4'] = 'firstRate';

/**
 * Filters are handled here
 * Has the spinning loader - {Icons.Loader}
 * Also, see data.bookingDetailsLink - this is a check to see that data is available
 */
class Results extends Component {
  static propTypes = {
    results: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        flights: PropTypes.arrayOf(PropTypes.shape()),
      }),
    ),
    loading: PropTypes.bool,
    loaded: PropTypes.bool.isRequired,
    onResetFilters: PropTypes.func,
  };

  static defaultProps = {
    results: [],
    loading: false,
    onResetFilters: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      total: 0,
      sort: 0,
      showMinPrice: 0,
      showMinPoints: 0,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {};
    if (nextProps.loaded !== prevState.loaded) {
      state.loaded = nextProps.loaded;
      state.page = 0;
    }
    if (nextProps.results !== prevState.originalResults) {
      state.page = 0;
      state.results = nextProps.results;
      state.total = Math.max(state.results.length / 20);
      state.originalResults = nextProps.results;

      if ((nextProps.search.loyalty || []).length > 0) {
        const results = nextProps.results || prevState.results;
        const programsDict = {};
        const programs = nextProps.filters.programs || [];
        programs.forEach(id => {
          programsDict[id] = true;
        });

        state.results = results.filter(flight => {
          let programFound = false;

          flight.awardOptionsFilter = [];
          if (programs.length === 0) {
            flight.awardOptionsFilter = flight.awardOptions;
          } else {
            flight.awardOptions.forEach(award => {
              if (programsDict[award.program.id]) {
                programFound = true;
                flight.awardOptionsFilter.push(award);
              }
            });
          }

          if (!programFound) {
            return false;
          }
          return true;
        });
        state.page = 0;
        state.total = Math.max(state.results.length / 20);
      }
      state.results.forEach(flight => {
        let minPrice = Infinity;
        if (flight.pricingOptions) {
          flight.pricingOptions.forEach(price => {
            minPrice = Math.min(minPrice, price.Price);
          });
        }
        flight.minPrice = minPrice;

        let minPoints = Infinity;
        if (flight.awardOptionsFilter) {
          flight.awardOptionsFilter.forEach(award => {
            const points =
              award.awardChart[classes[nextProps.search.flightClass]].rate;
            minPoints = Math.min(minPoints, points);
          });
        }
        flight.minPoints = minPoints;
      });
      state.sort = 0;
      sortResults(state.sort, state.results);
      state.showMinPoints = new Date().getTime();
    }
    if (nextProps.filters !== prevState.filters) {
      state.filters = nextProps.filters;
      const results = nextProps.results || prevState.results;

      const airlines = {};
      nextProps.filters.airlines.forEach(id => {
        airlines[id] = true;
      });

      const programsDict = {};
      const programs = nextProps.filters.programs || [];
      programs.forEach(id => {
        programsDict[id] = true;
      });

      state.results = results.filter(flight => {
        let carrierFound = true;
        if (nextProps.filters.airlines.length > 0) {
          if (flight.inboundLeg) {
            flight.inboundLeg.OperatingCarriers.forEach(carrier => {
              if (!airlines[carrier.Id]) {
                carrierFound = false;
              }
            });
          }
          if (flight.outboundLeg) {
            flight.outboundLeg.OperatingCarriers.forEach(carrier => {
              if (!airlines[carrier.Id]) {
                carrierFound = false;
              }
            });
          }
        }
        // console.log(flight, carrierFound)
        if (!carrierFound) {
          return false;
        }

        let programFound = false;

        flight.awardOptionsFilter = [];
        if (programs.length === 0) {
          flight.awardOptionsFilter = flight.awardOptions;
        } else {
          flight.awardOptions.forEach(award => {
            if (programsDict[award.program.id]) {
              programFound = true;
              flight.awardOptionsFilter.push(award);
            }
          });
        }

        if (!programFound && flight.awardOptions.length > 0) {
          return false;
        }

        if (flight.inboundLeg) {
          if (flight.inboundLeg.Segments.length === 1) {
            if (!nextProps.filters.nonstop) {
              return false;
            }
          }
          if (flight.inboundLeg.Segments.length === 2) {
            if (!nextProps.filters.stop1) {
              return false;
            }
          }
          if (flight.inboundLeg.Segments.length > 2) {
            if (!nextProps.filters.stop2) {
              return false;
            }
          }
        }

        if (flight.outboundLeg) {
          if (flight.outboundLeg.Segments.length === 1) {
            if (!nextProps.filters.nonstop) {
              return false;
            }
          }
          if (flight.outboundLeg.Segments.length === 2) {
            if (!nextProps.filters.stop1) {
              return false;
            }
          }
          if (flight.outboundLeg.Segments.length > 2) {
            if (!nextProps.filters.stop2) {
              return false;
            }
          }
        }

        if (nextProps.filters.duration > 0) {
          if (
            flight.inboundLeg &&
            flight.inboundLeg.Duration > nextProps.filters.duration * 30
          ) {
            return false;
          }
          if (
            flight.outboundLeg &&
            flight.outboundLeg.Duration > nextProps.filters.duration * 30
          ) {
            return false;
          }
        }

        let minPrice = Infinity;
        flight.pricingOptions = flight.pricingOptionsOriginal.concat([]);
        if (
          nextProps.filters.price[0] !== -1 &&
          nextProps.filters.price[1] !== -1
        ) {
          flight.pricingOptions = flight.pricingOptions.filter(
            pricing =>
              nextProps.filters.price[0] <= pricing.Price &&
              nextProps.filters.price[1] >= pricing.Price,
          );

          if (flight.pricingOptions.length === 0) {
            return false;
          }
        }

        flight.pricingOptions.forEach(price => {
          minPrice = Math.min(minPrice, price.Price);
        });
        flight.minPrice = minPrice;

        let minPoints = Infinity;
        flight.awardOptionsFilter.forEach(award => {
          const points =
            award.awardChart[classes[nextProps.search.flightClass]].rate;
          minPoints = Math.min(minPoints, points);
        });
        flight.minPoints = minPoints;

        // Arrival, Departure
        if (flight.outboundLeg) {
          if (
            nextProps.filters['outbound-time'][0] !== -1 ||
            nextProps.filters['outbound-time'][1] !== -1
          ) {
            let departureTime = new Date(flight.outboundLeg.Departure);
            departureTime =
              departureTime.getHours() * 60 + departureTime.getMinutes();
            if (
              departureTime < nextProps.filters['outbound-time'][0] * 60 ||
              departureTime > nextProps.filters['outbound-time'][1] * 60
            ) {
              return false;
            }
          }
        }

        if (flight.inboundLeg) {
          if (
            nextProps.filters['return-time'][0] !== -1 ||
            nextProps.filters['return-time'][1] !== -1
          ) {
            let arrivalTime = new Date(flight.inboundLeg.Arrival);
            arrivalTime =
              arrivalTime.getHours() * 60 + arrivalTime.getMinutes();
            if (
              arrivalTime < nextProps.filters['return-time'][0] * 60 ||
              arrivalTime > nextProps.filters['return-time'][1] * 60
            ) {
              return false;
            }
          }
        }

        return true;
      });
      state.page = 0;
      state.total = Math.max(state.results.length / 20);
    }

    return state;
  }

  // Load next sets of results, no need to request server as results are already in state due to pulling
  loadMore = event => {
    if (event.nativeEvent.type === 'keydown') {
      if (event.nativeEvent.keyCode !== 13) {
        return false;
      }
    }
    this.setState(state => ({
      page: Math.min(state.page + 1, state.total - 1),
    }));
    return true;
  };

  // Request filters reset
  resetFilters = event => {
    if (event.nativeEvent.type === 'keydown') {
      if (event.nativeEvent.keyCode !== 13) {
        return false;
      }
    }
    const { onResetFilters } = this.props;
    onResetFilters();
    return true;
  };

  // sortResults the results
  sortResults = event => {
    const { results } = this.state;
    const sort = event.target.value;
    this.setState({
      sort,
      page: 0,
    });

    sortResults(sort, results);
    // Sorting by min price require show min price
    if (sort === 1) {
      this.setState({ showMinPrice: new Date().getTime() });
    }
    // Sorting by min price require show min points
    if (sort === 0) {
      this.setState({ showMinPoints: new Date().getTime() });
    }
  };

  render() {
    const {
      page,
      total,
      results,
      sort,
      showMinPrice,
      showMinPoints,
    } = this.state;
    const { loading, search, loaded, error } = this.props;

    const flights = (results || [])
      .slice(0, (page + 1) * 20)
      .map((data, index) => {
        const { bookingDetailsLink } = data;
        if (bookingDetailsLink) {
          const { Uri, Body } = bookingDetailsLink;
          return (
            <Flight
              key={Uri + Body}
              data={data}
              search={search}
              awardOptionsFilter={data.awardOptionsFilter}
              showMinPrice={showMinPrice}
              showMinPoints={showMinPoints}
            />
          );
        }
        return (
          <div key={index} className="search-placeholder">
            <b />
            <i />
            <span />
          </div>
        );
      });

    let paging = null;
    if (page < total - 1) {
      paging = (
        <div className="load-more-button">
          <b
            role="button"
            onClick={this.loadMore}
            onKeyDown={this.loadMore}
            tabIndex={0}
          >
            Show more flights
          </b>
        </div>
      );
    }

    return (
      <div className="search-results">
        <div
          className="search-results-loader"
          style={{ display: loading ? '' : 'none' }}
        >
          {Icons.Loader}
        </div>
        <div
          className="search-error-results"
          style={{ display: error ? '' : 'none' }}
        >
          Whoops! There was an error on our end.
        </div>
        <div
          className="search-no-results"
          style={{
            display: !loading && flights.length === 0 && !error ? '' : 'none',
          }}
        >
          No flights found matching your search.{error}
          <b
            role="button"
            onClick={this.resetFilters}
            onKeyDown={this.resetFilters}
            tabIndex={0}
          >
            Reset Filters
          </b>
        </div>
        <div
          className="search-filters"
          style={{ textAlign: 'right', minHeight: '40px' }}
        >
          <b style={{ display: loaded && flights.length > 0 ? '' : 'none' }}>
            Filters:
          </b>{' '}
          <TextField
            select
            // label="Select"
            className="sort-select"
            value={sort}
            onChange={this.sortResults}
            SelectProps={{
              disableUnderline: true,
            }}
            margin="normal"
            style={{
              display: loaded && flights.length > 0 ? '' : 'none',
              marginTop: 0,
              fontSize: '12px',
            }}
          >
            {sortOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {flights}
        {paging}
      </div>
    );
  }
}
export default Results;
