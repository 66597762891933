import { connect } from 'react-redux';

import AirportAutocomplete from './AirportAutocompleteContainer';

const mapStateToProps = state => ({
  windowState: state.window,
});

// const mapDispatchToProps = { dispatchWindowResize: windowResize };

export default connect(
  mapStateToProps,
  null,
)(AirportAutocomplete);
