import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import { isKey, KeyCodes } from 'utils/KeyCodes';
import { getRef } from 'utils/refUtils';
import { isType } from 'utils/typeUtils';

const emptyFunc = () => {};

/**
 * These scenarios are when props.value set/override state.value:
 * - on mount
 * - if it changes on update (which may result from props.onSave())
 * - if user cancels editing
 *
 * Otherwise, state is set by user typing (event.target.value).
 */
class InputWithEditIcon extends Component {
  static propTypes = {
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
    disableUnderline: PropTypes.bool,
    inputProps: PropTypes.shape({}),
    onEdit: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    type: PropTypes.string,
    validate: PropTypes.func,
    validationMessage: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    ariaLabel: '',
    disableUnderline: false,
    className: undefined,
    inputProps: undefined,
    onEdit: emptyFunc,
    type: 'text',
    validate: () => true,
    validationMessage: undefined,
    value: '',
  };

  constructor(props) {
    super(props);

    const { value } = props;

    this.state = {
      editing: false,
      value,
      valueOriginal: '',
      valid: true,
    };

    this.inputRef = null;
  }

  // componentDidMount() {
  //   this.init()
  // }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (value !== prevProps.value && !isType(value, 'nan')) {
      // this.init();
      this.setState({ value });
    }
  }

  init = () => {
    const { value } = this.props;
    this.setState({ editing: false, value });
  };

  onChange = (evt) => {
    const { validate } = this.props;

    const { value } = evt.target;
    this.setState({ valid: validate(value), value });
  };

  onCancel = () => {
    // this.init();
    this.setState((state) => ({
      editing: false,
      valid: true,
      value: state.valueOriginal,
    }));
  };

  onEdit = () => {
    const { validate, value } = this.props;

    this.setState(
      { editing: true, valid: validate(value), valueOriginal: value },
      () => {
        const ref = getRef(this.inputRef);
        if (ref) {
          ref.focus();
        }
      }
    );
  };

  onKeyDown = (event) => {
    if (isKey(event, KeyCodes.ENTER)) {
      this.onSave();
      return;
    }
    if (isKey(event, KeyCodes.ESCAPE)) {
      this.onCancel();
    }
  };

  onSave = () => {
    const { valid, value } = this.state;

    if (!valid) return;

    const { onSave } = this.props;

    onSave(value);
    this.setState({ editing: false, valueOriginal: value });
  };

  render() {
    const { editing, valid, value } = this.state;
    const {
      ariaLabel,
      className,
      disableUnderline,
      inputProps,
      onEdit,
      type,
      validationMessage,
    } = this.props;

    const muiInputProps = {
      disableUnderline,
      endAdornment: (
        <Fragment>
          <InputAdornment position="end">
            {editing ? (
              <Fragment>
                <IconButton
                  aria-label="close"
                  className="close-icon"
                  onClick={this.onCancel}
                >
                  <CloseIcon />
                </IconButton>
                <IconButton
                  aria-label="done"
                  className="done-icon"
                  disabled={!valid}
                  onClick={this.onSave}
                >
                  <CheckIcon />
                </IconButton>
              </Fragment>
            ) : (
              <IconButton
                aria-label="edit"
                className="edit-icon"
                onClick={this.onEdit}
              >
                <EditIcon />
              </IconButton>
            )}
          </InputAdornment>
        </Fragment>
      ),
      onChange: this.onChange,
      onClick: onEdit,
      onKeyDown: this.onKeyDown,
      type,
      value,
    };

    return (
      <div className={clsx('input-with-edit-icon', className)}>
        <Input
          // defaultValue=""
          inputProps={{
            'aria-label': ariaLabel,
            disabled: !editing,
            ref: (input) => {
              this.inputRef = input;
            },
            ...inputProps,
          }}
          error={!valid}
          {...muiInputProps}
        />
        {!valid && <p className="validation-error">{validationMessage}</p>}
      </div>
    );
  }
}

export default InputWithEditIcon;
