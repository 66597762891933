import _groupBy from 'lodash/groupBy';
import { is, isType } from './typeUtils';

/**
 * Any properties that exist in defaultObj will be checked:
 *  If that property is not undefined, use that value.
 * @example overrideDefaults({a:1, b:2}, {a:0, c:0}) -> {a:1, c:0}
 * @param {object} obj
 * @param {object} defaultObj
 * @returns {object} with default properties overwritten by
 * existing obj properties.
 */
export const overrideDefaults = (obj, defaultObj) => {
  // unless I deep copy, this must be one level deep.
  const copy = { ...defaultObj };
  Object.keys(copy).forEach((key) => {
    const foundValue = obj[key];
    if (!isType(foundValue, 'undefined')) {
      copy[key] = foundValue;
    }
  });
  return copy;
};

/**
 * Categorize array of objects by property
 * @param {Object[]} data
 * @param {function} callback
 * @returns {Object} {airline: [...], hotel: [...], creditCard: [...]}
 */
export const groupBy = (arr, callback) =>
  _groupBy(arr, (item) => callback(item));

/**
 * build a lookup table for any array
 * @param {Object[]} data
 * @param {function} callback
 * @returns {Object} lookup table: { key: prop, value: arrayItem }
 */
export const createLookupTable = (arr, callback) =>
  arr.reduce((dict, item) => {
    const key = callback(item);
    // eslint-disable-next-line no-param-reassign
    dict[key] = item;
    return dict;
  }, {});

/**
 * @param {Object[]|*[]} arr
 * @param {Object} options
 * @param {boolean} options.mutate
 * @param {boolean} options.reverse
 * @param {function} options.sortBy
 * @returns {array} get a sorted array based on options
 */
export const sortWithOptions = (
  arr,
  { mutate = false, reverse = false, sortBy = (x) => x } = {}
) => {
  const data = mutate ? arr : arr.slice();
  return data.sort((a, b) => {
    const itemA = sortBy(a);
    const itemB = sortBy(b);
    return reverse ? itemB - itemA : itemA - itemB;
  });
};

export const getSetCopy = (set) => new Set(Array.from(set));

/**
 * @param {Set} setA
 * @param {Set} setB
 * @returns {Set} items in setA that aren't in setB
 */
// export const difference = (setA, setB) => {
//   const diffSet = new Set(setA);
//   setB.forEach((elem) => {
//     diffSet.delete(elem);
//   });
//   return diffSet;
// };

/**
 * @param {Set} set
 * @param {function} [callback] - called with the set item
 * @returns {Object}
 */
export const setToObject = (set, callback) =>
  Array.from(set).reduce((acc, cur) => {
    acc[cur] = callback ? callback(cur) : cur;
    return acc;
  }, {});

/**
 * @param {Array|Set} item
 * @returns {Set}
 */
export const ensureSet = (item) => (Array.isArray(item) ? new Set(item) : item);

export const runIfFunction = (item) => (is.function(item) ? item() : false);

/**
 * If methodName is a method on obj, run it using `.call()`
 * to keep it in its own `this` scope
 * @param {string} methodName
 * @param {*} [obj]
 */
export const runIfMethod = (methodName, obj) => {
  if (!is.any([is.function, is.array, is.object], obj)) {
    return false;
  }
  const func = obj[methodName];
  return is.function(func) ? func.call(obj) : false;
};

/**
 * Immer helper, modifies array in place
 * @param {Array} arr
 * @param {function} updater
 * @returns {Array} modified when updater contains a match
 */
export const findAndUpdate = (arr, updater) =>
  arr.map((item) => {
    updater(item);
    return item;
  });
