import PropTypes from 'prop-types';

/** SelectMenu and SelectMenuBasic */
const selectPropsCommon = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string.isRequired,
};

const selectDefaultCommon = {
  className: null,
};

export const selectMenuBasicPropTypes = selectPropsCommon;
export const selectMenuBasicDefaults = selectDefaultCommon;

export const selectMenuPropTypes = {
  ...selectPropsCommon,
  muiVariation: PropTypes.oneOf(['Select', 'TextField']),
};

export const selectMenuDefaults = {
  ...selectDefaultCommon,
  muiVariation: 'TextField',
};
