import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { flightClassData } from 'utils/forms/formData';

const { options } = flightClassData;

const createEvent = (value) => ({ target: { value } });

const getButtonByIdx = (idx, onClick, value) => {
  const displayIdx = String(idx + 1);
  const active = displayIdx === value;

  return (
    <Button
      className={clsx(active && 'cabin-btn-active')}
      onClick={() => onClick(createEvent(displayIdx))}
      variant="outlined"
    >
      {options[idx].text}
    </Button>
  );
};

const CabinMobile = (props) => {
  const { flightClass, onChangeFlightClass } = props;

  return (
    <div className="cabin-container">
      <Typography className="text-center" variant="h4">
        Cabin
      </Typography>
      <div className="cabin-buttons-wrapper text-center">
        {getButtonByIdx(0, onChangeFlightClass, flightClass)}
        {getButtonByIdx(1, onChangeFlightClass, flightClass)}
        {getButtonByIdx(2, onChangeFlightClass, flightClass)}
        {getButtonByIdx(3, onChangeFlightClass, flightClass)}
      </div>
    </div>
  );
};

CabinMobile.propTypes = {
  flightClass: PropTypes.string.isRequired,
  onChangeFlightClass: PropTypes.func.isRequired,
};
// CabinMobile.defaultProps = {}

export default CabinMobile;
