import { makeAPIConstants, namespacer } from 'utils/stringUtils';

const ns = namespacer('user');

// firebase service
export const LOGIN = ns('LOGIN'); // done by user action
export const LOGIN_INIT = ns('LOGIN_INIT'); // done on app load
export const LOGIN_SUCCESS = ns('LOGIN_SUCCESS');
export const LOGIN_FAIL = ns('LOGIN_FAIL');
export const GUEST_INIT = ns('GUEST_INIT');

export const logoutAPIConstants = makeAPIConstants('LOGOUT', ns);

// user info
export const displayNameAPIConstants = makeAPIConstants(
  'DISPLAY_NAME_UPDATE',
  ns
);
export const homeAirportAPIConstants = makeAPIConstants(
  'HOME_AIRPORT_UPDATE',
  ns
);

// loyalty
export const LOYALTY_PROGRAM_BALANCE_UPDATE = ns(
  'LOYALTY_PROGRAM_BALANCE_UPDATE'
);
export const LOYALTY_PROGRAM_ADD = ns('LOYALTY_PROGRAM_ADD');
export const LOYALTY_PROGRAM_DELETE = ns('LOYALTY_PROGRAM_DELETE');
export const LOYALTY_PROGRAMS_UPDATE = ns('LOYALTY_PROGRAMS_UPDATE');

// loyalty status dropdown
export const LOYALTY_PROGRAM_STATUS_UPDATE = ns(
  'LOYALTY_PROGRAM_STATUS_UPDATE'
);

// User data service
export const userDataAPIConstants = makeAPIConstants('USER_DATA', ns);
