import { isValidEmail } from 'utils/Validators';
import { KeyCodes, isKey } from 'utils/KeyCodes';

/** Validate each form field */
/* eslint-disable consistent-return */
export const getEmailError = (email) => {
  if (!email) {
    return 'Email is required.';
  }
  if (!isValidEmail(email)) {
    return 'Enter a valid email.';
  }
};

export const getPassError = (password) => {
  if (!password) {
    return 'Password is required.';
  }
  if (password.length < 6) {
    return 'Password must be at least 6 characters.';
  }
};
/* eslint-enable consistent-return */

export const assignError = (obj, key, err) => {
  if (err) obj[key] = err; // eslint-disable-line no-param-reassign
};

export const getNewPass1Error = (curPass, pass1) => {
  if (!pass1) {
    return 'Required';
  }
  if (pass1 === curPass) {
    return "New password shouldn't match current password.";
  }
  if (pass1.length < 6) {
    return 'Password too short.';
  }
};

export const getNewPass2Error = (pass1, pass2) => {
  if (!pass2) {
    return 'Required';
  }
  if (pass1 !== pass2) {
    return "New passwords don't match.";
  }
};

export const handleEnterKey = (evt, submitFunc) => {
  if (isKey(evt, KeyCodes.ENTER)) {
    return submitFunc();
  }
};
