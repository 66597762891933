import { createSelector } from 'reselect';

export const userStateSelector = (state) => state.user;
export const tokenSelector = (state) => state.user.token;
export const userIDSelector = (state) => state.user.id;
export const lastLoginSelector = (state) => state.user.lastLogin;
export const userProgramsSelector = (state) => state.user.programs;
export const providerSelector = (state) => state.user.providerData;
export const userNameSelector = (state) => state.user.userName;
export const homeAirportSelector = (state) => state.user.homeAirport;

/**
 * @typedef {Object} AuthProvider
 * @property {string} providerId google.com|facebook.com|password
 */

/**
 * @param {Object} state
 * @returns {string} AuthProvider.providerId || ''
 */
export const getProvider = createSelector(providerSelector, (providerData) => {
  if (!providerData.length) {
    return '';
  }

  return providerData.find((item) => item.providerId).providerId;
});
