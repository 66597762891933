// Actions
export const OPEN_MODAL = 'modals/OPEN';
export const CLOSE_MODAL = 'modals/CLOSE';

// Modals
export const LPS = 'LOYALTY_PROGRAM_SELECTOR';

/**
 *
 * @param {string} modal use constant
 * @param {boolean} open
 * @returns {function} action creator which returns action Object
 */
const getModalAction = (modal, open) => () => {
  const type = open ? OPEN_MODAL : CLOSE_MODAL;
  return { modal, type };
};

export const openLoyaltyProgramSelector = getModalAction(LPS, true);
export const closeLoyaltyProgramSelector = getModalAction(LPS, false);

const initialState = {};

export default function (state = initialState, action) {
  const { modal, type } = action;

  switch (type) {
    case OPEN_MODAL:
      return { ...state, [modal]: true };
    case CLOSE_MODAL:
      return { ...state, [modal]: false };
    default:
      return state;
  }
}
