import { ENDPOINTS } from './endpoints';
import { requests } from './requests';

/**
 * @example treklee.com/airports/search?q=bal
 * @param {string} query User typed in query
 * @returns {Promise}
 */
export function fetchAirportData(query) {
  return requests.get({ url: `${ENDPOINTS.airportsSearch}${query}` });
}

/**
 * @example treklee.com/airports/sfo
 * @param {string} threeLetterCode 'sfo' for example
 * @returns {Promise<Object>} Airport
 */
export function getSingleAirportData(threeLetterCode) {
  if (!threeLetterCode || typeof threeLetterCode !== 'string') {
    return null;
  }
  return requests.get({
    url: `${ENDPOINTS.airports}/${threeLetterCode.toUpperCase()}`,
  });
}
