import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import { getRef } from 'utils/refUtils';

const inputPropsPropTypes = PropTypes.shape({
  value: PropTypes.string.isRequired,
});

const getInputBasic = (inputProps) => (
  <input className="input-basic" {...inputProps} />
);

export class AirportInputWithEditIcon extends Component {
  static propTypes = {
    airport: PropTypes.shape({}),
    ariaLabel: PropTypes.string,
    cssClasses: PropTypes.string,
    inputProps: inputPropsPropTypes.isRequired,
    isMobile: PropTypes.bool.isRequired,
    mobileInputFieldOpen: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    airport: null,
    ariaLabel: undefined,
    cssClasses: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };

    this.inputRef = null;
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable react/no-did-update-set-state */
    const { airport, onSave } = this.props;

    if (airport !== null && prevProps.airport === null) {
      onSave(airport);
      this.setState({ editing: false });
    }
  }

  onEdit = (event) => {
    const { inputProps } = this.props;
    const { onMouseDown } = inputProps;

    onMouseDown(event);

    this.setState({ editing: true }, () => {
      const ref = getRef(this.inputRef);
      if (ref) {
        ref.focus();
      }
    });
  };

  getMuiInputProps = () => {
    const { editing, value } = this.state;
    const {
      airport,
      inputProps,
      isMobile,
      mobileInputFieldOpen,
      // onEdit,
    } = this.props;

    const { onMouseDown } = inputProps;

    const inputValue =
      typeof inputProps.value === 'string' ? inputProps.value : value;

    const muiInputProps = {
      disableUnderline: mobileInputFieldOpen,
      error: editing && airport === null,
      value: inputValue,
    };

    const showEditIcon = isMobile || !editing;

    muiInputProps.endAdornment = (
      <InputAdornment position="end">
        {showEditIcon ? (
          <IconButton
            aria-label="edit"
            onClick={isMobile ? onMouseDown : this.onEdit}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <div style={{ width: 24 }} />
        )}
      </InputAdornment>
    );

    return muiInputProps;
  };

  render() {
    const { editing } = this.state;
    const {
      ariaLabel,
      cssClasses,
      inputProps,
      mobileInputFieldOpen,
    } = this.props;

    const { value: inputPropValue, ...restOfInputProps } = inputProps;

    if (mobileInputFieldOpen) {
      return getInputBasic(inputProps);
    }

    return (
      <div className={clsx('input-with-edit-icon', cssClasses)}>
        <Input
          inputProps={{
            'aria-label': ariaLabel,
            disabled: !editing,
            ...restOfInputProps,
            ref: (input) => {
              this.inputRef = input;
            },
          }}
          {...this.getMuiInputProps()}
        />
      </div>
    );
  }
}

export const AirportInput = (props) => {
  const { inputProps, mode } = props;

  if (mode === 'basic') {
    return getInputBasic(inputProps);
  }

  return <AirportInputWithEditIcon {...props} />;
};

AirportInput.propTypes = {
  inputProps: inputPropsPropTypes.isRequired,
  mode: PropTypes.string,
};

AirportInput.defaultProps = {
  mode: 'basic',
};
