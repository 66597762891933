import axios from 'axios';

const ERRORS = {
  noData: 'Expected data.',
  noURL: 'Expected url.',
};

const getResData = (res) => res.data;

// make sure to include params in url
const makeGetRequest = ({ config, url }) => {
  if (!url) {
    throw new Error(ERRORS.noURL);
  }
  return axios.get(url, config).then(getResData);
};

const makePostRequest = ({ config, data, url }) => {
  if (!data || !url) {
    throw new Error(ERRORS.noData);
  }
  return axios.post(url, data, config).then(getResData);
};

const makePutRequest = ({ config, data, url }) => {
  if (!data || !url) {
    throw new Error(ERRORS.noData);
  }
  return axios.put(url, data, config).then(getResData);
};

// axios doesn't allow `data` as parameter in delete request.
const makeDeleteRequest = ({ config, url }) => {
  if (!url) {
    throw new Error(ERRORS.noURL);
  }
  return axios.delete(url, config).then(getResData);
};

export const requests = {
  delete: makeDeleteRequest,
  get: makeGetRequest,
  post: makePostRequest,
  put: makePutRequest,
};
