import { tripTypeData } from 'utils/forms/formData';
import { convertToMoment } from 'utils/date';
import { overrideDefaults } from 'utils/dataUtils';
import { decodeUrlParams } from 'utils/urlParams';

const { oneWay, roundTrip } = tripTypeData.values;

const defaultSearch = {
  tripType: roundTrip,
  flightClass: '1',
  loyalty: [],
};

const defaultPassengers = {
  adults: 1,
  children: 0,
  infants: 0,
};

/**
 * @param {object} searchParams decoded query string of search params
 * @returns {object} search that can be used in UI
 */
const getValidSearch = searchParams => {
  const {
    date,
    destinationName,
    flightClass: flightClassNum,
    iata,
    loyalty,
    originName,
  } = searchParams;

  const overrides = {
    ...overrideDefaults(
      {
        loyalty,
        flightClass: String(flightClassNum),
      },
      defaultSearch,
    ),
    passengers: overrideDefaults(searchParams, defaultPassengers),
  };

  const [startDate, endDate] = date.split('_').map(convertToMoment);

  if (endDate) {
    overrides.dates = {
      startDate,
      endDate,
    };
  } else {
    overrides.dates = { date: startDate };
    overrides.tripType = oneWay;
  }

  const [originIATA, destinationIATA] = iata.split('_');

  overrides.origin = {
    shortDisplayName: originName,
    iata: originIATA,
  };
  overrides.destination = {
    shortDisplayName: destinationName,
    iata: destinationIATA,
  };

  // loyalty can be a single number. it should be an array.
  if (!Array.isArray(loyalty)) {
    overrides.loyalty = [loyalty];
  }

  return overrides;
};

/**
 * We're assuming that nextProps !== prevProps
 * @param {object} nextProps
 * @returns {object} { location,
 *   search,
 *   _search: location.search };
 */
export function getDerivedState(nextProps) {
  const { location } = nextProps;
  const params = decodeUrlParams(location.search);
  const search = getValidSearch(params);
  return {
    location,
    search,
    _search: location.search,
  };
}
