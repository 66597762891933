import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import clsx from 'clsx';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';

import { historyPropType, userStatePropType } from 'utils/propTypes';
import { routesArray } from 'components/Account/AccountRoutes/routesArray';

import { getTotalPoints } from 'mock/mockAccountData';

const [, loyaltyRoute, tripRoute, recRoute] = routesArray;

/* eslint-disable react/prop-types */
const withCard = (children, props, routeObj) => {
  const { cardClassName, cardActionClassName, history } = props;
  const { Icon, name, url } = routeObj;

  return (
    <Card className={clsx('card-layout', cardClassName)}>
      <CardActionArea
        className={clsx('card-action-layout', cardActionClassName)}
        onClick={() => {
          history.push(url);
        }}
      >
        <div className="card-children">
          <Icon className="dashboard-icon" />
          <h2>{name}</h2>
          {children}
        </div>
      </CardActionArea>
    </Card>
  );
};
/* eslint-enable react/prop-types */

const Dashboard = (props) => {
  const { openLPS, userState } = props;
  const { programs } = userState;

  return (
    <div className="account-dashboard">
      {withCard(
        <Fragment>
          <div>{getTotalPoints(programs)} points</div>
          {programs.length === 0 ? (
            <div>
              <Link
                className={clsx(
                  'account-dashboard-loyalty-link-btn',
                  // use MUI button styles on <a /> link
                  // to prevent invalid DOM nesting:
                  // bad: <button> <button></button> </button>
                  // good: <button> <a></a> </button>
                  'MuiButtonBase-root MuiButton-root MuiButton-text'
                )}
                onClick={openLPS}
                to={loyaltyRoute.url}
              >
                Start adding programs
              </Link>
            </div>
          ) : (
            <div>{programs.length} loyalty programs</div>
          )}
        </Fragment>,
        props,
        loyaltyRoute
      )}
      {withCard(
        <Fragment>
          <div style={{ marginBottom: 4 }}>0 upcoming</div>
          <div>0 completed</div>
        </Fragment>,
        props,
        tripRoute
      )}
      {withCard(
        <Fragment>
          <div>4 recommended</div>
        </Fragment>,
        props,
        recRoute
      )}
    </div>
  );
};

Dashboard.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  history: historyPropType.isRequired,
  openLPS: PropTypes.func.isRequired,
  userState: userStatePropType.isRequired,
};

export default withRouter(Dashboard);
