import { Component } from 'react';
import PropTypes from 'prop-types';
import store from 'store';

// UTILS

function throwNoParentError() {
  throw new Error(`No "parent" prop was provided to react-simple-storage. A parent component's context is required in order to access and update the parent component's state.
  \nTry the following: <SimpleStorage parent={this} />`);
}

function testStorage() {
  const test = 'test';
  try {
    store.set(test, test);
    store.remove(test);
    return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('react-simple-storage could not access any storage options.');
    return false;
  }
}

export function clearStorage(prefix) {
  if (testStorage() === true) {
    store.each((value, key) => {
      if (key.includes(prefix)) {
        store.remove(key);
      }
    });

    store.set('rss_cleared', true);
  }
}

export function resetParentState(parent, initialState = {}, keysToIgnore = []) {
  if (testStorage() === true) {
    Object.entries(initialState).forEach(([key, value]) => {
      // reset property if not in keys to ignore
      if (!keysToIgnore.includes(key)) {
        parent.setState({ [key]: value });
      }
    });
  }
}

class SimpleStorage extends Component {
  static propTypes = {
    blacklist: PropTypes.arrayOf(PropTypes.string),
    /**
     * An object containing transform functions that correspond
     * with keys in state
     * @prop {Object} { {string} state key: {function} transform function}
     */
    preSave: PropTypes.shape({}),
    parent: PropTypes.shape({
      setState: PropTypes.func,
      state: PropTypes.shape({}),
    }).isRequired,
    prefix: PropTypes.string,
  };

  static defaultProps = {
    blacklist: [],
    preSave: {},
    prefix: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      didHydrate: false,
      firedHydrateCallback: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // callback function that fires after the parent's state
    // has been hydrated with storage items
    if (
      'onParentStateHydrated' in props &&
      state.didHydrate &&
      !state.firedHydrateCallback
    ) {
      props.onParentStateHydrated();
      return {
        ...state,
        ...{ firedHydrateCallback: true },
      };
    }
    return state;
  }

  componentDidMount() {
    if (testStorage() === true) {
      this.hydrateStateWithStorage();
      window.addEventListener('pagehide', this.saveStateToStorage.bind(this));
    }
  }

  componentWillUnmount() {
    if (testStorage() === true) {
      this.saveStateToStorage();
      window.removeEventListener(
        'pagehide',
        this.saveStateToStorage.bind(this)
      );
    }
  }

  /* eslint-disable react/sort-comp */

  hydrateStateWithStorage() {
    const { parent, prefix } = this.props;

    if (!parent) {
      throwNoParentError();
    }

    // loop through storage
    store.each((value, key) => {
      // if the storage item is in the current parent's state
      if (key.includes(prefix)) {
        // remove the parent-specific prefix to get original key
        // from parent's state
        const name = key.slice(prefix.length + 1);

        // update parent's state with the result
        // store.js handles parsing
        if (name in parent.state) {
          parent.setState({ [name]: value });
        }
      }
    });

    this.setState({ didHydrate: true });
  }

  getValueAfterPreSave(key, value) {
    const { preSave } = this.props;
    const foundPreSaveHook = preSave[key];

    return foundPreSaveHook ? foundPreSaveHook(value) : value;
  }

  saveStateToStorage(allowNewKey = true) {
    if (store.get('rss_cleared')) {
      store.set('rss_cleared', false);
      return;
    }

    const { parent, prefix, blacklist } = this.props;

    if (!parent) {
      throwNoParentError();
    }

    // loop through all of the parent's state
    Object.entries(parent.state).forEach(([key, value]) => {
      // save item to storage if not on the blacklist
      const prefixWithKey = `${prefix}_${key}`;
      if (!blacklist.includes(key) && allowNewKey) {
        store.set(prefixWithKey, this.getValueAfterPreSave(key, value));
      }
    });
  }

  render() {
    return null;
  }
}

export default SimpleStorage;
