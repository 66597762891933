import Validator from 'fastest-validator';

import { replaceAll } from './stringUtils';

/** Factory */
export const validatorFactory = new Validator();

/** Schemas */
export const validEmailSchema = {
  email: { type: 'email' },
};

const compiledValidEmailSchema = validatorFactory.compile(validEmailSchema);

export const validPasswordSchema = {
  password: { type: 'string', min: 6 },
};

const compiledValidPasswordSchema = validatorFactory.compile(
  validPasswordSchema
);

export const validUserNameSchema = {
  name: {
    alphanum: true,
    type: 'string',
    min: 1,
    // max: 30,
  },
};

const compiledValidUserNameSchema = validatorFactory.compile(
  validUserNameSchema
);

/** Validators */

/** This allows us a simple boolean check for valid email */
export const isValidEmail = (email) => {
  if (email.length <= 5) {
    return false;
  }
  return compiledValidEmailSchema({ email }) === true;
};

export const isValidPassword = (password) =>
  compiledValidPasswordSchema({ password }) === true;

export const isValidUserName = (name) => {
  // includes spaces
  if (name.length > 30) {
    return false;
  }

  // exclude spaces to let alphanum do its thing
  return (
    compiledValidUserNameSchema({ name: replaceAll(name, ' ', '') }) === true
  );
};
