import React, { Component } from 'react';

import ICONS from 'utils/Icons';

import defaultData from './defaultData';

class GoalsAndTrips extends Component {
  constructor(props) {
    super(props);
    this.state = defaultData;
  }

  render() {
    const { goals } = this.state;
    const goalsRender = goals.map(goal => {
      const programs = goal.programs.map(program => (
        <div className="program" key={program.id}>
          <i>
            <b>{program.name} miles:</b> {program.value.toLocaleString()}/
            {program.target.toLocaleString()}
          </i>
          <b>
            <u
              style={{ width: `${(100 * program.value) / program.target}%` }}
            />
          </b>
        </div>
      ));
      return (
        <div className="item">
          <div className="goal">
            <div
              className="image"
              style={{ backgroundImage: `url(${goal.image})` }}
            >
              <div className="summary">
                <h2>{goal.destination}</h2>
                <span>{`${goal.start} - ${goal.end}`}</span>
                <span>{goal.airline}</span>
                <i>
                  <u>
                    {goal.originIATA}
                    <b>{ICONS.ArrowRight}</b>
                  </u>
                  <u>
                    {goal.destinationIATA}
                    <b>{ICONS.ArrowLeft}</b>
                  </u>
                </i>
              </div>
            </div>
            <div className="programs">
              {programs}
              <span>Sync Rewards Wallet to update points automatically</span>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className="goals-trips">
        <h1>Goals And Trips</h1>
        <div className="goals">{goalsRender}</div>
      </div>
    );
  }
}

export default GoalsAndTrips;
