import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  closeLoyaltyProgramSelector,
  LPS,
  openLoyaltyProgramSelector,
} from 'modules/modals';
import { getSetCopy } from 'utils/dataUtils';
import { windowStatePropType } from 'utils/propTypes';

import LoyaltySelectionView from './LoyaltySelectionView';
import { getPropsDiff } from '../utils';

const dfltSet = new Set();

class LoyaltySelectionState extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    /* This used to be value */
    programs: PropTypes.arrayOf(PropTypes.object),
    programsDict: PropTypes.shape({}),
    selected: PropTypes.instanceOf(Set),
    status: PropTypes.string.isRequired,
    /* React Node, button to open Drawer */
    trigger: PropTypes.func.isRequired,
    windowState: windowStatePropType.isRequired,
  };

  static defaultProps = {
    programs: [],
    programsDict: {},
    selected: dfltSet,
  };

  constructor(props) {
    super(props);

    const newState = getPropsDiff({ selected: dfltSet }, props);

    this.state = {
      // airline: [],
      // creditCard: [],
      // hotel: [],
      // open: false,
      // programsDict: {},
      // programs: [],
      search: '',
      selected: newState ? newState.selected : dfltSet,
      // status: props.status,
      // valueCache: [],
    };
  }

  /** Programs arrive asynchronously */
  componentDidUpdate(prevProps) {
    /* eslint-disable react/no-did-update-set-state */
    const { selected } = this.props;
    const { selected: prevSelected } = prevProps;

    if (prevSelected !== selected) {
      this.setState({ selected });
    }
  }

  onSearchInput = (event) => {
    this.setState({ search: event.target.value });
  };

  onClearSearchInput = (event) => {
    event.preventDefault();
    this.onSearchInput({ target: { value: '' } });
  };

  onSelect = (loyaltyId) => () => {
    const { selected } = this.state;
    const newSelected = getSetCopy(selected);
    if (selected.has(loyaltyId)) {
      newSelected.delete(loyaltyId);
    } else {
      newSelected.add(loyaltyId);
    }
    this.setState({ selected: newSelected });
  };

  onClose = () => {
    const { onChange } = this.props;
    const { selected } = this.state;

    onChange(selected);
  };

  render() {
    const { onClearSearchInput, onSearchInput, onSelect } = this;
    const { search, selected } = this.state;

    return (
      <LoyaltySelectionView
        {...this.props}
        {...{
          onClearSearchInput,
          onSearchInput,
          onSelect,
          state: {
            // programs,
            search,
            selected,
            // status,
          },
        }}
        onClose={this.onClose}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  windowState: state.window,
  modalOpenRedux: state.modals[LPS] === true,
});

const mapDispatchToProps = {
  closeLPS: closeLoyaltyProgramSelector,
  openLPS: openLoyaltyProgramSelector,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoyaltySelectionState);
