import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const getBtnText = (type, btn) => {
  if (type === 'login') {
    return `Log in with ${btn.displayName}`;
  }
  return `Continue with ${btn.displayName}`;
};

const googleBtn = {
  displayName: 'Google',
  className: 'btn-dialog-full-width btn-dialog-google-signup',
  img: <img src="/img/google-logo-rainbow.png" alt="Google sign-in logo" />,
};

const facebookBtn = {
  displayName: 'Facebook',
  className: 'btn-dialog-full-width btn-dialog-facebook-signup',
  img: <img src="/img/facebook-logo-blue.png" alt="Facebook sign-in logo" />,
};

const getBtnAttributes = {
  facebook: facebookBtn,
  google: googleBtn,
};

const LoginSignupButton = ({ className, onClick, service, type }) => {
  const btnAttributes = getBtnAttributes[service];
  return (
    <Button
      className={`${className} ${btnAttributes.className}`}
      color="primary"
      onClick={onClick}
      variant="contained"
    >
      {btnAttributes.img}
      {getBtnText(type, btnAttributes)}
    </Button>
  );
};

LoginSignupButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  service: PropTypes.oneOf(['facebook', 'google']),
  type: PropTypes.oneOf(['login', 'signup']),
};

LoginSignupButton.defaultProps = {
  className: '',
  onClick: () => {},
  service: 'google',
  type: 'login',
};

export default LoginSignupButton;
