import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import Icons from 'utils/Icons';
import { createUrlParams } from 'utils/urlParams';

import { ROOT } from 'components/App/routeConstants';
import { SearchForm } from 'components/FlightSearchScene/Search';

import { Destinations } from './Destinations';
import { mockDestinations } from './destinationData';
import Footer from './Footer';

class Home extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  static defaultProps = {
    history: {
      push: () => {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      // mobileDrawerOpen: false,
    };
  }

  /**
   * Creates a query param string and re-routes to result url
   * @param {object} data - user input data
   * @returns {undefined}
   */
  submitSearchForm = (data) => {
    const { history } = this.props;
    const url = createUrlParams(data);
    history.push(url);
  };

  render() {
    return (
      <div className="app">
        <div className="homepage-hero">
          <div className="homepage-hero-inner-div">
            <div className="content homepage-hero-outer-content">
              <div className="homepage-text-headings">
                <h1 className="homepage-large-text-header">
                  Travel more, spend less
                </h1>
                <h2 className="text-center">
                  Compare cash and points flights to find the best deals.
                </h2>
              </div>
              <SearchForm onSearch={this.submitSearchForm} />
            </div>
          </div>
        </div>

        <div className="homepage-boxes">
          <div className="section">
            <div>
              <div className="box">
                <b>{Icons.LightBulb}</b>
                <h2>Points made easy</h2>
                <p>
                  Redeeming your points for travel shouldnt be a chore. We take
                  the guesswork out of booking award travel with our interactive
                  guides.
                </p>
              </div>
            </div>
            <div>
              <div className="box">
                <b>{Icons.CreditCard}</b>
                <h2>Best travel credit cards</h2>
                <p>
                  Search for flights from 85 loyalty programs and over 1,000
                  travel websites. Compare cash and points fares to get the best
                  value.
                </p>
              </div>
            </div>
            <div>
              <div className="box">
                <b>{Icons.Globe}</b>
                <h2>Travel the world</h2>
                <p>
                  Try our inspiration search enginer for a list of the hottest
                  destinations to book with points, tailored specifically for
                  you.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="homepage-partners">
          <div className="content">
            <div className="partners">
              <h4>Over 80 rewards partners:</h4>
              <div>
                <img src="/img/home/united.png" alt="United Airlines" />
              </div>
              <div>
                <img src="/img/home/chase.png" alt="Chase" />
              </div>
              <div>
                <img src="/img/home/american.png" alt="American Airline" />
              </div>
              <div>
                <img src="/img/home/amex.png" alt="American Express" />
              </div>
              <div>
                <img src="/img/home/citi.png" alt="Citi" />
              </div>
              <Link to={ROOT}>See All 87 &gt;</Link>
            </div>
          </div>
        </div>

        <div className="homepage-destination">
          <div className="holder">
            <div className="content">
              <h2>Great Points Deals</h2>
              <Destinations destinations={mockDestinations} />
              <div>
                <Button className="see-more" href="#">
                  See more deals &gt;
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="homepage-text homepage-text01">
          <img className="bg" alt="bg" src="/img/home/homepage-1.png" />
          <div className="section section-1">
            <div className="content">
              <div className="text">
                <h2>
                  Get the best value,
                  <br />
                  every time.
                </h2>
                <p>
                  Search for flights from 85 loyalty programs and over 1,000
                  travel websites. Compare cash and points fares to get the best
                  value.
                </p>
                <Link className="homepage-link" to={ROOT}>
                  Sign Up
                </Link>
              </div>
              <div className="airplane">
                <img
                  className="great"
                  src="/img/home/great-points-value.svg"
                  alt="United Airlines"
                />
                <img
                  className="poor"
                  src="/img/home/poor-cash-value.svg"
                  alt="United Airlines"
                />
                <img
                  className="airplane"
                  alt="ariplane"
                  src="/img/home/airplanes.svg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="homepage-text homepage-text02">
          <div className="section section-3">
            <div>
              <div className="content">
                <div className="text">
                  <h2>
                    Need some travel
                    <br />
                    inspiration?
                  </h2>
                  <p>
                    Try our inspiration search enginer for a list of the hottest
                    destinations to book with points, tailored specifically for
                    you.
                  </p>
                  <Link className="homepage-link" to={ROOT}>
                    Sign Up
                  </Link>
                </div>
              </div>
              <div className="images">
                <img className="bg" alt="bg" src="/img/home/homepage-3.png" />
                <img
                  className="screen"
                  alt="screen"
                  src="/img/home/home-page-screen.svg"
                />
                <img
                  className="person"
                  alt="person"
                  src="/img/home/homepage-person.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="homepage-steps">
          <div className="content">
            <div className="text">
              <h2>We make booking a breeze.</h2>
              <p>
                Redeeming your points for travel shouldn&apos;t be a chore. We
                take the guesswork out of booking award travel with our
                interactive guides.
              </p>
              <Link className="homepage-link" to={ROOT}>
                Sign Up
              </Link>
            </div>
            <div className="card check-dates">
              <span>Step 1</span>
              <b>
                Check
                <br />
                Dates
              </b>
            </div>
            <div className="card transfer-points">
              <span>Step 2</span>
              <b>
                Transfer
                <br />
                Points
              </b>
            </div>
            <div className="card book-flights">
              <span>Step 3</span>
              <b>
                Book
                <br />
                Flights
              </b>
            </div>
          </div>
        </div>
        <div className="homepage-signup">
          <h2 className="homepage-large-text-header">Ready to get going?</h2>
          <Link className="homepage-link" to={ROOT}>
            Sign Up
          </Link>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Home);
