import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import Icons from 'utils/Icons';

// const chipStyles = {
// root: {
//   backgroundColor: '#0191ff',
//   color: 'white',
//   '&:focus': {
//     backgroundColor: '#0191ff',
//   },
// },
// };

// const LoyaltyChip = withStyles(chipStyles)(
// const LoyaltyChip = ({ classes, className, label, onDelete }) => {
const LoyaltyChip = ({ className, label, onDelete }) => {
  return (
    <Chip
      {...{
        label,
        onDelete,
      }}
      // className={clsx(classes.root, 'loyalty-chip-poc', className)}
      className={clsx('loyalty-chip-poc', className)}
      color="primary"
      deleteIcon={Icons.Close}
    />
  );
};

LoyaltyChip.propTypes = {
  // classes: PropTypes.shape({}),
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};

LoyaltyChip.defaultProps = {
  className: undefined,
  onDelete: undefined,
};

export default LoyaltyChip;
