import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const SelectedChips = (props) => {
  const { chipsNode, isMobile, showValidation } = props;
  const numSelected = chipsNode.length;

  return (
    <div
      className={clsx(isMobile ? 'mob' : 'dsktp', 'selected-chips-container')}
    >
      {!isMobile && (
        <div className="selected-num">
          {numSelected} {`program${numSelected === 1 ? '' : 's'}`} selected
        </div>
      )}
      <div className="selected-chips">
        {numSelected === 0 && showValidation ? (
          <span className="no-programs-selected">
            Choose at least one loyalty program to view your flight results.
          </span>
        ) : (
          chipsNode
        )}
      </div>
    </div>
  );
};

SelectedChips.propTypes = {
  chipsNode: PropTypes.node.isRequired,
  isMobile: PropTypes.bool,
  showValidation: PropTypes.bool,
};

SelectedChips.defaultProps = {
  isMobile: false,
  showValidation: true,
};

export default SelectedChips;
