export const ROOT = '/';
export const HOME = '/home';
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const SEARCH = '/search';
export const FORGOT_PASSWORD = '/forgot-password';
export const PRIVACY = '/privacy';

export const ACCOUNT = '/account';

const addBaseAccountRoute = (route) => `${ACCOUNT}/${route}`;

export const LOYALTY = addBaseAccountRoute('loyalty-programs');
export const TRIPS = addBaseAccountRoute('trips');
export const RECS = addBaseAccountRoute('recommendations');
