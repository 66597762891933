import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export const AwardWalletSubtext = () => (
  <div className="award-wallet-subtext">
    <Typography variant="h6">
      AwardWallet user? Sync your programs automatically.
    </Typography>
  </div>
);

const AwardWalletBtn = ({ btnProps, cssClasses, onClick }) => {
  return (
    <Button
      className={clsx('award-wallet-btn', cssClasses)}
      onClick={onClick}
      size="large"
      startIcon={<img src="/img/logo_award_wallet_small.png" alt="" />}
      variant="contained"
      {...btnProps}
    >
      Sync with AwardWallet
    </Button>
  );
};

AwardWalletBtn.propTypes = {
  btnProps: PropTypes.shape({}),
  cssClasses: PropTypes.string,
  onClick: PropTypes.func,
};

AwardWalletBtn.defaultProps = {
  btnProps: undefined,
  cssClasses: undefined,
  onClick: () => {},
};

export default AwardWalletBtn;
