import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import LoginSignupButton from 'components/Shared/LoginSignupButton';

const ServiceHandler = ({ onClick, type }) => (
  <Fragment>
    <LoginSignupButton
      type={type}
      service="google"
      onClick={onClick('google')}
    />
    <LoginSignupButton
      type={type}
      service="facebook"
      onClick={onClick('facebook')}
    />
  </Fragment>
);

ServiceHandler.propTypes = {
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['login', 'signup']).isRequired,
};

// ServiceHandler.defaultProps = {
//   onClick: () => {},
//   type: 'login',
// };

export default ServiceHandler;
