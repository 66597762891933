import moment from 'moment';

const today = moment().startOf('day');
const dfltFormat = 'YYYY-MM-DD';

export const isMoment = (date) => moment.isMoment(date);

export const isDate = (date) => moment.isDate(date);

/**
 * Converts any date format to moment
 * @param {*} date
 * @returns {Object|null} moment object or null
 */
export const convertToMoment = (date) => {
  if (!date) {
    return null;
  }
  if (isMoment(date)) {
    return date;
  }
  return moment(date);
};

/**
 * Transform moment date to String
 * @param {Object} [momentDate] moment object
 * @param {string} format moment format
 * @returns {string} empty string if momentDate undefined
 */
export const dateToString = (momentDate, format = dfltFormat) =>
  momentDate ? convertToMoment(momentDate).format(format) : '';

/**
 * @param {*} date
 * @returns {boolean} true if moment in past,
 *    false otherwise (including if date is null)
 */
export const isBeforeToday = (date) => {
  const converted = convertToMoment(date);
  return converted ? converted.isBefore(today) : false;
};

// could consider year...but not for now
// ensure moment before using
export const isSameMonth = (date1, date2) => date1.month() === date2.month();
