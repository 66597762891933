import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';

import { saveButtonDialog } from 'utils/styles';
import DialogTitleWithCloseButton from 'components/Shared/DialogTitleWithCloseButton';

import CabinMobile from './CabinMobile';
import { renderPassengerTypeButtons } from './utils';

const scrollDialogStyles = {
  saveButton: saveButtonDialog,
};

function PassengerCountMobile(props) {
  const {
    classes,
    error,
    flightClass,
    onChangeFlightClass,
    onCancel,
    onSave,
    tempState: temp,
    trigger,
    updateQty,
  } = props;

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (error.length > 0) {
      onCancel();
      return;
    }
    onSave();
  };

  return (
    <Fragment>
      {trigger({ onOpen: handleOpen })}
      <Dialog
        aria-describedby="scroll-dialog-description"
        aria-labelledby="passenger-count-mobile-dialog-title"
        className="passenger-count-mobile-dialog"
        fullScreen
        fullWidth
        // maxWidth="lg" // md, lg, and more
        onClose={handleClose}
        open={open}
        // scroll="paper"
      >
        <DialogTitleWithCloseButton
          className="passenger-count-mobile-dialog-title"
          id="passenger-count-mobile-dialog-title"
          onClose={handleClose}
          title="Passengers"
        />
        <DialogContent className="passenger-count-content">
          <div className="passenger-count-wrapper">
            {renderPassengerTypeButtons(temp, updateQty)}
            {error.length > 0 && (
              <div className="passenger-count-validation-error">{error}</div>
            )}
          </div>
          <CabinMobile
            flightClass={flightClass}
            onChangeFlightClass={onChangeFlightClass}
          />
        </DialogContent>
        <DialogActions className="save-btn-container">
          <Button
            className={classes.saveButton}
            disabled={error.length > 0}
            onClick={handleClose}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

PassengerCountMobile.propTypes = {
  classes: PropTypes.shape({
    saveButton: PropTypes.string,
  }).isRequired,
  error: PropTypes.string.isRequired,
  flightClass: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChangeFlightClass: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  tempState: PropTypes.shape({}).isRequired,
  /* React Node, button to open Drawer */
  trigger: PropTypes.func.isRequired,
  updateQty: PropTypes.func.isRequired,
};

PassengerCountMobile.defaultProps = {};

export default withStyles(scrollDialogStyles)(PassengerCountMobile);
