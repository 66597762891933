import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

const styles = {
  button: {
    height: 110,
  },
  card: {
    // minWidth: 275,
    width: 160,
  },
  cardFocus: {
    backgroundColor: 'transparent',
  },
  cardContent: {
    padding: 0,
  },
};

function OutlinedCard({
  classes,
  children,
  contentClassname,
  onClick,
  selected,
}) {
  return (
    <div className="card-outline-container">
      <Card
        className={clsx(
          selected ? 'airline-card-outlined' : 'airline-card',
          classes.card
        )}
      >
        <CardActionArea
          classes={{
            focusHighlight: classes.cardFocus,
            focusVisible: classes.cardFocus,
          }}
          className={classes.button}
          onClick={onClick}
        >
          <CardContent className={clsx(classes.cardContent, contentClassname)}>
            <>{children}</>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

OutlinedCard.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string,
    card: PropTypes.string,
    cardFocus: PropTypes.string,
    cardContent: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  contentClassname: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

OutlinedCard.defaultProps = {
  contentClassname: '',
};

export default withStyles(styles)(OutlinedCard);
