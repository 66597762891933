import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  updateLoyaltyProgramBalance as updateLoyaltyProgramBalanceAction,
  updateLoyaltyPrograms as updateLoyaltyProgramsAction,
  updateLoyaltyProgramStatus as updateLoyaltyProgramStatusAction,
} from 'modules/user/actions';
import { loyaltyStatePropType, userStatePropType } from 'utils/propTypes';

import AwardWalletBtn, {
  AwardWalletSubtext,
} from 'components/Shared/AwardWalletBtn';
import { withPaper } from 'components/Shared/withPaper';

import LoyaltyPrograms from './LoyaltyPrograms';

const LoyaltyPage = ({
  loyaltyState,
  updateLoyaltyProgramBalance,
  updateLoyaltyPrograms,
  updateLoyaltyProgramStatus,
  userState,
}) => {
  return (
    <div className="loyalty-page-container layout-max-width-centered layout-page-padding">
      {withPaper(
        LoyaltyPrograms,
        {
          loyaltyState,
          updateLoyaltyProgramBalance,
          updateLoyaltyPrograms,
          updateLoyaltyProgramStatus,
          userState,
        },
        'padding-lr0'
      )}
      <div className="award-wallet-section text-center">
        <AwardWalletBtn />
        <AwardWalletSubtext />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loyaltyState: state.loyalty,
  userState: state.user,
});

const mapDispatchToProps = {
  updateLoyaltyProgramBalance: updateLoyaltyProgramBalanceAction,
  updateLoyaltyPrograms: updateLoyaltyProgramsAction,
  updateLoyaltyProgramStatus: updateLoyaltyProgramStatusAction,
};

LoyaltyPage.propTypes = {
  loyaltyState: loyaltyStatePropType.isRequired,
  updateLoyaltyProgramBalance: PropTypes.func.isRequired,
  updateLoyaltyPrograms: PropTypes.func.isRequired,
  updateLoyaltyProgramStatus: PropTypes.func.isRequired,
  userState: userStatePropType.isRequired,
};

// LoyaltyPage.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyPage);
