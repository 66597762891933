import React from 'react';
import PropTypes from 'prop-types';

import Icons from 'utils/Icons';
import { flightClassData } from 'utils/forms/formData';

const getCabinText = (flightClass) =>
  flightClassData.options.find((opt) => opt.value === flightClass).text;

const getDisplay = (count, flightClass) => {
  const cabinDisplay = flightClass ? `, ${getCabinText(flightClass)}` : '';
  return `${count} Passenger${count > 1 ? 's' : ''}${cabinDisplay}`;
};

const PassengerCountInputButton = ({
  count,
  flightClass,
  id,
  onClick,
  onKeyDown,
}) => (
  <div
    aria-describedby={id}
    aria-haspopup="true"
    className="passenger-count-button-container"
    onClick={onClick}
    onKeyDown={onKeyDown}
    role="button"
    tabIndex={0}
  >
    <span className="passenger-count-user-icon">{Icons.UserFriends}</span>
    <span className="passenger-count-button">
      {getDisplay(count, flightClass)}
      <span className="passenger-count-button-dropdown-icon dropdown icon">
        <svg
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          role="presentation"
        >
          <path d="M7 10l5 5 5-5z" />
        </svg>
      </span>
    </span>
  </div>
);

PassengerCountInputButton.propTypes = {
  count: PropTypes.number.isRequired,
  flightClass: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};

PassengerCountInputButton.defaultProps = {
  flightClass: undefined,
  id: '',
};

export default PassengerCountInputButton;
