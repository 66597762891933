import React from 'react';
import { func, string } from 'prop-types';
import clsx from 'clsx';
import './back-button-mobile.scss';

// eslint-disable-next-line arrow-body-style
const BackButtonMobile = ({ classes, onClick }) => {
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <div
      className={clsx('back-button-mobile', classes)}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <span>Back</span>
    </div>
  );
};

BackButtonMobile.propTypes = {
  classes: string,
  onClick: func,
};

BackButtonMobile.defaultProps = {
  classes: '',
  onClick: () => {},
};

export default BackButtonMobile;
