import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Formik, Field, Form } from 'formik';
import {
  TextField as FormikTextField,
  CheckboxWithLabel,
} from 'formik-material-ui';
import { LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { loginUser as loginUserAction } from 'modules/user/actions';

import ErrorDialog from 'components/Dialogs/ErrorDialog';
import {
  firebaseLoginWithEmail,
  firebasePopupSignin,
  getAnyUserData,
} from 'services/firebase';
import {
  assignError,
  getEmailError,
  getPassError,
  handleEnterKey,
} from 'utils/forms/Formik';
import {
  historyPropType,
  locationPropType,
  // userStatePropType,
} from 'utils/propTypes';

import ServiceHandler from './ServiceHandler';

import {
  renderOtherMethod,
  rerouteWithState,
  rerouteWithStateOrBackupRoute,
  SIGNUP,
  REROUTE_URL,
} from './utils';

const initialValues = { email: '', password: '', rememberMe: false };

const INITIAL_STATE = {
  errorDialogContent: [null, null],
  errorDialogOpen: false,
};

export class LoginBase extends Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    history: historyPropType.isRequired,
    location: locationPropType.isRequired,
    /* eslint-enable react/no-unused-prop-types */

    loginUser: PropTypes.func.isRequired,
    // className: PropTypes.string,
    // logoutUser: PropTypes.func,
    // userState: PropTypes.shape({}),
  };

  static defaultProps = {
    // className: null,
    // loginUser: () => {},
    // logoutUser: () => {},
    // userState: {
    //   userEmail: undefined,
    //   userPhotoURL: '',
    // },
  };

  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  handleLoginWithEmail = (email, password) =>
    firebaseLoginWithEmail(email, password)
      .then((data) => this.signUpSuccessCallback(data))
      .catch((error) => this.signUpFailureCallback(error));

  handleRoute = (route) => {
    rerouteWithState(this.props, route);
  };

  /**
   * Calls firebasePopupSignin with service
   * @param {string} service - 'google'|'fakebook'
   */
  loginWithService = (service) => () => {
    firebasePopupSignin(service)
      .then((data) => this.signUpSuccessCallback(data))
      .then((res) => {
        // if (res.error) {
        //   throw new Error(res.error)
        // }
        // TODO: why !res.error? should I throw?
        if (res && !res.error) {
          this.onSubmit();
        }
      })
      .catch((error) => this.signUpFailureCallback(error));
  };

  openCloseHandler = (stateKey, open = false, callback = () => {}) => () => {
    this.setState({ [stateKey]: open }, callback);
  };

  onSubmit = () => {
    rerouteWithStateOrBackupRoute(this.props, REROUTE_URL);
  };

  /**
   * TODO: rename to Login success?
   * - if no email in payload, return failure callback
   * - dispatch loginUser
   * @param {Object} data firebase response
   */
  signUpSuccessCallback = (data) => {
    const { loginUser } = this.props;
    const userData = getAnyUserData(data);
    const { email } = userData;

    if (!email) {
      return this.signUpFailureCallback({
        error: true,
        message: 'Email not found.',
      });
    }

    loginUser(data, { needLoginToken: true });
    // create new account on backend - api call with acct info
    return {};
  };

  signUpFailureCallback = (error) => {
    this.setState({
      errorDialogOpen: true,
      errorDialogContent: ['Error: Unable to log in.', [error.message]],
    });
    return { error: true, ...error };
  };

  render() {
    const { errorDialogContent, errorDialogOpen } = this.state;
    const [errorTitle, errorContent] = errorDialogContent;
    return (
      <div className="login-container">
        {errorDialogOpen && (
          <ErrorDialog
            content={errorContent}
            title={errorTitle}
            onClose={this.openCloseHandler('errorDialogOpen', false)}
          />
        )}
        <h1>Log In</h1>
        <ServiceHandler type="login" onClick={this.loginWithService} />
        <div className="hr-with-text">
          <hr className="hr-lite" />
          <span>or</span>
        </div>
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const { email, password } = values;
            const errors = {};
            assignError(errors, 'email', getEmailError(email));
            assignError(errors, 'password', getPassError(password));
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setImmediate(() => {
              this.handleLoginWithEmail(values.email, values.password).then(
                (res) => {
                  // TODO: handle values.rememberMe bool
                  if (res && !res.error) {
                    this.onSubmit();
                  }
                  setSubmitting(false);
                }
              );
            });
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form className="settings-form">
              <Field
                autoComplete="email"
                // autoFocus
                component={FormikTextField}
                fullWidth
                label="Email"
                margin="dense"
                name="email"
                type="email"
              />
              <Field
                autoComplete="Password"
                component={FormikTextField}
                fullWidth
                label="Password"
                margin="dense"
                name="password"
                type="password"
                onKeyDown={(evt) => {
                  handleEnterKey(evt, submitForm);
                }}
              />
              <div className="account-flex">
                <Field
                  name="rememberMe"
                  Label={{ label: 'Remember me' }}
                  component={CheckboxWithLabel}
                  color="default"
                  type="checkbox"
                />
                {
                  // <span className="login-forgot-password">Forgot password?</span>
                }
                <Button
                  onClick={() => {
                    this.handleRoute('/forgot-password');
                  }}
                  // className="btn-dialog-plain"
                  className="login-forgot-password"
                >
                  Forgot password?
                </Button>
              </div>
              {isSubmitting && <LinearProgress />}
              {
                // error.length ? <div className="password-change-success">{error}</div> : null
              }
              <Button
                className="btn-dialog-full-width btn-dialog-email-signup"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                variant="contained"
              >
                Log In
              </Button>
            </Form>
          )}
        </Formik>
        <hr className="hr-lite" />
        {renderOtherMethod({
          onClick: () => this.handleRoute('/signup'),
          otherName: SIGNUP,
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userState: state.user,
});

const mapDispatchToProps = { loginUser: loginUserAction };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginBase)
);
