import React from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

const DesktopRange = props => (
  <DateRangePicker
    startDateId="desktop_range_start_date_id"
    endDateId="desktop_range_end_date_id"
    {...props}
  />
);
export default DesktopRange;
