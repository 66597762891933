import React from 'react';
import PropTypes from 'prop-types';

import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';

const defaultProps = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  autoHideDuration: 6000,
  // autoHideDuration: null, // use for testing
  className: '',
  // open: bool
  message: undefined,
  // action: Node
};

const getSnackbarProps = (...args) => {
  return Object.assign(defaultProps, ...args);
};

export default function SnackbarMUI(props) {
  const { className, onClose, ...rest } = props;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    onClose();
  };

  return (
    <Portal>
      <Snackbar
        classes={{
          anchorOriginTopCenter: className,
        }}
        {...getSnackbarProps(rest, { onClose: handleClose })}
      />
    </Portal>
  );
}

SnackbarMUI.propTypes = {
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOf(['center', 'left', 'right']).isRequired,
    vertical: PropTypes.oneOf(['bottom', 'top']).isRequired,
  }),
  /**
   * The number of milliseconds to wait before automatically calling the
   * `onClose` function. `onClose` should then set the state of the `open`
   * prop to hide the Snackbar. This behavior is disabled by default with
   * the `null` value.
   */
  autoHideDuration: PropTypes.number,
  /** Custom css class name */
  className: PropTypes.string,
  /**
   * The message to display.
   */
  message: PropTypes.node,
  /**
   * Callback fired when the component requests to be closed.
   * Typically `onClose` is used to set state in the parent component,
   * which is used to control the `Snackbar` `open` prop.
   * The `reason` parameter can optionally be used to control the response to `onClose`,
   * for example ignoring `clickaway`.
   *
   * @param {object} event The event source of the callback.
   * @param {string} reason Can be: `"timeout"` (`autoHideDuration` expired), `"clickaway"`.
   */
  onClose: PropTypes.func.isRequired,
  /**
   * If `true`, `Snackbar` is open.
   */
  open: PropTypes.bool.isRequired,
};

SnackbarMUI.defaultProps = defaultProps;
