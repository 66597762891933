import { getLoyaltyPrograms } from 'services/fetchLoyaltyData';

import { createLoyaltyDict, sortLoyaltyPrograms } from './utils';

import { FETCH, FETCH_SUCCESS, FETCH_FAIL } from './constants';

export const fetchLoyaltyPrograms = () => {
  return async (dispatch) => {
    dispatch({ status: 'loading', type: FETCH });

    try {
      const programs = await getLoyaltyPrograms();
      dispatch({
        dict: createLoyaltyDict(programs),
        error: false,
        programs: sortLoyaltyPrograms(programs),
        status: 'done',
        type: FETCH_SUCCESS,
      });
    } catch (error) {
      dispatch({ error, status: 'error', type: FETCH_FAIL });
    }
  };
};
