import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';

import { userStatePropType } from 'utils/propTypes';

import AccountMenuCommon from './AccountMenuCommon';

class UserProfileDropdown extends Component {
  static propTypes = {
    // className: PropTypes.string,
    userState: userStatePropType.isRequired,
  };

  static defaultProps = {
    // className: null,
  };

  state = {
    anchorEl: null,
  };

  handleOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { userState } = this.props;
    const { anchorEl } = this.state;

    const { userEmail, userName } = userState;

    const displayName = userName || 'Hello!';

    return (
      <div className="user-profile-dropdown">
        <Fab aria-label="add" onClick={this.handleOpen}>
          <AccountCircleIcon />
        </Fab>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          className="user-profile-dropdown-menu"
          getContentAnchorEl={null}
          id="user-profile-dropdown-menu-id"
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className="user-profile-dropdown-content" tabIndex={-1}>
            <ul className="account-menu-list user-profile-info">
              <li className="text-bold">{displayName}</li>
              <li className="text-muted">{userEmail}</li>
            </ul>
            {
              // The div.onClick allows the click of any link to close menu
            }
            <div
              onClick={this.handleClose}
              // role="navigation dialog"
              role="presentation"
              tabIndex={-1}
            >
              <AccountMenuCommon className="desktop" />
            </div>
          </div>
        </Menu>
      </div>
    );
  }
}

export default UserProfileDropdown;
