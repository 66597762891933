import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { userProgramsToSet } from 'mock/mockAccountData';
// import { addEvent, removeEvent } from 'utils/browser';
// import Icons from 'utils/Icons';
import { getSetCopy } from 'utils/dataUtils';
import { loyaltyStatePropType, userStatePropType } from 'utils/propTypes';

import SimpleStorage from 'components/Shared/SimpleStorage';

// import LoyaltyChip from './LoyaltyChip';
import LoyaltySelection from './LoyaltySelection';
import { getPropsDiff } from './utils';

// const dfltSet = new Set();

// todo: document the usage of component

class LoyaltyInput extends Component {
  static displayName = 'LoyaltyInput';

  static propTypes = {
    loyaltyState: loyaltyStatePropType.isRequired,
    onChange: PropTypes.func,
    // selected: PropTypes.instanceOf(Set),
    userState: userStatePropType.isRequired,
  };

  static defaultProps = {
    onChange: () => {},
    // selected: dfltSet,
  };

  constructor(props) {
    super(props);

    // don't set selected from parent.
    // const newState = getPropsDiff({ selected: dfltSet }, props);

    this.state = {};
    // this.state = {
    //   loyalty: [],
    //   loyaltyDict: {},
    //   // selected: newState ? newState.selected : dfltSet,
    //   selected: dfltSet,
    //   status: 'loading', // oneOf 'loading', 'done', 'error'
    // };
    // this.cache = {
    //   sortedPrograms: [],
    //   popularProgramsSet: null,
    // };
    // SimpleStorage
    this.initialState = this.state;
  }

  componentDidUpdate(prevProps) {
    // if user logs in, set selected to dfltSet
    // (for now until user has selected programs in their account)
    const {
      userState: { userEmail },
    } = this.props;
    const {
      userState: { userEmail: prevUserEmail },
    } = prevProps;

    let newState = getPropsDiff(prevProps, this.props);

    if (userEmail !== prevUserEmail) {
      newState = {
        ...newState,
        // ...this.getDefaultGuestState(this.cache.sortedPrograms),
      };
    }

    if (Object.keys(newState).length > 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(newState);
    }
  }

  onSave = (selected) => {
    const { onChange } = this.props;
    const selectedCopy = getSetCopy(selected);
    // this.setState({ selected: selectedCopy });
    onChange(selectedCopy);
  };

  render() {
    const { guestInit } = this.state;
    const { loyaltyState, userState } = this.props;
    const { dict: loyaltyDict, programs: loyalty, status } = loyaltyState;

    const selected = userProgramsToSet(userState);
    const Icn = selected.size > 0 ? EditIcon : AddIcon;
    const action = selected.size > 0 ? 'Edit' : 'Add';

    return (
      <Fragment>
        <SimpleStorage
          blacklist={['loyalty', 'loyaltyDict', 'status']}
          // onParentStateHydrated={() => {
          //   this.setState({ selected });
          // }}
          parent={this}
          prefix={LoyaltyInput.displayName}
          preSave={{ selected: Array.from }}
        />

        <LoyaltySelection
          {...{ guestInit, selected, status }}
          onChange={this.onSave}
          programs={loyalty}
          programsDict={loyaltyDict}
          trigger={({ onOpen }) => (
            <Button className="loyalty-input-btn" onClick={onOpen}>
              <span className="loyalty-input-btn-text-desktop">
                {action}&nbsp;
              </span>
              <span className="loyalty-input-btn-text-tablet">
                Loyalty&nbsp;
              </span>
              <span className="loyalty-input-btn-text-mobile">Programs</span>
              <Icn className="" fontSize="small" />
            </Button>
          )}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loyaltyState: state.loyalty,
  userState: state.user,
});

export default connect(mapStateToProps, null)(LoyaltyInput);
