import { dateToString } from 'utils/date';
import { tripTypeData } from 'utils/forms/formData';

import { ENDPOINTS } from 'services/endpoints';
import { requests } from 'services/requests';

import { parseFlightsData } from './parseFlightsData';

export { parseFlightsData };

const { oneWay, roundTrip } = tripTypeData.values;

let currentSessionKey = null;
let hasSearshResults = false;

// Polling is done with a setTimeout of 1 second.
export const pollSearchService = (sessionKey, makeBigLoad, search) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      let url = `${ENDPOINTS.flightSearch}/session/${sessionKey}`;
      if (makeBigLoad) {
        url += '?size=100';
      }

      requests
        .get({ url })
        .then((json) => {
          if (!json) {
            return false;
          }

          const ranges = {};
          console.log(json.sessionKey, currentSessionKey);
          if (json.sessionKey !== currentSessionKey) {
            return false;
          }
          if (json.status === 'UpdatesComplete') {
            hasSearshResults = true;
            const { flights } = parseFlightsData(json, ranges, search);
            console.log('UpdatesComplete/ranges', ranges);
            // console.log('UpdatesComplete/flights', flights);
            // console.log('UpdatesComplete/json', json);
            resolve({
              flights,
              sessionKey: json.sessionKey,
              status: json.status,
              ranges,
            });
          } else if (hasSearshResults) {
            pollSearchService(sessionKey, false, search)
              .then((result) => resolve(result))
              .catch((error) => {
                reject(error);
              });
          } else {
            const { flights } = parseFlightsData(json, ranges, search);
            if (flights.length > 0) {
              hasSearshResults = true;
              console.log('not UpdatesComplete', ranges);
              resolve({
                flights,
                sessionKey: json.sessionKey,
                status: json.status,
                ranges,
              });
            } else {
              pollSearchService(sessionKey, false, search)
                .then((result) => resolve(result))
                .catch((error) => {
                  reject(error);
                });
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    }, 1000);
  });

// Get final results of search after polling is completed
export const getSearchServiceResults = (sessionKey, search) =>
  new Promise((resolve, reject) => {
    const url = `${ENDPOINTS.flightSearch}/session/${sessionKey}?size=100`;
    let pagesLoaded = 0;
    let flights = [];
    const ranges = {};

    const addResults = (json) => {
      // one page has loaded
      const { flights: newFlights } = parseFlightsData(json, ranges, search);
      flights = flights.concat(newFlights);
      pagesLoaded += 1;

      // if both pages loaded
      if (pagesLoaded === 2) {
        resolve({
          flights,
          sessionKey: json.sessionKey,
          status: json.status,
          ranges,
        });
      }
    };

    const makeRequest = (page) => {
      requests
        .get({ url: `${url}&page=${page}` })
        .then((json) => {
          if (!json) {
            return;
          }
          addResults(json);
        })
        .catch((error) => {
          reject(error);
        });
    };

    // request 2 pages which are 200 results
    makeRequest(0);
    makeRequest(1);
  });

// @private - called in getSearchServicePromise
export const fetchSearchServicePromise = ({ body, search, url }) =>
  requests.post({ data: body, url }).then((json) => {
    if (!json) {
      return {};
    }
    currentSessionKey = json.sessionKey;

    let ranges = {};
    let { flights } = parseFlightsData(json, ranges, search);
    hasSearshResults = flights.length > 0;
    if (flights.length === 0) {
      flights = [
        { dummy: true },
        { dummy: true },
        { dummy: true },
        { dummy: true },
        { dummy: true },
        { dummy: true },
        { dummy: true },
      ];
      ranges = {
        carriers: [],
      };
    }
    return {
      flights,
      sessionKey: json.sessionKey,
      status: json.status,
      ranges,
    };
  });
// .catch(error => {
//   console.error(error);
//   throw new Error(error);
// });

/**
 * Call to request a new search
 * @param {Object} search - data shape:
 *    adults: '1',
 *    children: '0',
 *    dates: { startDate: moment('2000-01-01'), endDate: moment('2000-01-02') },
 *    destination: { shortDisplayName: 'Baltimore (BWI)', iata: 'BWI' },
 *    flightClass: '1',
 *    infants: '0',
 *    loyalty: [],
 *    origin: { shortDisplayName: 'Chicago (ORD)', iata: 'ORD' },
 *    passengers: { adults: 1, children: 0, infants: 0 },
 *    tripType: '1',
 * @param {boolean} log - set to false to turn off log (in tests)
 */
export const getSearchServicePromise = (search, log = true) => {
  currentSessionKey = null;
  hasSearshResults = false;
  const cabin = {
    1: 'economy',
    2: 'premiumEconomy',
    3: 'business',
    4: 'first',
  };

  const data = {
    orig: search.origin.iata,
    dest: search.destination.iata,
    cabin: cabin[search.flightClass],
    adults: Number(search.passengers.adults),
    infants: Number(search.passengers.infants),
    children: Number(search.passengers.children),
  };
  if (search.tripType === roundTrip) {
    data.departDate = dateToString(search.dates.startDate);
    data.returnDate = dateToString(search.dates.endDate);
  } else if (search.tripType === oneWay) {
    data.departDate = dateToString(search.dates.date);
  }
  if (log) {
    console.log('%c Making Request', 'background: #222; color: #bada55');
    console.log('search', search);
    console.log('data', data);
    // console.log('options', options);
    console.log('=============');
  }
  return fetchSearchServicePromise({
    body: data,
    search,
    url: ENDPOINTS.flightSearch,
  });
};
