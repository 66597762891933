import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import { MIN, MIN_ADULT, MAX, PASSENGER_TYPES } from './constants';

const PassengerTypeButtons = ({ info, name, type, updateQty, value }) => {
  const max = MAX;
  const min = type === 'adults' ? MIN_ADULT : MIN;
  return (
    <div className="passenger-type-container">
      <div className="passenger-type">{name}</div>
      {info.length > 0 && <div className="passenger-type-info">{info}</div>}
      <div className="passenger-type-buttons">
        <Button disabled={value <= min} onClick={updateQty(type, -1)}>
          -
        </Button>
        <span className="passenger-type-buttons-temp-value">{value}</span>
        <Button disabled={value >= max} onClick={updateQty(type)}>
          +
        </Button>
      </div>
    </div>
  );
};

PassengerTypeButtons.propTypes = {
  info: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(PASSENGER_TYPES).isRequired,
  updateQty: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};
PassengerTypeButtons.defaultProps = {
  info: '',
};

export default PassengerTypeButtons;
