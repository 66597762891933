import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { openLoyaltyProgramSelector } from 'modules/modals';
import {
  updateDisplayName as updateDisplayNameAction,
  updateHomeAirport as updateHomeAirportAction,
} from 'modules/user/actions';
import { isEmailProvider } from 'modules/user/utils';
import { userStatePropType } from 'utils/propTypes';
import { withPaper } from 'components/Shared/withPaper';

import ChangePassword from './ChangePassword';
import Dashboard from './Dashboard';
import RecentSearches from './RecentSearches';
import UserInfo from './UserInfo';

const AccountPage = ({
  openLPS,
  updateDisplayName,
  updateHomeAirport,
  userState,
}) => {
  return (
    <div className="account-page-container layout-max-width-centered layout-page-padding">
      {withPaper(UserInfo, { updateDisplayName, updateHomeAirport, userState })}
      <Dashboard {...{ openLPS, userState }} />
      {withPaper(RecentSearches, {}, 'padding-lr0')}
      {
        isEmailProvider(userState) && withPaper(ChangePassword)
        // withPaper(ChangePassword)
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  userState: state.user,
});

const mapDispatchToProps = {
  openLPS: openLoyaltyProgramSelector,
  updateDisplayName: updateDisplayNameAction,
  updateHomeAirport: updateHomeAirportAction,
};

AccountPage.propTypes = {
  openLPS: PropTypes.func.isRequired,
  updateDisplayName: PropTypes.func.isRequired,
  updateHomeAirport: PropTypes.func.isRequired,
  userState: userStatePropType,
};

AccountPage.defaultProps = {
  userState: {
    userEmail: undefined,
    userName: undefined,
    userPhotoURL: undefined,
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
