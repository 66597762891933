import React from 'react';
import Button from '@material-ui/core/Button';

/**
 * state comes from SearchForm if user has submitted
 * and contains the built url for getting flight results
 * @typedef {string} LocationState
 */

/**
 * @param {Object} location props.location
 * @returns {(LocationState|undefined)}
 */
export const getURLState = (location) => {
  return location.state;
};

export const rerouteWithState = ({ history, location }, route) => {
  history.push(route, getURLState(location));
};

export const rerouteToURLState = ({ history, location }) => {
  history.push(getURLState(location));
};

export const rerouteWithStateOrBackupRoute = ({ history, location }, route) => {
  const urlState = getURLState(location);

  history.push(urlState || route);
};

export const REROUTE_URL = '/account';
export const LOGIN = 'login';
export const SIGNUP = 'signup';

const otherMethodLookup = {
  [LOGIN]: 'Already have an account? Log in',
  [SIGNUP]: "Don't have an account? Sign up",
};

/* eslint-disable react/prop-types */
/**
 * @param {Object} props
 * @param {function} props.onClick
 * @param {string} props.otherName one of LOGIN|SIGNUP
 * @returns {ReactNode} MUI Button
 */
export const renderOtherMethod = ({ onClick, otherName }) => {
  return (
    <Button onClick={onClick} className="btn-dialog-plain">
      {otherMethodLookup[otherName]}
    </Button>
  );
};
/* eslint-enable react/prop-types */
