// https://keycode.info/
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
// event.key = key that is output to user
// event.which = number
export const KeyCodes = {
  TAB: 'Tab', // 9
  ENTER: 'Enter', // 13
  ESCAPE: 'Escape', // 27
  UP_ARROW: 'ArrowUp', // 38
  DOWN_ARROW: 'ArrowDown', // 40
};

// Use onKeyDown: apparently the keypress event is deprecated.
export const isKey = (event, keyName) => event.key === keyName;

export default KeyCodes;
