import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

/**
 * @typedef {string} SortDirection aka sortDirection - 'asc'|'desc'
 * @typedef {string} SortProp aka orderBy - the property on the data object to sort by
 * @typedef {Object} TableRowData
 * @typedef {Function} Comparator callback for .sort
 */

/**
 * @typedef {Object} HeadCell
 * @property {string} [HeadCell.className]
 * @property {boolean} [HeadCell.disablePadding]
 * @property {SortProp} HeadCell.id
 * @property {string} HeadCell.label
 * @property {boolean} [HeadCell.numeric]
 */

/** @type {SortDirection} */
const sortDirectionASC = 'asc';
/** @type {SortDirection} */
const sortDirectionDESC = 'desc';

const sortDirectionPropType = PropTypes.oneOf([
  sortDirectionASC,
  sortDirectionDESC,
]);

/**
 * @param {TableRowData} a
 * @param {TableRowData} b
 * @param {SortProp} orderBy
 * @returns {number} -1, 0, or 1
 */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * @param {SortDirection} sortDirection 'asc' or 'desc'
 * @param {SortProp} orderBy the property on the data object
 * @returns {Comparator} callback for .sort
 */
function getComparator(sortDirection, orderBy) {
  return sortDirection === sortDirectionDESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * @param {TableRowData[]} array
 * @param {Comparator} comparator
 * @returns {TableRowData[]} array copy sorted by comparator
 */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const sortDirection = comparator(a[0], b[0]);
    if (sortDirection !== 0) return sortDirection;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    headCells,
    orderBy,
    onRequestSort,
    sortDirection,
    tableHeadProps,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead {...tableHeadProps}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={headCell.className}
            align={headCell.numeric ? 'right' : 'left'}
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? sortDirection : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={
                orderBy === headCell.id ? sortDirection : sortDirectionASC
              }
              onClick={createSortHandler(headCell.id)}
            >
              <span className="head-cell-label">{headCell.label}</span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sortDirection === sortDirectionDESC
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.shape({ visuallyHidden: PropTypes.string }).isRequired,
  headCells: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onRequestSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  sortDirection: sortDirectionPropType.isRequired,
  tableHeadProps: PropTypes.shape({}),
};

EnhancedTableHead.defaultProps = { tableHeadProps: undefined };

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable({
  className,
  getTableRow,
  headCells,
  initialSortDirection,
  initialSortProperty,
  rows,
  tableContainerProps,
  tableHeadProps,
  tableFooter,
  tableProps,
}) {
  const classes = useStyles();

  // can be 'asc' or 'desc'
  const [sortDirection, setSortDirection] = React.useState(
    initialSortDirection
  );

  // sets which column orders the table
  const [orderBy, setOrderBy] = React.useState(initialSortProperty);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && sortDirection === sortDirectionASC;
    setSortDirection(isAsc ? sortDirectionDESC : sortDirectionASC);
    setOrderBy(property);
  };

  return (
    <div className={clsx(className)}>
      <TableContainer {...tableContainerProps}>
        <Table
          aria-label="enhanced sticky table"
          aria-labelledby="tableTitle"
          size="small"
          stickyHeader
          {...tableProps}
        >
          <EnhancedTableHead
            classes={classes}
            headCells={headCells}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            sortDirection={sortDirection}
            tableHeadProps={tableHeadProps}
          />
          <TableBody>
            {stableSort(rows, getComparator(sortDirection, orderBy)).map(
              getTableRow
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {tableFooter}
    </div>
  );
}

EnhancedTable.propTypes = {
  className: PropTypes.string,
  getTableRow: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialSortDirection: sortDirectionPropType,
  initialSortProperty: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tableContainerProps: PropTypes.shape({}),
  tableFooter: PropTypes.node.isRequired,
  tableHeadProps: PropTypes.shape({}),
  tableProps: PropTypes.shape({}),
};

EnhancedTable.defaultProps = {
  className: undefined,
  initialSortDirection: sortDirectionASC,
  tableContainerProps: undefined,
  tableHeadProps: undefined,
  tableProps: undefined,
};
