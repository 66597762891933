import React, { Component } from 'react';
import PropTypes from 'prop-types';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Slider from 'rc-slider';

const RangeComp = Slider.Range;

class Range extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    format: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    defaultValue: PropTypes.arrayOf(PropTypes.number),
  };

  static defaultProps = {
    format: value => value,
    onChange: () => {},
    min: 0,
    max: 100,
    defaultValue: [0, 100],
  };

  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
      defaultValue: [0, 100],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {};
    let changeState = false;
    if (nextProps.min !== prevState.min) {
      state.min = nextProps.min;
      if (prevState.defaultValue) {
        changeState = true;
      }
    }
    if (nextProps.max !== prevState.max) {
      state.max = nextProps.max;
      if (prevState.defaultValue) {
        changeState = true;
      }
    }
    if (nextProps.defaultValue !== prevState.defaultValue || changeState) {
      const { defaultValue } = nextProps;
      const realValue = defaultValue.concat([]);

      realValue[0] =
        realValue[0] === -1 ? nextProps.min || prevState.min : realValue[0];
      realValue[1] =
        realValue[1] === -1 ? nextProps.max || prevState.max : realValue[1];

      state.defaultValue = defaultValue;
      state.value = realValue;
      state.tmpValue = realValue;
    }
    // console.log(state, nextProps, prevState)
    return state;
  }

  onChange = _value => {
    const { onChange, min, max } = this.props;
    const { value } = this.state;
    if (value[0] !== _value[0] || value[1] !== _value[1]) {
      this.setState({ dragging: false });
      this.setState({ value: _value, tmpValue: _value });
      const valueParse = [value[0], value[1]];
      valueParse[0] = value[0] === min ? -1 : value[0];
      valueParse[1] = value[1] === max ? -1 : value[1];
      onChange(_value);
    }
  };

  onBefore = () => {
    this.setState({ dragging: true });
  };

  onUpdate = data => {
    this.setState({ tmpValue: data });
  };

  render() {
    const { min, max, format } = this.props;
    const { tmpValue } = this.state;
    return (
      <div className="range-slider">
        <span>{`${format(tmpValue[0], 0)} - ${format(tmpValue[1], 1)}`}</span>
        <RangeComp
          min={min}
          max={max}
          allowCross={false}
          value={tmpValue}
          onBeforeChange={this.onBefore}
          tipFormatter={format}
          onChange={this.onUpdate}
          onAfterChange={this.onChange}
        />
      </div>
    );
  }
}
/*

*/
export default Range;
