import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ROOT } from 'components/App/routeConstants';

const alt = 'Treklee Logo';

const LogoLink = (props) => {
  const { className, textColor } = props;

  if (textColor === 'black') {
    return (
      <Link className={className || 'logo-link logo-link-black'} to={ROOT}>
        <img src="/img/logo-blue-white-mobile.svg" alt={alt} />
      </Link>
    );
  }

  return (
    <Link className={className || 'logo-link logo-link-white'} to={ROOT}>
      <img src="/img/logo-blue-white.svg" alt={alt} />
    </Link>
  );
};

LogoLink.propTypes = {
  className: PropTypes.string,
  textColor: PropTypes.oneOf(['black', 'white']),
};

LogoLink.defaultProps = {
  className: undefined,
  textColor: 'black',
};

export default LogoLink;
