import { createLookupTable, sortWithOptions } from 'utils/dataUtils';

/**
 * return a sorted copy of input data by popularity
 * @param {Object[]} data
 */
export const sortLoyaltyPrograms = (data) =>
  sortWithOptions(data, { reverse: true, sortBy: (x) => x.popularity });

/**
 * build a lookup table for loyalty programs
 * @param {Object[]} data
 * @returns {Object} lookup table: { key: id, value: loyaltyData }
 */
export const createLoyaltyDict = (data) => createLookupTable(data, (x) => x.id);
