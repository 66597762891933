import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  loadingStart,
  loadingContinue,
  loadingEnd,
} from 'modules/search-loading-bar';
import { addEvent, removeEvent, Browser } from 'utils/browser';
import { tripTypeData } from 'utils/forms/formData';
import Icons from 'utils/Icons';
import { createUrlParams } from 'utils/urlParams';
import {
  getSearchServicePromise,
  pollSearchService,
  getSearchServiceResults,
} from 'services/fetchFlightData';

import { SearchForm } from '../Search';
import Filters, { defaultValue as defaultFilters } from '../Filters';
import Results from '../Results';

import { getDerivedState } from './getDerivedState';

const { oneWay, roundTrip } = tripTypeData.values;

/**
 * <Page /> puts everything together:
 * - <SearchForm />
 * - <Filters />
 * - <Results />
 * - Side Ads
 *
 * Also, see data.bookingDetailsLink - this is a check to see that data is available
 *  if not, it's dummy data (?)
 */
class Page extends Component {
  static propTypes = {
    onLoadingStart: PropTypes.func.isRequired,
    onLoadingContinue: PropTypes.func.isRequired,
    onLoadingEnd: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  static defaultProps = {
    history: {
      push: () => {},
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      filters: defaultFilters,
      hasResults: false,
      loading: false,
      loaded: false,
      fixed: false,
      filterRanges: {
        price: [100, 300],
        pricePoints: [5000, 10000],
        duration: [0, 24],
        airlines: [],
        'outbound-time': [0, 24],
        'return-time': [0, 24],
      },
      search: {},
      mobileFilters: false,
      hideSummary: false,
      // ...and see getDerivedStateFromProps
    };
    this.filtersRef = React.createRef();
    this.spaceBarRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location !== prevState.location) {
      return getDerivedState(nextProps);
    }
    return null;
  }

  componentDidMount() {
    const { search } = this.state;
    // console.log('component did mount / searching init');
    this.makeSearchRequest(search);

    addEvent(window, 'resize', this.resize);
    addEvent(window, 'scroll', this.scroll);
  }

  componentWillUnmount() {
    removeEvent(window, 'resize', this.resize);
    removeEvent(window, 'scroll', this.scroll);
  }

  scroll = () => {
    // 64, 56
    const rect = this.spaceBarRef.current.getBoundingClientRect();
    if (rect.top <= 0 && Browser.getSize().width <= 900) {
      this.setState({ fixed: true });
    } else {
      this.setState({ fixed: false });
    }
  };

  resize = () => {
    this.setState({ fixed: false });
  };

  onFilter = (filters) => {
    console.log('filters', filters);
    this.setState({ filters });
    // this.updateSearchUrl(search, filters);
  };

  onSearch = (search) => {
    if (this.isSearchValid(search)) {
      this.setState({ search, hasResults: false });
      this.updateSearchUrl(search, defaultFilters);
      // this.makeSearchRequest(search);
    }
  };

  isSearchValid = (search) => {
    const { origin, destination, tripType, dates } = search;
    let invalidDate = false;
    let invalidLocation = false;
    let invalidSameLocation = false;

    if (tripType === roundTrip) {
      invalidDate = !(dates && dates.startDate && dates.endDate);
      invalidLocation = !(origin && destination);
    } else if (tripType === oneWay) {
      invalidDate = !(dates && dates.date);
      invalidLocation = !origin;
    }

    if (origin && destination && origin.iata === destination.iata) {
      invalidSameLocation = true;
    }

    if (invalidDate || invalidLocation || invalidSameLocation) {
      return false;
    }
    return true;
  };

  /**
   * Creates url params and pushes them to url location
   * This causes the component to refresh with the new data
   */
  updateSearchUrl = (search, filters) => {
    const { history } = this.props;
    const url = createUrlParams(search, filters);
    history.push(url);
    return true;
  };

  filterRanges = (ranges) => {
    const filterRanges = {};
    filterRanges.duration = [
      Math.floor(ranges.duration.min),
      Math.ceil(ranges.duration.max),
    ];
    filterRanges.price = [
      Math.floor(ranges.price.min),
      Math.ceil(ranges.price.max),
    ];
    filterRanges.pricePoints = [6000, 13000];
    filterRanges['outbound-time'] = [0, 24];
    filterRanges['return-time'] = [0, 24];
    filterRanges.airlines = [];

    Object.keys(ranges.carriers).forEach((carrierId) => {
      filterRanges.airlines.push({
        id: Number(carrierId),
        name: ranges.carriers[carrierId].Name,
        only: ranges.carriers[carrierId].only,
      });
    });

    filterRanges.airlines.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    ranges.programs.sort((a, b) => {
      const nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
      const nameB = b.displayName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    filterRanges.programs = ranges.programs;
    return filterRanges;
  };

  makePollRequest = (sessionKey, makeBigLoad = false) => {
    const { search } = this.state;
    const { onLoadingContinue } = this.props;

    console.log('pull', sessionKey);

    pollSearchService(sessionKey, makeBigLoad, search).then((data) => {
      const { ranges } = data;

      console.log('DATA from PULL', data);
      this.setState({
        results: data.flights,
        filterRanges: this.filterRanges(ranges),
        error: false,
      });
      if (data.status === 'UpdatesPending') {
        this.makePollRequest(sessionKey);
        onLoadingContinue();
      } else {
        this.getCompletedSearchServiceResults(sessionKey);
      }
    });
  };

  getCompletedSearchServiceResults = (sessionKey) => {
    const { search } = this.state;
    const { onLoadingEnd } = this.props;

    getSearchServiceResults(sessionKey, search).then((data) => {
      const { ranges } = data;
      console.log('ranges', ranges); // displayName
      this.setState({
        results: data.flights,
        loaded: true,
        filterRanges: this.filterRanges(ranges),
        error: false,
      });
      onLoadingEnd();
    });
  };

  onResetFilters = () => {
    this.filtersRef.current.onResetFilters();
  };

  showMobileFilters = (event) => {
    if (event.nativeEvent.type === 'keydown') {
      if (event.nativeEvent.keyCode !== 13) {
        return false;
      }
    }
    this.setState({ mobileFilters: true });
    return true;
  };

  hideMobileFilters = (event) => {
    if (event.nativeEvent.type === 'keydown') {
      if (event.nativeEvent.keyCode !== 13) {
        return false;
      }
    }
    this.setState({ mobileFilters: false });
    return true;
  };

  onHideSummary = (hideSummary) => {
    this.setState({ hideSummary });
    console.log('hideSummary', hideSummary);
  };

  makeSearchRequest(search, filters) {
    if (!this.isSearchValid(search)) {
      return false;
    }

    const { onLoadingContinue, onLoadingEnd, onLoadingStart } = this.props;

    console.log('this.makeSearchRequest()');

    this.setState({ loading: true, results: [], loaded: false });

    onLoadingStart();

    getSearchServicePromise(search, filters)
      .then((data) => {
        const { flights, ranges, sessionKey } = data;
        console.log({ data });
        if (!flights) {
          this.setState({
            results: flights,
            loading: false,
            hasResults: false,
            error: false,
          });
          onLoadingEnd();
          return;
        }
        this.setState({
          results: flights,
          loading: false,
          hasResults: true,
          error: false,
        });
        // const { ranges } = data;

        if (flights.length > 0 && !flights[0].dummy) {
          this.setState({ filterRanges: this.filterRanges(ranges) });
        }
        this.makePollRequest(sessionKey);
        onLoadingContinue();
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          results: [],
          loading: false,
          loaded: true,
          hasResults: true,
          error: true,
        });
        onLoadingEnd();
      });

    return true;
  }

  render() {
    const {
      error,
      filterRanges,
      filters,
      fixed,
      hasResults,
      hideSummary,
      loaded,
      loading,
      mobileFilters,
      results,
      search,
    } = this.state;
    let summaryShowed = false;
    const { origin, destination, passengers } = search;
    if (
      (loaded || loading || (results || []).length > 0) &&
      origin &&
      destination &&
      passengers &&
      !hideSummary
    ) {
      summaryShowed = true;
    }
    return (
      <React.Fragment>
        <div
          className={`search-space space${fixed ? ' fixed' : ''}`}
          ref={this.spaceBarRef}
        />
        <div
          className={`homepage-hero search-page${
            summaryShowed ? ' summaryShowed' : ''
          }${fixed ? ' fixed' : ''}`}
        >
          <div>
            <div className="content">
              <SearchForm
                onSearch={this.onSearch}
                search={search}
                loaded={loaded}
                loading={loading}
                results={results}
                onHideSummary={this.onHideSummary}
              />
            </div>
          </div>
        </div>

        <div
          className="search-results-loader"
          style={{ display: loading && !hasResults ? '' : 'none' }}
        >
          {Icons.Loader}
        </div>
        <div
          className={`content-container-main${
            mobileFilters ? ' mobile-filters' : ''
          }`}
          style={{ display: hasResults ? '' : 'none' }}
        >
          <Filters
            onChange={this.onFilter}
            filterRanges={filterRanges}
            loaded={loaded}
            search={search}
            error={error}
            ref={this.filtersRef}
            results={results}
          />
          <Results
            results={results}
            loading={loading}
            loaded={loaded}
            filters={filters}
            search={search}
            error={error}
            onResetFilters={this.onResetFilters}
          />

          <div
            className="ads"
            style={{
              display:
                !loading && (results && results.length) > 0 && !error
                  ? ''
                  : 'none',
            }}
          >
            <div className="ad-1">Ad 1</div>
            <div className="ad-2">Ad 2</div>
          </div>
        </div>
        <b
          className="filters-mobile-button"
          role="button"
          onKeyDown={this.showMobileFilters}
          onClick={this.showMobileFilters}
          style={{ display: !mobileFilters ? '' : 'none' }}
        >
          Filters
        </b>
        <b
          className="filters-mobile-button"
          role="button"
          onKeyDown={this.hideMobileFilters}
          onClick={this.hideMobileFilters}
          style={{ display: mobileFilters ? '' : 'none', zIndex: 10001 }}
        >
          Apply
        </b>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  onLoadingStart: loadingStart,
  onLoadingContinue: loadingContinue,
  onLoadingEnd: loadingEnd,
};

export default connect(null, mapDispatchToProps)(withRouter(Page));
