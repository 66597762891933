import queryString from 'query-string';

import { tripTypeData } from 'utils/forms/formData';
import { dateToString } from 'utils/date';
import { is, isType } from 'utils/typeUtils';

const { oneWay, roundTrip } = tripTypeData.values;
const BASE = '/search?';

/**
 * @throws if tripType is invalid
 */
const getFormattedDate = (data) => {
  const { dates, tripType } = data;
  const { date, endDate, startDate } = dates;

  let dateCollection;
  if (tripType === roundTrip) {
    dateCollection = [startDate, endDate];
  } else if (tripType === oneWay) {
    dateCollection = [date];
  }

  return dateCollection.map((d) => dateToString(d)).join('_');
};

/**
 * @param {object} data - user input data
 * @param {object} filters
 * @returns {string} example: /search?adults=1&children=0
 *   &date=2019-12-24_2019-12-31&destinationName=Asheville%20%28AVL%29
 *   &flightClass=1&iata=TSE_AVL&infants=0&loyalty=&originName=Astana%20%28TSE%29
 */
export const createUrlParams = (data, filters) => {
  const {
    destination,
    flightClass,
    loyalty,
    origin,
    passengers: { adults, children, infants },
  } = data;

  const queryObj = {
    adults,
    children,
    date: getFormattedDate(data),
    infants,
    flightClass,
    iata: [origin.iata, destination.iata].join('_'),
    loyalty,
    originName: origin.shortDisplayName,
    destinationName: destination.shortDisplayName,
  };

  if (isType(filters, 'object')) {
    Object.entries(filters).forEach(([key, value]) => {
      queryObj[key] = value;
    });
    queryObj.time = Date.now();
  }

  return `${BASE}${queryString.stringify(queryObj, { arrayFormat: 'comma' })}`;
};

export const decodeUrlParams = (urlString) => {
  if (!is.nonEmptyString(urlString)) {
    throw new Error('Expected string.');
  }
  if (urlString[0] !== '?') {
    throw new Error('Expected query string to begin with `?`.');
  }
  return queryString.parse(urlString, {
    arrayFormat: 'comma',
    parseBooleans: true,
    parseNumbers: true,
  });
};
