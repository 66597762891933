import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const CreateAccount = ({ onAccept, onCancel }) => (
  <Dialog
    className="create-account-dialog"
    open
    aria-labelledby="create-account-dialog-title"
    maxWidth="xs"
  >
    <DialogTitle id="create-account-dialog-title">
      Create an account
    </DialogTitle>
    <DialogContent className="create-account-dialog-content" dividers>
      <p>
        Save your loyalty programs for your next visit by creating a free
        account.
      </p>
      <div className="create-account-dialog-btn-primary">
        <Button
          className="create-account-dialog-signup-btn"
          color="primary"
          fullWidth
          onClick={() => onAccept('/signup')}
          variant="contained"
        >
          Sign Up
        </Button>
      </div>
      <div className="create-account-dialog-btn-secondary">
        <Button
          className="create-account-dialog-login-btn"
          fullWidth
          onClick={() => onAccept('/login')}
        >
          Already have an account? Log in
        </Button>
      </div>
      <div className="create-account-dialog-btn-secondary">
        <Button
          className="create-account-dialog-cancel-btn"
          fullWidth
          onClick={onCancel}
        >
          No thanks, show my flight results.
        </Button>
      </div>
    </DialogContent>
  </Dialog>
);

CreateAccount.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

// CreateAccount.defaultProps = {};

export default CreateAccount;
