import { tripTypeData } from 'utils/forms/formData';

const { oneWay, roundTrip } = tripTypeData.values;

export const getDerivedState = nextProps => {
  const { search } = nextProps;
  const { dates, destination, loyalty, origin } = search;

  const data = {
    _search: search,
    flightClass: search.flightClass,
    loyalty: loyalty ? new Set(loyalty) : new Set(),
    passengers: search.passengers,
    tripType: search.tripType,
  };

  if (origin && origin.shortDisplayName) {
    data.origin = {
      shortDisplayName: origin.shortDisplayName,
      iata: origin.iata,
    };
  }
  if (destination && destination.shortDisplayName) {
    data.destination = {
      shortDisplayName: destination.shortDisplayName,
      iata: destination.iata,
    };
  }
  if (dates) {
    const { tripType } = search;
    if (tripType === roundTrip) {
      data.endDate = dates.endDate;
      data.startDate = dates.startDate;
      // data.date = null;
    } else if (tripType === oneWay) {
      // data.endDate = null;
      // data.startDate = null;
      data.date = dates.date;
    }
  }

  return data;
};
