import { convertToMoment } from 'utils/date';

const keys = ['startDate', 'endDate', 'date'];

/**
 * Gets the date-holding props and ensures they're moment objects
 * @param {Object} props all props
 * @returns {Object} just the date-holding props, converted
 */
export const ensureMoment = props => {
  return keys.reduce((acc, key) => {
    const value = props[key];
    if (value) {
      acc[key] = convertToMoment(value);
    }
    return acc;
  }, {});
};
