import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import { ACCOUNT, LOYALTY, RECS, TRIPS } from 'components/App/routeConstants';

export const routesArray = [
  {
    name: 'Account',
    Icon: AccountCircleIcon,
    url: ACCOUNT,
  },
  {
    name: 'Loyalty Programs',
    Icon: LoyaltyIcon,
    shortName: 'Programs',
    url: LOYALTY,
  },
  {
    name: 'Trips',
    Icon: WorkOutlineIcon,
    url: TRIPS,
  },
  {
    name: 'Credit Cards',
    Icon: CreditCardIcon,
    shortName: 'Cards',
    url: RECS,
  },
].map((item, idx) => {
  // eslint-disable-next-line no-param-reassign
  item.index = idx;
  return item;
});
