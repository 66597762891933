/**
 * @typedef destination {}
 *
 * name: {string},
 * image: {string},
 * class: 'Business',
 * id: 1,
 * price: 1250,
 * points: 65000,
 * loyalty: 'AMEX',
 */

const sanFran = {
  name: 'San Francisco',
  image: '/img/home/destinations/san-francisco.jpg',
};

const barcelona = {
  name: 'Barcelona',
  image: '/img/home/destinations/barcelona.jpg',
};

const rome = {
  name: 'Rome',
  image: '/img/home/destinations/rome.jpg',
};

const nassau = {
  name: 'Nassau',
  image: '/img/home/destinations/nassau.jpg',
};

const lasVegas = {
  name: 'Las Vegas',
  image: '/img/home/destinations/las-vegas.jpg',
};

export const mockDestinations = [
  sanFran,
  barcelona,
  rome,
  nassau,
  lasVegas,
  sanFran,
  barcelona,
  rome,
  nassau,
  lasVegas,
  sanFran,
  barcelona,
  rome,
].map((item, id) => ({
  ...item,
  id,
  flightClass: item.class || 'Business',
  loyalty: 'AMEX',
  points: 6500 + id,
  price: 1250 + id,
}));
