import { namespacer } from 'utils/stringUtils';

const ns = namespacer('loyalty');

// Actions
export const FETCH = ns('FETCH'); // done by app load
export const FETCH_SUCCESS = ns('FETCH_SUCCESS');
export const FETCH_FAIL = ns('FETCH_FAIL');

// Data types

// response - { type: enum }
export const LOYALTY_TYPES = {
  airline: 'airline',
  creditCard: 'creditCard',
  hotel: 'hotel',
};
