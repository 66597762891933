import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getTotalPoints, mockProgramsStatuses } from 'mock/mockAccountData';

import InputWithEditIcon from 'components/Shared/InputWithEditIcon';
import TableSort from 'components/Shared/TableStickySort';
import SelectMenu from 'components/Shared/SelectMenu';
import { LOYALTY_TYPES } from 'modules/loyalty/constants';
import icons from 'utils/Icons';
import { isType } from 'utils/typeUtils';

import './loyalty_points_form.scss';

const typeToIconMap = {
  [LOYALTY_TYPES.airline]: icons.Airplane,
  [LOYALTY_TYPES.creditCard]: icons.CreditCard,
  [LOYALTY_TYPES.hotel]: icons.Hotel,
};

const getIcon = (type) => {
  const Icon = typeToIconMap[type];
  if (!Icon) return ''; // report error
  return <span className={`loyalty-table-icon-${type}`}>{Icon}</span>;
};

const LoyaltyPointsTable = ({ data, onChange, totalPoints }) => (
  <TableSort
    {...{
      className: 'loyalty-points-input-wrapper',
      getTableRow: (row) => {
        return (
          <TableRow className="loyalty-program-row" key={row.id}>
            <TableCell component="th" scope="row">
              <span className="display-name">{row.displayName}</span>
            </TableCell>
            <TableCell className="hide-tablet">{getIcon(row.type)}</TableCell>
            <TableCell className="loyalty-status-select-cell hide-mobile">
              <SelectMenu
                className="loyalty-status-select"
                muiVariation="Select"
                onChange={onChange({ id: row.id, type: 'status' })}
                options={mockProgramsStatuses}
                value={row.status}
              />
            </TableCell>
            <TableCell style={{ width: 140 }}>
              <InputWithEditIcon
                disableUnderline
                onSave={onChange({ id: row.id, type: 'points' })}
                type="number"
                value={row.points}
                validationMessage="Please enter a valid number."
                validate={(val) => {
                  if (val === '' || isType(Number(val), 'nan')) {
                    return false;
                  }
                  return true;
                }}
              />
            </TableCell>
          </TableRow>
        );
      },
      headCells: [
        {
          id: 'displayName',
          label: 'Program',
        },
        {
          className: 'loyalty-points-table-head-type hide-tablet',
          id: 'type',
          label: 'Type',
        },
        {
          className: 'loyalty-points-table-head-status hide-mobile',
          id: 'status',
          label: 'Status',
        },
        {
          className: 'loyalty-points-table-head-points',
          id: 'points',
          label: 'Balance',
        },
      ],
      initialSortProperty: 'displayName',
      rows: data,
      tableFooter: (
        <Table className="loyalty-points-table-footer">
          <TableHead>
            <TableRow>
              <TableCell className="head-cell-label">Total</TableCell>
              <TableCell className="head-cell-label hide-tablet" />
              <TableCell className="head-cell-label loyalty-points-table-head-status hide-mobile" />
              <TableCell className="head-cell-label loyalty-points-table-footer-head-points">
                <span>{totalPoints}</span>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      ),
      tableProps: {
        'aria-label': 'loyalty points table',
        className: 'loyalty-points-table',
        size: 'small',
      },
      tableContainerProps: {
        className: 'loyalty-points-table-container',
      },
      tableHeadProps: {
        className: 'loyalty-points-table-head',
      },
    }}
  />
);

LoyaltyPointsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  totalPoints: PropTypes.string.isRequired,
};

LoyaltyPointsTable.displayName = 'LoyaltyPointsTable';

const LoyaltyPointsForm = ({
  loyaltyDict,
  updateLoyaltyProgramBalance,
  updateLoyaltyProgramStatus,
  userPrograms,
}) => {
  let loading = false;

  const onChange = ({ id, type }) => (evt) => {
    if (type === 'points') {
      updateLoyaltyProgramBalance(id, Number.parseFloat(evt));
    }
    if (type === 'status') {
      updateLoyaltyProgramStatus(id, evt.target.value);
    }
  };

  // todo, make more efficient
  const tableData = userPrograms.map((program) => {
    const { id, points, status } = program;
    const loyaltyProgram = loyaltyDict[id];

    // todo: fix me
    if (!loyaltyProgram) {
      loading = true;
      return {};
    }

    // If we store _all_ the data in userPrograms, we won't need to do this!
    const { displayName, programName, type } = loyaltyProgram;

    const data = {
      displayName: displayName || programName || id,
      id,
      // name: programName || displayName || id,
      points,
      status,
      type,
    };

    return data;
  });

  return loading ? (
    <div className="text-center">Loading...</div>
  ) : (
    <LoyaltyPointsTable
      data={tableData}
      onChange={onChange}
      totalPoints={getTotalPoints(userPrograms)}
    />
  );
};

LoyaltyPointsForm.propTypes = {
  loyaltyDict: PropTypes.shape({}).isRequired,
  updateLoyaltyProgramBalance: PropTypes.func.isRequired,
  updateLoyaltyProgramStatus: PropTypes.func.isRequired,
  userPrograms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default LoyaltyPointsForm;
