import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import Fab from '@material-ui/core/Fab';
// import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogActions from '@material-ui/core/DialogActions';
// import Typography from '@material-ui/core/Typography';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { windowStatePropType } from 'utils/propTypes';
import { saveButtonDialog } from 'utils/styles';
import AwardWalletBtn, {
  AwardWalletSubtext,
} from 'components/Shared/AwardWalletBtn';
import DialogTitleWithCloseButton from 'components/Shared/DialogTitleWithCloseButton';
import SearchInput from 'components/Shared/SearchInput';

import LoyaltyChip from '../LoyaltyChip';
// import { filterOptions } from '../loyaltyData';
import { getFilteredProgramData, matchesSearchFilter } from '../utils';

import LoyaltyCard from './LoyaltyCard';
import SelectedChips from './SelectedChips';
import SelectedPopper from './SelectedPopper';

const scrollDialogStyles = {
  saveButton: saveButtonDialog,
};

const getSelectedChips = ({ onSelect, selected, programsDict }) => {
  return Array.from(selected).map((id) => {
    // todo: fix me
    if (!programsDict[id]) return <span />;

    const { displayName, programName } = programsDict[id];
    return (
      <LoyaltyChip
        key={id}
        label={programName || displayName}
        onDelete={onSelect(id)}
      />
    );
  });
};

/**
 * This is to render certain text evenly on two lines
 * @typedef {object} TwoLineText
 * { programName: ReactNode }
 */

/** Renders {TwoLineText} value */
const twoLineText = (text1, text2) => (
  <span>
    {text1}
    <br />
    {text2}
  </span>
);

/** @type {TwoLineText} */
const twoLineTextMap = {
  'British Airways': twoLineText('British Airways', 'Executive Club'),
  'Air France/KLM': twoLineText('Air France/KLM', 'Flying Blue'),
  'Virgin Atlantic': twoLineText('Virgin Atlantic', 'Flying Club'),
};

/* eslint-disable react/prop-types */
const getLoyaltyCard = ({ onSelect, program, selected }) => {
  const { id, displayName, logoUrl, programName, type } = program;

  return (
    <LoyaltyCard
      contentClassname="loyalty-card-content"
      key={id}
      selected={selected.has(id)}
      onClick={onSelect(id)}
    >
      <div className="loyalty-card-image-container">
        <img
          alt={`Loyalty program ${type} ${id} ${displayName || programName}`}
          src={`${logoUrl}`}
        />
      </div>
      <div className="loyalty-card-label">
        {twoLineTextMap[programName] || displayName || programName}
      </div>
    </LoyaltyCard>
  );
};
/* eslint-enable react/prop-types */

/**
 * If no programs found and status is done, display that to user
 * Otherwise, run list through filters and display program results
 * @param {Object} state { programs: [], search: '', selected: Set }
 * @param {Object} handlers { onClearSearchInput: func, onSelect: func }
 */
const getLoyaltyProgramsList = (state, handlers) => {
  const { selected } = state;
  const { onClearSearchInput, onSelect } = handlers;

  const loyaltyData = getFilteredProgramData({
    searchFilter: matchesSearchFilter,
    state,
    transform: (program) => getLoyaltyCard({ onSelect, program, selected }),
  });

  const { status } = state;

  return (
    <div className="loyalty-programs-list-container">
      {
        /* Handle No Programs Found */
        status === 'done' && loyaltyData.length === 0 ? (
          <div className="no-programs-found">
            <div className="alert-icon-container">
              <ReportProblemOutlinedIcon />
            </div>
            <div>No programs found with that name.</div>
            <div className="btn-container">
              <Button
                onClick={onClearSearchInput}
                variant="contained"
                color="primary"
              >
                Clear Search
              </Button>
            </div>
          </div>
        ) : null
      }
      <div className="loyalty-programs-list-results">{loyaltyData}</div>
      <div className="award-wallet-btn-container-mobile">
        <AwardWalletBtn />
        <AwardWalletSubtext />
      </div>
    </div>
  );
};

function LoyaltySelectionView(props) {
  const {
    classes,
    closeLPS,
    modalOpenRedux: open,
    // onCheckPopular,
    onClearSearchInput,
    onClose,
    // onFilterChange,
    openLPS,
    onSearchInput,
    onSelect,
    programs,
    programsDict,
    saveButtonText,
    showValidation,
    state,
    status,
    title,
    trigger,
    windowState,
  } = props;

  const { search, selected } = state;

  const selectedChipsRendered =
    status === 'loading'
      ? []
      : getSelectedChips({
          onSelect,
          selected,
          programsDict,
        });

  const handleOpen = () => {
    openLPS();
  };

  const handleClose = () => {
    onClose();
    closeLPS();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Fragment>
      {trigger({ onOpen: handleOpen })}
      <Dialog
        aria-describedby="scroll-dialog-description"
        aria-labelledby="loyalty-selection-dialog-title"
        className="loyalty-selection-poc"
        fullScreen={windowState.width < 1000}
        fullWidth
        maxWidth="lg" // md, lg, and more
        onClose={handleClose}
        open={open}
        scroll="paper"
      >
        <DialogTitleWithCloseButton
          className="loyalty-selection-dialog-title"
          id="loyalty-selection-dialog-title"
          onClose={handleClose}
          title={title}
          // subtitle="Choose at least one loyalty program to view your flight results."
        />
        <div className="flex-content">
          <div className="search-and-programs">
            <div className="search-wrapper">
              <SearchInput
                className="search-input"
                placeholder="Search programs"
                onChange={onSearchInput}
                onClear={onClearSearchInput}
                value={search}
              />
            </div>
            <div className="loyalty-programs-status-container">
              {status === 'done' &&
                getLoyaltyProgramsList(
                  // "state" (plus redux state)
                  { ...state, programs, selected },
                  // handlers
                  {
                    onClearSearchInput,
                    onSelect,
                  }
                )}
              {status === 'error' && (
                <div style={{ color: '#be0000' }}>
                  There was an error getting loyalty programs.
                </div>
              )}
              {status === 'loading' && <div>Loading...</div>}
            </div>
          </div>
          <div className="aside-section">
            <div className="award-wallet-section">
              <div className="award-wallet-btn-containers">
                <AwardWalletBtn />
                <SelectedPopper
                  dialogId="selected-chips-dialog-mobile"
                  selected={selected}
                  trigger={(onClick) => {
                    return (
                      <Fab
                        className="selected-chips-btn-mobile"
                        onClick={onClick}
                      >
                        {selected.size}
                      </Fab>
                    );
                  }}
                >
                  <SelectedChips
                    chipsNode={selectedChipsRendered}
                    isMobile
                    showValidation={showValidation}
                  />
                </SelectedPopper>
              </div>
              <AwardWalletSubtext />
            </div>
            <SelectedChips
              chipsNode={selectedChipsRendered}
              showValidation={showValidation}
            />
          </div>
        </div>
        <DialogActions className="save-btn-container">
          <SelectedPopper
            dialogId="selected-chips-dialog-mobile"
            selected={selected}
            trigger={(onClick) => {
              return (
                <Button
                  className={classes.saveButton}
                  // this is a validation if no items are selected
                  onClick={
                    selected.size > 0 || !showValidation ? handleClose : onClick
                  }
                  variant="contained"
                >
                  {saveButtonText}
                </Button>
              );
            }}
          >
            <SelectedChips
              chipsNode={selectedChipsRendered}
              isMobile
              showValidation={showValidation}
            />
          </SelectedPopper>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

LoyaltySelectionView.propTypes = {
  classes: PropTypes.shape({
    saveButton: PropTypes.string,
  }).isRequired,
  closeLPS: PropTypes.func.isRequired,
  modalOpenRedux: PropTypes.bool.isRequired,
  onClearSearchInput: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSearchInput: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  openLPS: PropTypes.func.isRequired,
  programs: PropTypes.arrayOf(PropTypes.object).isRequired,
  programsDict: PropTypes.shape({}),
  saveButtonText: PropTypes.string,
  showValidation: PropTypes.bool,
  state: PropTypes.shape({
    search: PropTypes.string,
    selected: PropTypes.instanceOf(Set),
  }).isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string,
  /* React Node, button to open Drawer */
  trigger: PropTypes.func.isRequired,
  windowState: windowStatePropType.isRequired,
};

LoyaltySelectionView.defaultProps = {
  programsDict: {},
  saveButtonText: 'Continue',
  showValidation: true,
  title: 'Select your loyalty programs',
};

export default withStyles(scrollDialogStyles)(LoyaltySelectionView);
