import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import { Formik, Field, Form } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import { LinearProgress } from '@material-ui/core';

import {
  getFirebaseEmailAuthCredential,
  getFirebaseUser,
} from 'services/firebase';
import ErrorDialog from 'components/Dialogs/ErrorDialog';

import {
  assignError,
  getPassError as getCurPassError,
  getNewPass1Error,
  getNewPass2Error,
  handleEnterKey,
} from 'utils/forms/Formik';

const initialValues = { curPass: '', newPass1: '', newPass2: '' };

const INITIAL_STATE = {
  displaySuccess: false,
  errorDialogContent: [null, null],
  errorDialogOpen: false,
};

class ChangePassword extends Component {
  static propTypes = {
    userState: PropTypes.shape({ userEmail: PropTypes.string }),
  };

  static defaultProps = {
    userState: {
      userEmail: undefined,
    },
  };

  state = {
    ...INITIAL_STATE,
  };

  async componentDidMount() {
    this.user = await getFirebaseUser();
  }

  handleChangePassword = (password1, password2) =>
    this.validateCurrentPassword(password1)
      .then(() => this.updatePassword(password2))
      .catch((err) => this.handleError(err));

  handleError = (err) => {
    const { message } = err;
    // console.error(err);
    let displayError = 'Unable to update password.';
    if (message === 'invalid_credential') {
      displayError = 'Unable to verify user or current password.';
    }
    if (message === 'weak_password') {
      displayError = "New password isn't strong enough.";
    }
    this.setState({
      displaySuccess: false,
      errorDialogOpen: true,
      errorDialogContent: [`Error:`, [displayError]],
    });
    return { error: true };
  };

  openCloseHandler = (stateKey, open = false, callback = () => {}) => () => {
    this.setState({ [stateKey]: open }, callback);
  };

  /**
   * Handle password update after aser is re-authenticated.
   * `res` isn't being used, but looks like this:
   * {
   *   additionalUserInfo: Ef {providerId: "password", isNewUser: false}
   *   credential: null
   *   operationType: "reauthenticate"
   *   user: Hk {D: Array(0), G: "AIz...etc
   * } = res
   */
  updatePassword = (newPassword) =>
    this.user
      .updatePassword(newPassword)
      .then(() => {
        this.setState({ displaySuccess: true, errorDialogOpen: false });
        return {};
      })
      .catch((err) => {
        if (err.code === 'auth/weak-password') {
          return Promise.reject(new Error('weak_password'));
        }
        return Promise.reject(new Error('generic'));
      });

  /** Returns promise. catch should display an error */
  validateCurrentPassword = (password1) => {
    const {
      userState: { userEmail = '' },
    } = this.props;

    const credential = getFirebaseEmailAuthCredential(userEmail, password1);

    if (!this.user) {
      return Promise.reject(new Error('invalid_credential'));
    }

    return this.user
      .reauthenticateAndRetrieveDataWithCredential(credential)
      .catch(() => Promise.reject(new Error('invalid_credential')));
  };

  render() {
    const { displaySuccess, errorDialogContent, errorDialogOpen } = this.state;
    const [errorTitle, errorContent] = errorDialogContent;
    return (
      <div className="account-settings-container">
        {errorDialogOpen && (
          <ErrorDialog
            content={errorContent}
            title={errorTitle}
            onClose={this.openCloseHandler('errorDialogOpen', false)}
          />
        )}
        <h2>Change Password</h2>
        <div className="account-settings-form-container-div">
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const { curPass, newPass1, newPass2 } = values;
              const errors = {};
              assignError(errors, 'curPass', getCurPassError(curPass));
              assignError(
                errors,
                'newPass1',
                getNewPass1Error(curPass, newPass1)
              );
              assignError(
                errors,
                'newPass2',
                getNewPass2Error(newPass1, newPass2)
              );
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              this.handleChangePassword(values.curPass, values.newPass1).then(
                (res) => {
                  setImmediate(() => {
                    if (res && !res.error) {
                      resetForm();
                    }
                    setSubmitting(false);
                  });
                }
              );
            }}
          >
            {({ dirty, isSubmitting, submitForm }) => (
              <Form className="settings-form">
                <Field
                  autoComplete="Current Password"
                  // autoFocus
                  component={FormikTextField}
                  fullWidth
                  label="Current Password"
                  margin="dense"
                  name="curPass"
                  type="password"
                />
                <br />
                <Field
                  autoComplete="New Password"
                  component={FormikTextField}
                  fullWidth
                  label="New Password"
                  margin="dense"
                  name="newPass1"
                  type="password"
                />
                <br />
                <Field
                  autoComplete="Confirm New Password"
                  component={FormikTextField}
                  fullWidth
                  label="Confirm New Password"
                  margin="dense"
                  name="newPass2"
                  type="password"
                  onKeyDown={(evt) => {
                    handleEnterKey(evt, submitForm);
                  }}
                />
                <br />
                {isSubmitting && <LinearProgress />}
                <br />
                {!dirty && displaySuccess && (
                  <div className="password-change-message">
                    Password successfully changed!
                  </div>
                )}
                <Button
                  className="btn-dialog-full-width btn-dark-blue"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  variant="contained"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userState: state.user,
});

export default connect(mapStateToProps, null)(ChangePassword);
