// todo: replace with a selector and css
// clsx(saveButtonDialog, className)
export const saveButtonDialog = {
  backgroundColor: '#004bff', // $treklee-dark-blue2
  color: 'white',
  margin: '0 auto',
  paddingTop: 7,
  width: '100%',
  '&:hover': {
    backgroundColor: '#023fd3',
  },
  maxWidth: 400,
};
