export const getDisplayName = ({ userEmail, userName }) => {
  if (userName) {
    return userName.split(' ')[0];
  }

  if (userEmail) {
    return userEmail.split('@')[0];
  }

  return null;
};

export const getWelcomeMessage = ({ userEmail, userName }) => {
  const displayName = getDisplayName({ userEmail, userName });

  return displayName ? `Welcome, ${displayName}!` : 'Welcome!';
};
