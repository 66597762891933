import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Chbx extends Component {
  static propTypes = {
    showOnly: PropTypes.bool,
  };

  static defaultProps = {
    showOnly: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {};
    if (nextProps.checked !== prevState.checked) {
      const { checked } = nextProps;
      state.checked = checked;
    }
    return state;
  }

  onChange = (event, checked) => {
    const { onChange } = this.props;
    onChange(checked);
  };

  onStopUnique = () => {
    const { onUnique, id } = this.props;
    onUnique(id);
  };

  render() {
    const { checked } = this.state;
    const { label, showOnly } = this.props;
    return (
      <div className={`checkbox${checked ? ' selected' : ''}`}>
        <b />
        <input type="checkbox" checked={checked} onChange={this.onChange} />
        <span onClick={this.onChange}>{label}</span>
        <span
          onClick={this.onStopUnique}
          style={{ display: showOnly ? '' : 'none' }}
        >
          Only
        </span>
      </div>
    );
  }
}

export default Chbx;
