/*
const sortOptions = [
  { text: 'Cheapest (points)', value: 0 },
  { text: 'Cheapest (cash)', value: 1 },
  { text: 'Departure time', value: 2 },
  { text: 'Arrival time', value: 3 },
  { text: 'Duration', value: 4 },
]
*/
export default (sortType, results) => {
  if (sortType === 4) {
    results.sort((a, b) => {
      let durationA = 0;
      let durationB = 0;
      if (a.inboundLeg) {
        durationA += a.inboundLeg.Duration;
      }
      if (a.outboundLeg) {
        durationA += a.outboundLeg.Duration;
      }
      if (b.inboundLeg) {
        durationB += b.inboundLeg.Duration;
      }
      if (b.outboundLeg) {
        durationB += b.outboundLeg.Duration;
      }
      if (durationA > durationB) {
        return 1;
      }
      if (durationA < durationB) {
        return -1;
      }
      return 0;
    });
  }
  if (sortType === 3) {
    results.sort((a, b) => {
      let durationA = 0;
      let durationB = 0;

      if (a.outboundLeg) {
        durationA += a.outboundLeg.ArrivalTime;
      }
      if (b.outboundLeg) {
        durationB += b.outboundLeg.ArrivalTime;
      }
      if (durationA > durationB) {
        return 1;
      }
      if (durationA < durationB) {
        return -1;
      }
      return 0;
    });
  }
  if (sortType === 2) {
    results.sort((a, b) => {
      let durationA = 0;
      let durationB = 0;

      if (a.outboundLeg) {
        durationA += a.outboundLeg.DepartureTime;
      }
      if (b.outboundLeg) {
        durationB += b.outboundLeg.DepartureTime;
      }
      if (durationA > durationB) {
        return 1;
      }
      if (durationA < durationB) {
        return -1;
      }
      return 0;
    });
  }
  if (sortType === 1) {
    results.sort((a, b) => {
      if (a.minPrice > b.minPrice) {
        return 1;
      }
      if (a.minPrice < b.minPrice) {
        return -1;
      }
      return 0;
    });
  }
  if (sortType === 0) {
    results.sort((a, b) => {
      if (a.minPoints > b.minPoints) {
        return 1;
      }
      if (a.minPoints < b.minPoints) {
        return -1;
      }
      return 0;
    });
  }
};
