import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import BackButtonMobile from 'components/Shared/BackButtonMobile';
import { fetchAirportData as fetchAirportDataUtil } from 'services/fetchAirportData';
import Icons from 'utils/Icons';
import { KeyCodes, isKey } from 'utils/KeyCodes';
import { addEvent, removeEvent } from 'utils/browser';
import { windowStatePropType } from 'utils/propTypes';

import { getRef } from 'utils/refUtils';

import { AirportInput } from './AirportAutocompleteInput';
import { AirportResultsDropdown } from './AirportResultsDropdown';
import { getDerivedStateFromProps } from './utils';

const checkIsMobile = (windowState) => windowState.device !== 'desktop';

const localityIcon = {
  from: Icons.FlightTakeOff,
  to: Icons.FlightLanding,
};

class AirportAutoComplete extends Component {
  static propTypes = {
    // defaultValue: PropTypes.shape({
    //   shortDisplayName: PropTypes.string,
    //   iata: PropTypes.string,
    // }),
    // icon: PropTypes.node,
    inputMode: PropTypes.string,
    inputProps: PropTypes.shape({ onSave: PropTypes.func }),
    locality: PropTypes.oneOf(['from', 'to']).isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    windowState: windowStatePropType.isRequired,
  };

  static defaultProps = {
    // defaultValue: null,
    // icon: '',
    // locality: undefined,
    inputMode: 'basic',
    inputProps: { onSave: () => {} },
    onChange: () => {},
    placeholder: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      airportCache: null, // cached version of state.airport
      airport: null,
      mobileInputFieldOpen: false,
      loading: false,
      results: [],
      resultsPanelOpen: false,
      selectedIndex: 0,
      value: '',
    };
    this.timeout = null;
    this.autoComplete = React.createRef();
    // this.mobileInputField = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return getDerivedStateFromProps(nextProps, prevState);
  }

  componentDidMount() {
    addEvent(window, 'keydown', this.keyDown);
    addEvent(window, 'mousedown', this.mouseDown);
    addEvent(window, 'touchstart', this.mouseDown);
  }

  componentWillUnmount() {
    removeEvent(window, 'keydown', this.keyDown);
    removeEvent(window, 'mousedown', this.mouseDown);
    removeEvent(window, 'touchstart', this.mouseDown);
    clearTimeout(this.timeout);
  }

  /* eslint-disable react/sort-comp */

  /**
   * Click-away listener - checks if target is inside component.
   * If not, close results panel.
   */
  mouseDown = (event) => {
    const { resultsPanelOpen } = this.state;

    let { target } = event;
    while (target) {
      if (target === getRef(this.autoComplete)) {
        return true;
      }
      target = target.parentNode;
    }

    if (resultsPanelOpen) {
      this.setState({ resultsPanelOpen: false });
    }

    return false;
  };

  keyDown = (event) => {
    const { results, resultsPanelOpen, selectedIndex } = this.state;

    if (!resultsPanelOpen || !results) {
      return;
    }

    if (isKey(event, KeyCodes.ENTER)) {
      const airport = results[selectedIndex];
      this.setAirportAndClose(airport);
      return;
    }

    let newSelectedIndex;

    if (isKey(event, KeyCodes.UP_ARROW)) {
      newSelectedIndex = Math.max(selectedIndex - 1, 0);
      this.setState({ selectedIndex: newSelectedIndex });
      return;
    }
    if (isKey(event, KeyCodes.DOWN_ARROW)) {
      newSelectedIndex = Math.min(selectedIndex + 1, results.length - 1);
      this.setState({ selectedIndex: newSelectedIndex });
    }
  };

  /**
   * Starts a chain of events to render AirportResultsDropdown
   * set timeout -> start loading, fetch airport data
   * empty airport value
   */
  onInput = (event) => {
    const { value } = event.target;
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.setState({ loading: true, resultsPanelOpen: true, query: value });
      this.fetchAirportData(value);
    }, 300);

    this.runChangeHandler(null);
    this.setState({ airport: null, results: [], value });
  };

  fetchAirportData(query) {
    return fetchAirportDataUtil(query)
      .then((res) => {
        const { query: queryOnState } = this.state;
        if (queryOnState === query) {
          this.setState({ loading: false, results: res, selectedIndex: -1 });
        }
      })
      .catch(console.error);
  }

  selectAirport = (event) => {
    const { results } = this.state;
    let node = event.nativeEvent.target;

    while (!node.classList.contains('airport')) {
      node = node.parentNode;
    }
    const airport = results[node.getAttribute('index')];
    this.setAirportAndClose(airport);
  };

  setAirportAndClose = (airport) => {
    if (airport) {
      this.setState({
        airport,
        mobileInputFieldOpen: false,
        resultsPanelOpen: false,
      });
      this.runChangeHandler(airport);
    }
  };

  onFocus = () => {};

  /**
   * For UX improvement, input is cleared in mobile view
   * To do this, we keep airportCache and reset all other
   * value fields
   * @param {object} [event]
   */
  onMouseDownInsideInputField = () => {
    const { windowState } = this.props;

    const isMobile = checkIsMobile(windowState);
    if (!isMobile) {
      return;
    }

    this.setState({
      airport: null,
      // airportCache: null, // don't remove cached airport
      mobileInputFieldOpen: true,
      value: '',
    });
    this.runChangeHandler(null);
  };

  /**
   * clear airport value from state on mobile view close
   */
  closeMobileView = () => {
    const { airportCache, airport } = this.state;
    this.setState({ mobileInputFieldOpen: false, resultsPanelOpen: false });

    if (!airportCache && !airport) {
      this.setState({ airport: null, airportCache: null, value: '' });
      this.runChangeHandler(null);
    }
    // use cached airport if new airport wasn't selected
    this.runChangeHandler(airport || airportCache);
  };

  runChangeHandler(item) {
    const { onChange } = this.props;
    onChange(item);
  }

  render() {
    const {
      airport,
      loading,
      mobileInputFieldOpen,
      results,
      resultsPanelOpen,
      selectedIndex,
      value: valueOnState,
    } = this.state;
    const {
      inputMode,
      inputProps = {},
      locality,
      placeholder,
      windowState,
    } = this.props;

    const value = (airport && airport.shortDisplayName) || valueOnState;

    return (
      <div
        className={clsx(
          'airport-autocomplete',
          mobileInputFieldOpen && 'mobile',
          locality
        )}
        ref={this.autoComplete}
      >
        <div className="bar">
          {/* Icon only displayed in mobile view */}
          <span className="airport-icon">{localityIcon[locality]}</span>
          <AirportInput
            inputProps={{
              autoComplete: 'off',
              onChange: this.onInput,
              onFocus: this.onFocus,
              onMouseDown: this.onMouseDownInsideInputField,
              // ref: this.mobileInputField,
              placeholder,
              type: 'text',
              value,
            }}
            airport={airport}
            isMobile={checkIsMobile(windowState)}
            mobileInputFieldOpen={mobileInputFieldOpen}
            mode={inputMode}
            onSave={() => inputProps.onSave(airport)}
          />
          <BackButtonMobile onClick={this.closeMobileView} />
        </div>
        <div className="content">
          <div
            className={clsx(
              'results',
              resultsPanelOpen && 'open',
              loading && 'loading'
            )}
          >
            {Icons.Loader}
            <AirportResultsDropdown
              onClick={this.selectAirport}
              results={results}
              selectedIndex={selectedIndex}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AirportAutoComplete;
