import {
  getFirebaseUser,
  getUserIDToken,
  getAnyUserData,
  signOutUser,
} from 'services/firebase';
import { getSingleAirportData } from 'services/fetchAirportData';
import {
  // deleteUserLoyaltyPrograms,
  // getUserLoyaltyPrograms,
  getUserData,
  // saveUserLoyaltyPrograms,
  updateUserData,
  // updateUserLoyaltyPrograms,
} from 'services/fetchUserData';
// import { difference, getSetCopy } from 'utils/dataUtils';

import {
  // LOGIN,
  LOGIN_INIT,
  LOGIN_SUCCESS,
  // LOGIN_FAIL,
  GUEST_INIT,
  logoutAPIConstants,
  displayNameAPIConstants,
  homeAirportAPIConstants,
  LOYALTY_PROGRAM_BALANCE_UPDATE,
  LOYALTY_PROGRAM_ADD,
  LOYALTY_PROGRAM_DELETE,
  LOYALTY_PROGRAM_STATUS_UPDATE,
  LOYALTY_PROGRAMS_UPDATE,
  userDataAPIConstants,
} from './constants';
import {
  homeAirportSelector,
  tokenSelector,
  userIDSelector,
  userNameSelector,
  userProgramsSelector,
} from './selectors';
import {
  handleEmailVerification,
  handleLoginToken,
  userNeedsStoreInit,
} from './utils';

export const initGuest = () => ({ type: GUEST_INIT });

/**
 * @param {object} userProperties - See initialState for expected properties
 * @returns {object}
 */
export const loginUser = (
  firebaseData,
  { needLoginToken, needSignupToken, token } = {}
) => {
  return async (dispatch) => {
    const userData = getAnyUserData(firebaseData);
    const {
      displayName,
      email,
      emailVerified,
      photoURL,
      providerData,
    } = userData;

    let parsedToken = token || undefined;

    if (needLoginToken) {
      try {
        // console.log(userData.toJSON()); // what is this?
        // userData.toJSON().stsTokenManager.accessToken ||
        parsedToken = await getUserIDToken(userData);
      } catch (error) {
        console.error(error);
        // todo, is this the right way to handle?
        // return dispatch(initGuest());
      }
    } else if (needSignupToken) {
      // TODO: this is an async call
    }

    let trekleeUserData;

    dispatch({ type: userDataAPIConstants.init });
    try {
      trekleeUserData = await getUserData(parsedToken);
      trekleeUserData.homeAirport = await getSingleAirportData(
        trekleeUserData.homeAirport
      );
      dispatch({ data: trekleeUserData, type: userDataAPIConstants.success });
    } catch (error) {
      dispatch({ error, type: userDataAPIConstants.fail });
      return dispatch(initGuest());
    }

    const parsed = {
      emailVerified,
      homeAirport: trekleeUserData.homeAirport,
      id: trekleeUserData.id,
      lastLogin: Date.now(),
      programs: trekleeUserData.accounts, // if empty [], need handling
      providerData,
      token: parsedToken,
      userEmail: email,
      userName: trekleeUserData ? trekleeUserData.displayName : displayName, // this field is editable by user
      // todo: create a util to get userPhotoURL as derived data
      // to use in component
      userPhotoURL: photoURL,
    };

    dispatch({
      type: LOGIN_SUCCESS,
      data: { firebaseData, parsed },
    });

    return { token: parsedToken };
  };
};

/**
 * Does a login at load of App
 * - checks if user already logged in (Firebase magic)
 * - if so:
 *   - check if store contains user's properties, set token if not
 *   - check if they've verified their email, remove token if so
 * - if not: it's a guest
 * @returns {Function} thunk
 */
export const attemptLogin = () => {
  return (dispatch, getState) => {
    dispatch({ type: LOGIN_INIT });

    return getFirebaseUser()
      .then(async (user) => {
        const state = getState();

        // User hasn't logged in yet
        if (userNeedsStoreInit(state)) {
          const res = await dispatch(
            loginUser(user, {
              needLoginToken: true,
            })
          );
          handleLoginToken(res.token);
        }

        handleEmailVerification(user);
      })
      .catch((/* err */) => {
        dispatch(initGuest());
      });
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    dispatch({ type: logoutAPIConstants.init });

    try {
      await signOutUser();
      dispatch({
        type: logoutAPIConstants.success,
      });
      return Promise.resolve();
    } catch (error) {
      dispatch({ error, type: logoutAPIConstants.fail });
      return Promise.reject(error);
    }
  };
};

// Account Info

export const updateDisplayName = (name) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = tokenSelector(state);
    const id = userIDSelector(state);
    const userName = userNameSelector(state);

    // do nothing, no need to update.
    if (userName === name) return;

    dispatch({ type: displayNameAPIConstants.init });

    try {
      await updateUserData(token, {
        displayName: name,
        id,
      });
      dispatch({ type: displayNameAPIConstants.success, data: { name } });
    } catch (error) {
      dispatch({ type: displayNameAPIConstants.fail, error });
    }
  };
};

/**
 * @param {Object} airport
 * @returns {Object}
 */
export const updateHomeAirport = (airport) => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = tokenSelector(state);
    const id = userIDSelector(state);
    const homeAirport = homeAirportSelector(state);

    // do nothing, no need to update.
    if (!airport || (homeAirport && homeAirport.iata === airport.iata)) return;

    dispatch({ type: homeAirportAPIConstants.init });
    try {
      await updateUserData(token, {
        homeAirport: airport.iata,
        id,
      });
      dispatch({
        type: homeAirportAPIConstants.success,
        data: { airport },
      });
    } catch (error) {
      dispatch({ type: homeAirportAPIConstants.fail, error });
    }
  };
};

// Loyalty

export const addLoyaltyProgram = (program) => {
  return {
    type: LOYALTY_PROGRAM_ADD,
    data: { program },
  };
};

export const deleteLoyaltyProgram = (program) => {
  return {
    type: LOYALTY_PROGRAM_DELETE,
    data: { program },
  };
};

// todo: allow user to confirm deletion of programs. This would necessitate a preceding event.
export const updateLoyaltyPrograms = (programs) => {
  return (dispatch, getState) => {
    const state = getState();
    const userPrograms = userProgramsSelector(state);
    const loyaltyDict = state.loyalty.dict; // this is why I needed a thunk

    const userProgramsSet = new Set(userPrograms.map((item) => item.id));

    const data = {
      // additions: difference(programs, userProgramsSet),
      // deletions: difference(userProgramsSet, programs),
      programs: userPrograms
        // filter out deleted programs
        .filter((program) => programs.has(program.id))
        // init added programs
        .concat(
          Array.from(programs)
            // filter out programs that currently exist in user's programs
            .filter((programId) => !userProgramsSet.has(programId))
            .map((programId) => {
              const newProgram = loyaltyDict[programId];
              return {
                ...newProgram,
                points: 0,
                status: 'GOLD', // or whatever.
              };
            })
        ),
    };

    dispatch({
      type: LOYALTY_PROGRAMS_UPDATE,
      data,
    });
  };
};

export const updateLoyaltyProgramBalance = (programId, points) => {
  // TODO: add success and fail actions
  return {
    type: LOYALTY_PROGRAM_BALANCE_UPDATE,
    data: { points, programId },
  };
};

export const updateLoyaltyProgramStatus = (programId, status) => {
  return {
    type: LOYALTY_PROGRAM_STATUS_UPDATE,
    data: { status, programId },
  };
};
