export const WINDOW_RESIZE_DESKTOP = 'WINDOW_RESIZE/desktop';
export const WINDOW_RESIZE_IPAD = 'WINDOW_RESIZE/ipad';
export const WINDOW_RESIZE_PHONE = 'WINDOW_RESIZE/phone';
export const WINDOW_RESIZE_LANDSCAPE = 'WINDOW_RESIZE/landscape';
const TYPES = new Set([
  WINDOW_RESIZE_DESKTOP,
  WINDOW_RESIZE_IPAD,
  WINDOW_RESIZE_PHONE,
  WINDOW_RESIZE_LANDSCAPE,
]);

export const dispatchWindowResize = ({ height, width }) => dispatch => {
  if (height > 599) {
    if (width > 768) {
      dispatch({
        type: WINDOW_RESIZE_DESKTOP,
        height,
        width,
      });
    } else if (width <= 768 && width >= 500) {
      dispatch({
        type: WINDOW_RESIZE_IPAD,
        height,
        width,
      });
    } else if (width < 500) {
      dispatch({
        type: WINDOW_RESIZE_PHONE,
        height,
        width,
      });
    }
  } else {
    dispatch({
      type: WINDOW_RESIZE_LANDSCAPE,
      height,
      width,
    });
  }
};

const getDevice = type => type.split('/').pop();

export default (state = {}, action) => {
  const { height, type, width } = action;
  if (TYPES.has(type)) {
    return {
      ...state,
      device: getDevice(type),
      height,
      width,
    };
  }
  return state;
};
