import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import { selectMenuPropTypes, selectMenuDefaults } from './propTypes-shared';

const nameToComp = {
  Select,
  TextField,
};

const disableUnderline = {
  disableUnderline: true,
};

const nameToMUIProps = {
  Select: {
    ...disableUnderline,
  },
  TextField: {
    margin: 'normal',
    select: true,
    SelectProps: disableUnderline,
  },
};

const SelectMenu = ({
  className,
  muiProps,
  muiVariation,
  onChange,
  options,
  value,
}) => {
  const SelectComp = nameToComp[muiVariation];

  return (
    <SelectComp
      className={className}
      onChange={onChange}
      {...Object.assign({}, nameToMUIProps[muiVariation], muiProps)}
      value={value}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </SelectComp>
  );
};

SelectMenu.propTypes = selectMenuPropTypes;
SelectMenu.defaultProps = selectMenuDefaults;

export default SelectMenu;
