import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Formik, Field, Form } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import { LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { loginUser as loginUserAction } from 'modules/user/actions';
import {
  firebasePopupSignin,
  firebaseSignupWithEmail,
  getAnyUserData,
  getUserIDToken,
  sendEmailVerification,
} from 'services/firebase';
import {
  assignError,
  getEmailError,
  getPassError,
  handleEnterKey,
} from 'utils/forms/Formik';
import {
  historyPropType,
  locationPropType,
  // userStatePropType,
} from 'utils/propTypes';

import ErrorDialog from 'components/Dialogs/ErrorDialog';
import ServiceHandler from './ServiceHandler';

import {
  renderOtherMethod,
  rerouteWithState,
  rerouteWithStateOrBackupRoute,
  LOGIN,
  REROUTE_URL,
} from './utils';

const initialValues = { email: '', password: '' };

const INITIAL_STATE = {
  errorDialogContent: [null, null],
  errorDialogOpen: false,
  showEmailSignup: false,
};

class Signup extends Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    history: historyPropType.isRequired,
    location: locationPropType.isRequired,
    /* eslint-enable react/no-unused-prop-types */
    loginUser: PropTypes.func.isRequired,

    // className: PropTypes.string,
    // logoutUser: PropTypes.func,
    // userState: userStatePropType,
  };

  static defaultProps = {
    // className: null,
    // loginUser: () => {},
    // logoutUser: () => {},
    // userState: {
    //   userEmail: undefined,
    //   userPhotoURL: '',
    // },
  };

  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  handleRoute = (route) => {
    rerouteWithState(this.props, route);
  };

  /**
   * Calls firebasePopupSignin with service
   * @param {string} service - 'google'|'fakebook'
   */
  loginWithService = (service) => () => {
    firebasePopupSignin(service)
      .then((data) => this.signUpSuccessCallback(data))
      .then((res) => {
        if (res && !res.error) {
          this.onSubmit();
        }
      })
      .catch((error) => this.signUpFailureCallback(error));
  };

  openCloseHandler = (stateKey, open = false, callback = () => {}) => () => {
    this.setState({ [stateKey]: open }, callback);
  };

  onSubmit = () => {
    rerouteWithStateOrBackupRoute(this.props, REROUTE_URL);
  };

  /**
   * After response:
   * - sendEmailVerification
   * - if no email in payload, return failure callback
   * - set token in localStorage
   * - dispatch loginUser
   * @param {Object} data firebase response
   */
  signUpSuccessCallback = async (data) => {
    const { loginUser } = this.props;
    const userData = getAnyUserData(data);
    const { email } = userData;
    // console.log(data, userData);
    // const userObj = firebase.auth().currentUser;
    sendEmailVerification();

    if (!email) {
      return this.signUpFailureCallback({
        error: true,
        message: 'Email not found.',
      });
    }

    const userToken = await getUserIDToken(userData).catch((err) => {
      console.error(err);
    });
    localStorage.setItem('token', userToken);

    loginUser(data, { token: userToken });
    // todo: create new account on backend - api call with acct info
    return {};
  };

  signUpFailureCallback = (error) => {
    const { message, title } = error;
    this.setState({
      errorDialogOpen: true,
      errorDialogContent: [`Error: ${title || ''}`, [message]],
    });
    return { error: true, ...error };
  };

  signUpWithEmail = (email, password) =>
    firebaseSignupWithEmail(email, password)
      .then((data) => this.signUpSuccessCallback(data))
      .catch((error) =>
        this.signUpFailureCallback({ title: 'Sign up unsuccessful.', ...error })
      );

  render() {
    const { errorDialogContent, errorDialogOpen, showEmailSignup } = this.state;
    const [errorTitle, errorContent] = errorDialogContent;

    const otherMethod = renderOtherMethod({
      onClick: () => this.handleRoute('/login'),
      otherName: LOGIN,
    });

    return (
      <div className="signup-container">
        {errorDialogOpen && (
          <ErrorDialog
            content={errorContent}
            title={errorTitle}
            onClose={this.openCloseHandler('errorDialogOpen', false)}
          />
        )}
        {!showEmailSignup && (
          <Fragment>
            <h1>Welcome to Treklee!</h1>
            <h2>Sign up to plan your next big trip using points & miles.</h2>
            <ServiceHandler type="signup" onClick={this.loginWithService} />
            <div className="hr-with-text">
              <hr className="hr-lite" />
              <span>or</span>
            </div>
            <Button
              className="btn-dialog-full-width btn-dialog-email-signup"
              color="primary"
              onClick={this.openCloseHandler('showEmailSignup', true)}
              variant="contained"
            >
              Sign up with email
            </Button>
            {otherMethod}
          </Fragment>
        )}
        {showEmailSignup && (
          <Fragment>
            <h1>Create an account</h1>
            <Formik
              initialValues={initialValues}
              validate={(values) => {
                const { email, password } = values;
                const errors = {};
                assignError(errors, 'email', getEmailError(email));
                assignError(errors, 'password', getPassError(password));
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setImmediate(() => {
                  this.signUpWithEmail(values.email, values.password).then(
                    (res) => {
                      if (res && !res.error) {
                        this.onSubmit();
                      }
                      setSubmitting(false);
                    }
                  );
                });
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <Form className="settings-form">
                  <Field
                    autoComplete="email"
                    // autoFocus
                    component={FormikTextField}
                    fullWidth
                    label="Email"
                    margin="dense"
                    name="email"
                    type="email"
                  />
                  <Field
                    autoComplete="Password"
                    component={FormikTextField}
                    fullWidth
                    label="Password"
                    margin="dense"
                    name="password"
                    type="password"
                    onKeyDown={(evt) => {
                      handleEnterKey(evt, submitForm);
                    }}
                  />
                  {isSubmitting && <LinearProgress />}
                  {
                    // error.length ? <div className="password-change-success">{error}</div> : null
                  }
                  <Button
                    className="btn-dialog-full-width btn-dialog-email-signup btn-dialog-email-signup-submit"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    variant="contained"
                  >
                    Sign up
                  </Button>
                </Form>
              )}
            </Formik>
            {otherMethod}
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userState: state.user,
});

const mapDispatchToProps = { loginUser: loginUserAction };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
