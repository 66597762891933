import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';

const ExpansionPanel = withStyles({
  root: {
    border: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    margin: 'auto',
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
    border: 0,
    minHeight: '0 !important',
  },
  content: {
    margin: '0 !important',
  },
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiExpansionPanelDetails);

class CollapseFilterPanel extends Component {
  static propTypes = {
    title: PropTypes.string,
    defaultOpen: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    defaultOpen: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    const { defaultOpen } = this.props;
    this.setState({ open: defaultOpen });
  }

  toggle = () => (event, open) => {
    this.setState({
      open,
    });
  };

  render() {
    const { title, children } = this.props;
    const { open } = this.state;
    // 'https://d3o54sf0907rz4.cloudfront.net/airline-logos/v3/centered/bugs/png/128x128/aa-logo-bug.png';
    return (
      <ExpansionPanel
        className="search-filter-panel"
        expanded={open}
        onChange={this.toggle()}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className="title">{title}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="content">{children}</div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default CollapseFilterPanel;
