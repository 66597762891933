// import cloneDeep from 'lodash/cloneDeep';

/**
 * Build data structures from json
 * Warning: Input data is mutated. json is too big to be worth cloning.
 * @param {object} json
 * @param {object} filterRanges
 * @param {object} search
 * @returns {object} {
 *   flights: itineraries, <- mutated json
 *   ranges: filterRanges, <- mutated filterRanges
 *   }
 */
export const parseFlightsData = (json, filterRanges, search) => {
  /* eslint-disable no-param-reassign */

  // build defaults for filterRanges
  filterRanges.carriers = filterRanges.carriers || {};
  filterRanges.price = filterRanges.price || { min: Infinity, max: 0 };
  filterRanges.duration = filterRanges.duration || { min: Infinity, max: 0 };

  // build carriers map with key: Id, value: carrierObj
  const { carriers = {} } = filterRanges;
  json.carriers.forEach(element => {
    carriers[element.Id] = element;
  });

  const programs = {};

  const classes = {
    1: 'economyRate',
    2: 'premiumEconomyRate',
    3: 'businessRate',
    4: 'firstRate',
  };

  // Dictionary of Loyalty Programs
  const awards = {};
  json.awardOptions.forEach(element => {
    awards[element.id] = element;
    if (programs[element.program.id]) {
      element.program = programs[element.program.id];
    } else {
      programs[element.program.id] = element.program;
    }
    element.program.total = 0;
  });

  // Dictionary of Agents
  const agents = {};
  json.agents.forEach(element => {
    // console.log(element.Id, element);
    agents[element.Id] = element;
  });
  const carriersSoloFlights = {};

  // Dicitonary of places
  const places = {};
  json.places.forEach(element => {
    places[element.Id] = element;
  });

  // Dictionary of segments
  const segments = {};
  json.segments.forEach(element => {
    segments[element.Id] = element;
    element.Carrier = carriers[element.Carrier];
    element.OperatingCarrier = carriers[element.OperatingCarrier];
    element.destination = places[element.DestinationStation];
    element.origin = places[element.OriginStation];
  });

  // Dictionary of legs
  const legs = {};
  json.legs.forEach(element => {
    legs[element.Id] = element;
    element.Carriers = element.Carriers.map(carrierId => carriers[carrierId]);
    element.OperatingCarriers = element.OperatingCarriers.map(
      carrierId => carriers[carrierId],
    );

    element.ArrivalTime = new Date(element.Arrival).getTime();
    element.DepartureTime = new Date(element.Departure).getTime();

    element.Segments = element.SegmentIds.map(segmentId => segments[segmentId]);
    element.destination = places[element.DestinationStation];
    element.origin = places[element.OriginStation];

    filterRanges.duration.max = Math.max(
      filterRanges.duration.max,
      element.Duration,
    );
    filterRanges.duration.min = Math.min(
      filterRanges.duration.min,
      element.Duration,
    );
  });
  filterRanges.duration.min = Math.ceil(filterRanges.duration.min / 30);
  filterRanges.duration.max = Math.ceil(filterRanges.duration.max / 30);

  // Dictionary of itineraries
  const { itineraries } = json;
  itineraries.forEach(element => {
    let carrier = null;
    // Get leg info from dict
    if (element.inboundLegId) {
      element.inboundLeg = legs[element.inboundLegId];
      carrier = element.inboundLeg.OperatingCarriers[0]; // eslint-disable-line prefer-destructuring
      element.inboundLeg.OperatingCarriers.forEach(_carrier => {
        if (_carrier !== carrier) {
          carrier = null;
        }
      });
    }
    // Get leg info from dict
    if (element.outboundLegId) {
      element.outboundLeg = legs[element.outboundLegId];
      if (!carrier && element.inboundLeg) {
        // inboundLeg only exists in a round trip flight
        carrier = element.inboundLeg.OperatingCarriers[0]; // eslint-disable-line prefer-destructuring
      }
      element.outboundLeg.OperatingCarriers.forEach(_carrier => {
        if (_carrier !== carrier) {
          carrier = null;
        }
      });
    }
    // check fligt has only one carrier
    if (carrier) {
      carriersSoloFlights[carrier.Id] = carrier;
      carrier.only = true;
    }
    if (element.outboundLegId && element.inboundLegId) {
      element.roundTrip = true;
    } else {
      element.roundTrip = false;
    }
    // map awards while filtering out the ones that dont match the flight class (economy, first class....)
    element.awardOptions = element.awardOptionIds
      .map(id => awards[id])
      .filter(award => award.awardChart[classes[search.flightClass]]);
    element.awardOptions.sort((awardA, awardB) => {
      const a = awardA.awardChart[classes[search.flightClass]].rate;
      const b = awardB.awardChart[classes[search.flightClass]].rate;
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });
    element.awardOptions.forEach(award => {
      award.program.total += 1; // eslint-disable-line no-param-reassign
    });

    element.awardOptionsFilter = element.awardOptions;

    // map agents
    element.pricingOptionsOriginal = element.pricingOptions.concat([]);
    element.pricingOptions.forEach(pricing => {
      pricing.Agents = pricing.Agents.map(agentId => agents[agentId]);
      // obtain min price of entire list
      filterRanges.price.max = Math.max(filterRanges.price.max, pricing.Price);
      filterRanges.price.min = Math.min(filterRanges.price.min, pricing.Price);
    });
  });
  filterRanges.programs = Object.keys(programs)
    .map(key => programs[key])
    .filter(program => program.total > 0);
  filterRanges.programs = filterRanges.programs.sort((a, b) => {
    if (a.popularity > b.popularity) {
      return -1;
    }
    if (a.popularity < b.popularity) {
      return 1;
    }
    return 0;
  });
  /* eslint-enable no-param-reassign */
  console.log('carriersSoloFlights', carriersSoloFlights, filterRanges);

  return { flights: itineraries, ranges: filterRanges };
};
