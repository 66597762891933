import clsx from 'clsx';
import 'react-dates/initialize';
// import { DateRangePickerPhrases } from 'react-dates/lib/defaultPhrases';
import React from 'react';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import Button from '@material-ui/core/Button';

import { isSafari } from 'utils/browser';
import { runIfMethod } from 'utils/dataUtils';

import { END_DATE, NUM_MONTHS_MOBILE, START_DATE } from './constants';
import { compareMoment, getInputValue, propTypesDatepicker } from './utils';

const FOCUS_TIMEOUT = isSafari ? 100 : 10;

class MobileRange extends React.Component {
  static propTypes = propTypesDatepicker.MobileRange;

  static defaultProps = {
    onDatesChange: () => {},
    onFocusChange: () => {},
  };

  constructor(props) {
    super(props);
    const { endDate, focusedInput, startDate } = this.props;

    this.state = {
      endDate,
      endInputValue: getInputValue(endDate),
      focusedInput,
      startDate,
      startInputValue: getInputValue(startDate),
    };
  }

  componentDidUpdate(prevProps) {
    const { endDate, focusedInput, startDate } = this.props;
    const diffs = compareMoment(prevProps, this.props);
    if (diffs) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({
        ...diffs,
        endInputValue: getInputValue(endDate),
        startInputValue: getInputValue(startDate),
      });
    }
    if (prevProps.focusedInput !== focusedInput) {
      this.setState({ focusedInput });
    }
  }

  /* eslint-disable react/sort-comp */

  onDatesChange = ({ startDate, endDate }) => {
    const { onDatesChange } = this.props;
    this.setState({
      startDate,
      endDate,
      startInputValue: getInputValue(startDate),
      endInputValue: getInputValue(endDate),
    });
    onDatesChange({ startDate, endDate });
  };

  closeCalendar = () => {
    const { onFocusChange } = this.props;
    this.setState({ focusedInput: null });
    onFocusChange(null);
  };

  handleFocusChange = (focusedInput) => {
    const { onFocusChange } = this.props;
    onFocusChange(focusedInput);
    this.setState({ focusedInput }, () => {
      this.jumpToCorrectFocus(focusedInput);
    });
  };

  getInputClassName = (type, focusedInput, value) =>
    clsx(type === focusedInput && 'focused', !value && 'placeholder');

  /**
   * [TWA-381] Fix for focus jumping to first visible date when number of
   * months shown is greater than NUM_MONTHS_MOBILE
   * set focus to CalendarDay__selected_start or CalendarDay__selected_end
   * depending on which input is focused
   * @param {string} focusedInput
   */
  jumpToCorrectFocus = (focusedInput) => {
    return setTimeout(() => {
      if (
        document.querySelectorAll('.CalendarMonth_caption').length <=
        NUM_MONTHS_MOBILE
      ) {
        return;
      }

      // these are both `td` elements
      const selectedStart = document.querySelector(
        '.CalendarDay__selected_start'
      );
      const selectedEnd = document.querySelector('.CalendarDay__selected_end');

      if (focusedInput === END_DATE && selectedEnd) {
        runIfMethod('focus', selectedEnd);
        return;
      }

      runIfMethod('focus', selectedStart);
    }, FOCUS_TIMEOUT);
  };

  renderCalendarInfoFunc = () => {
    const { endInputValue, focusedInput, startInputValue } = this.state;
    // Disabling irrelevant a11y rules (keyboard doesn't apply to mobile)
    /* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
    return (
      <div className="calendar-info">
        <div className="calendar-info-top">
          <div className="calendar-info-dates">
            <div
              className={this.getInputClassName(
                START_DATE,
                focusedInput,
                startInputValue
              )}
              onClick={() => this.handleFocusChange(START_DATE)}
              role="button"
            >
              {startInputValue || 'Depart'}
            </div>
            <div
              className={this.getInputClassName(
                END_DATE,
                focusedInput,
                endInputValue
              )}
              onClick={() => this.handleFocusChange(END_DATE)}
              role="button"
            >
              {endInputValue || 'Return'}
            </div>
          </div>
        </div>
        <div className="calendar-info-bottom">
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={this.closeCalendar}
            disabled={!startInputValue || !endInputValue}
          >
            Save
          </Button>
        </div>
      </div>
    );
    /* eslint-enable jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
  };

  render() {
    const { endDate, focusedInput, startDate } = this.state;
    // destructure the 3 props I don't want to pass through
    const {
      endDate: endDateProp,
      focusedInput: focusedInputProp,
      startDate: startDateProp,
      ...rest
    } = this.props;
    return (
      <DateRangePicker
        {...rest}
        endDate={endDate}
        endDateId="mobile_range_end_date_id"
        focusedInput={focusedInput}
        onClose={this.closeCalendar}
        onDatesChange={this.onDatesChange}
        onFocusChange={this.handleFocusChange}
        renderCalendarInfo={this.renderCalendarInfoFunc}
        startDate={startDate}
        startDateId="mobile_range_start_date_id"
      />
    );
  }
}

export default MobileRange;
