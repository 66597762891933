import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AirportAutocomplete from 'components/FlightSearchScene/Search/AirportAutocomplete';
import InputWithEditIcon from 'components/Shared/InputWithEditIcon';

import { getPhotoURLAlternate } from 'modules/user/utils';
import { isValidUserName } from 'utils/Validators';

import { getWelcomeMessage } from './utils';

const userProfileImageClass = 'user-profile-image';

class UserInfo extends Component {
  static propTypes = {
    updateDisplayName: PropTypes.func.isRequired,
    updateHomeAirport: PropTypes.func.isRequired,
    userState: PropTypes.shape({
      homeAirport: PropTypes.shape({}),
      userEmail: PropTypes.string,
      userName: PropTypes.string,
      userPhotoURL: PropTypes.string,
    }),
  };

  static defaultProps = {
    userState: {
      userEmail: undefined,
      userName: undefined,
    },
  };

  constructor(props) {
    super(props);

    const { userState } = this.props;

    const { homeAirport } = userState;

    this.state = {
      homeAirport, // just the text, don't save in Redux
    };
  }

  // componentDidMount() {
  // }

  handleAirportField = (homeAirport) => {
    // homeAirport can be null...
    this.setState({ homeAirport });
  };

  handleSubmitAirport = (homeAirport) => {
    const { updateHomeAirport } = this.props;
    // homeAirport won't be null b/c of the input validation
    updateHomeAirport(homeAirport);
  };

  handleSubmitDisplayName = (name) => {
    const { updateDisplayName } = this.props;
    // validation here?
    // console.log('Display name:', name);
    updateDisplayName(name);
  };

  render() {
    const { homeAirport } = this.state;
    const { userState } = this.props;

    const { userEmail, userName, userPhotoURL } = userState;

    return (
      <div className="account-user-info-container">
        <div className="user-profile-image-container">
          {userPhotoURL ? (
            <img
              alt={userName || 'Avatar'}
              className={userProfileImageClass}
              src={userPhotoURL}
            />
          ) : (
            <span className={userProfileImageClass}>
              {getPhotoURLAlternate({ email: userEmail, name: userName })}
            </span>
          )}
        </div>
        <div>
          <h2 className="welcome-message">
            {getWelcomeMessage({ userEmail, userName })}
          </h2>
        </div>
        <div className="fields">
          <div className="fields-row">
            <div className="field-name">Email</div>
            <div className="field-value no-edit">{userEmail}</div>
          </div>
          <div className="fields-row">
            <div className="field-name">Name</div>
            <div className="field-value">
              <InputWithEditIcon
                ariaLabel="name"
                onSave={this.handleSubmitDisplayName}
                validate={isValidUserName}
                validationMessage="Enter a name between 1 and 30 alphanumeric characters."
                value={userName || ''}
              />
            </div>
          </div>
          <div className="fields-row home-airport">
            <div className="field-name">Home Airport</div>
            <div className="field-value">
              <AirportAutocomplete
                inputMode="not-basic" // anything but basic
                inputProps={{
                  onSave: this.handleSubmitAirport,
                }}
                locality="from"
                onChange={this.handleAirportField}
                placeholder="Home Airport"
                value={homeAirport}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserInfo;
