import { is } from './typeUtils';

/**
 * @param {*} ref
 * @returns {null|Object}
 */
export const getRef = (ref) => {
  if (is.falsy(ref)) {
    return null;
  }

  if (ref.current) {
    return ref.current;
  }

  if (is.function(ref.focus)) {
    return ref;
  }

  return null;
};
