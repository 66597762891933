import React from 'react';
import '../../scss/components/_privacy_terms.scss';

/* eslint-disable arrow-body-style */
const PrivacyTerms = () => {
  return (
    <div className="app privacy-terms-container">
      <header className="bg-gradient">
        <h2>Terms of Use</h2>
        <p>
          Vegan unicorn squid waistcoat farm-to-table banjo photo booth
          messenger bag, chillwave wolf brunch pork belly paleo hashtag
          church-key. Hammock food truck vaporware live-edge, wolf kogi raclette
          trust fund etsy. Cloud bread shaman pour-over, butcher helvetica
          salvia raclette vegan lyft chartreuse man braid mumblecore tbh 3 wolf
          moon.
        </p>
        <div className="bg-wave-container">
          <div className="bg-wave-short" />
          <div className="bg-wave-long" />
        </div>
      </header>
      <main className="privacy-terms-main">
        <content className="privacy-terms-content">
          <h2>Section 1.</h2>
          <div className="privacy-terms-subsection">
            <h3>Literally flannel pug, cloud bread</h3>
            <p>
              Sustainable health goth shabby chic forage. Copper mug irony
              lumbersexual, poutine beard actually enamel pin mumblecore
              live-edge raclette migas flexitarian schlitz brooklyn lyft. Migas
              DIY gochujang, bushwick craft beer ennui hexagon church-key
              adaptogen la croix schlitz. Pitchfork cronut iceland yuccie.
              Cornhole slow-carb authentic, locavore humblebrag selvage normcore
              actually shabby chic readymade unicorn flannel. Pork belly ugh
              locavore fixie, viral helvetica vegan. Portland bicycle rights
              godard meditation snackwave listicle fingerstache brooklyn prism.
              Roof party church-key snackwave, neutra quinoa vegan street art
              pour-over enamel pin sartorial humblebrag direct trade typewriter
              wolf. Farm-to-table dreamcatcher drinking vinegar prism tumeric,
              adaptogen helvetica.
            </p>
            <p>
              Don’t show the other players with your position what you cards are
              telling you. Make sure to seat back and relax, don’t adopt
              positions that will make you move too much or feel uncomfortable
              during the time of the game. Some players in the tournaments show
              their game with the position they adopt while playing either
              putting the elbows on the table or resting in the bumper. 4) Do
              the most important thing in the brake.
            </p>
            <p>
              Fashion axe cray poke letterpress meggings banh mi hexagon la
              croix. Knausgaard fam kombucha kickstarter art party literally
              gluten-free readymade tofu YOLO. Iceland palo santo banjo, ethical
              poke intelligentsia offal tofu freegan. Mixtape bespoke readymade
              cronut la croix everyday carry tote bag.
            </p>
            <p>5) Also your words and mimic are important during the game.</p>
            <p>
              When ever you are playing don’t show your cards to the other
              player in the way you talk or look, is important that they will
              have other impression, for example in your hand Is good you can
              try on making faces or saying words that show that your hand is
              not good, then people will think you have a bad game and you will
              surprise them at the end. This doesn’t work all the time so try to
              find different ways to hide your game. Also you can read the other
              player’s movements and words to see either they are lying or
              telling the truth in the hand.
            </p>
          </div>
          <div className="privacy-terms-subsection">
            <h3>Your words and mimic are important during the game.</h3>
            <p>
              Helvetica sartorial salvia wayfarers heirloom, cronut blog
              kickstarter kombucha stumptown 90’s ramps freegan chartreuse. Raw
              denim taiyaki four loko kale chips, pitchfork pour-over bitters
              air plant af fixie mumblecore tofu art party. You probably haven’t
              heard of them jean shorts before they sold out, fanny pack
              try-hard godard four dollar toast. Vaporware humblebrag fashion
              axe ethical stumptown quinoa live-edge gastropub. Mumblecore
              scenester tumblr affogato, keytar gentrify you probably haven’t
              heard of them cronut pork belly YOLO selvage cardigan. Tofu pug
              kickstarter, palo santo 3 wolf moon iPhone tbh etsy pabst selfies
              tote bag.
            </p>
          </div>
        </content>

        <aside className="privacy-terms-aside">
          <ul>
            <li>
              Some <a href="google.com">links</a>
            </li>
            <li>
              for <a href="google.com">your</a>
            </li>
            <li>
              <a href="google.com">Enjoyment</a>
            </li>
          </ul>
          <h4>Something to read</h4>
          <p>
            This doesn’t work all the time so try to find different ways to hide
            your game. Also you can read the other player’s movements and words
            to see either they are lying or telling the truth in the hand.
          </p>
        </aside>
      </main>
    </div>
  );
};

export default PrivacyTerms;
