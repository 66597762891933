export const replaceAll = (str, oldString, newString) => {
  let replaced = str;
  while (replaced.indexOf(oldString) > -1) {
    replaced = replaced.replace(new RegExp(oldString, 'g'), newString);
  }
  return replaced;
};

/**
 * @param {string} prefix - the namespace
 * @returns {Function} which will apply the namespace
 */
export const namespacer = (prefix) => (str, { joinString = '/' } = {}) =>
  [prefix, str].join(joinString);

/**
 * Helper function to get three namespaced constants
 * for API calls
 * @param {string} eventName
 * @param {string} namespace
 * @returns {Object} { init, fail, success }
 */
export const makeAPIConstants = (eventName, namespaceFunc) => ({
  init: namespaceFunc(`${eventName}_INIT`),
  success: namespaceFunc(`${eventName}_SUCCESS`),
  fail: namespaceFunc(`${eventName}_FAIL`),
});

/**
 * @param {*} x number, 1000.10
 * @returns {string} formatted int, 1,000
 */
export const formatDisplayInteger = (x) => {
  if (Number.isNaN(x) || Number.isNaN(parseInt(x, 10))) {
    return '0';
  }

  return parseInt(x, 10).toLocaleString();
};

/**
 * @example matchesRouteBase('/app', <matches>)
 *
 * Matches:
 * /app /app/ /app/somewhere-else
 *
 * Non-matches:
 * apps/ /apps /apps-are-great /somewhere/apps /app-this app/this app apps
 * @param {string} route without trailing slash
 * @param {string} item
 * @returns {boolean} true if match
 */
export const matchesRouteBase = (route, item) =>
  new RegExp(`^${route}((/.*$)|$)`, 'gm').test(item);

/**
 * @example matchesRoute('/app', <matches>)
 *
 * Matches:
 * /app /app/
 *
 * Non-matches:
 * /app/somewhere-else apps/ /apps /apps-are-great /somewhere/apps /app-this app/this app apps
 * @param {string} route without trailing slash
 * @param {string} item
 * @returns {boolean} true if match
 */
export const matchesRoute = (route, item) =>
  new RegExp(`^${route}/?$`, 'gm').test(item);
