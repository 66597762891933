import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { firebaseInit } from 'services/firebase';
import { attemptLogin } from 'modules/user/actions';
// import { userStatePropType } from 'utils/propTypes';

class FirebaseLogin extends Component {
  static propTypes = {
    onAttemptLogin: PropTypes.func.isRequired,
    // userState: userStatePropType.isRequired,
  };

  // static defaultProps = {};

  constructor(props) {
    super(props);
    firebaseInit();
  }

  componentDidMount() {
    setImmediate(() => {
      const { onAttemptLogin } = this.props;
      onAttemptLogin();
    });
  }

  /* eslint-disable react/sort-comp */

  render() {
    return null;
  }
}

// const mapStateToProps = (state) => ({
//   userState: state.user,
// });

const mapDispatchToProps = {
  onAttemptLogin: attemptLogin,
};

export default connect(null, mapDispatchToProps)(FirebaseLogin);
