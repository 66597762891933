/**
 * @typedef Option
 * @property {string} Option.key
 * @property {string} Option.text
 * @property {*} Option.value
 */

/**
 * @typedef {Option[]} Options - in expected UI order
 */

export class OptionData {
  constructor(options, initialSelection = 0) {
    // must be an array
    this.options = options;
    this.initialSelection = options[initialSelection];
    this.values = {};
    this.optionsMap = {};
    this.createMaps();
  }

  createMaps() {
    const values = {};
    this.optionsMap = this.options.reduce((acc, item) => {
      const { key, text, value } = item;
      acc[key] = { text, value };
      values[key] = value;
      return acc;
    }, {});
    this.values = values;
  }

  getText(key) {
    return this.optionsMap[key].text;
  }

  getValue(key) {
    return this.optionsMap[key].value;
  }
}
