import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import AppBar from '@material-ui/core/AppBar';
// import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';

import { locationPropType, windowStatePropType } from 'utils/propTypes';

import { routesArray } from './routesArray';

/**
 * @param {Object} link
 * @returns {string} shortened name for mobile, if present, or regular name
 */
const getName = (link, width) => {
  const { name, url } = link;

  if (width > 375) {
    return name;
  }

  const newName =
    routesArray.find(
      (route) => Boolean(route.shortName) && route.url === url
    ) || {};

  return newName.shortName || name;
};

class AccountNav extends Component {
  static propTypes = {
    location: locationPropType.isRequired,
    windowState: windowStatePropType.isRequired,
  };

  state = {
    activeURL: '',
  };

  componentDidMount() {
    this.setActiveLink();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: prevLocation } = prevProps;

    if (location.pathname !== prevLocation.pathname) {
      this.setActiveLink(location.pathname);
    }
  }

  setActiveLink = (activeURL) => {
    const { location } = this.props;

    this.setState({ activeURL: activeURL || location.pathname });
  };

  render() {
    const { activeURL } = this.state;
    const { windowState } = this.props;
    const windowWidth = windowState.width;

    return (
      <AppBar position="static" color="default" className="my-account-appbar">
        <Toolbar
          className={clsx(
            'my-account-toolbar',
            windowWidth <= 768 && 'centered'
          )}
          disableGutters
        >
          {routesArray.map((link) => {
            const { Icon, index, url } = link;

            return (
              <Link
                to={url}
                key={index}
                className={clsx(
                  'my-account-link',
                  url === activeURL && 'active'
                )}
              >
                <Icon className="my-account-nav-icon" />
                <span>{getName(link, windowWidth)}</span>
                <span className="my-account-underline" />
              </Link>
            );
          })}
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => ({
  windowState: state.window,
});

export default connect(mapStateToProps, null)(withRouter(AccountNav));
