import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';

import CreateAccount from 'components/Dialogs/CreateAccount';
import SimpleStorage from 'components/Shared/SimpleStorage';
import SnackbarMUI from 'components/Shared/SnackbarMUI';
import SelectMenu from 'components/Shared/SelectMenu';
import { Datepicker } from 'components/Shared/Datepicker';
import {
  DEFAULT_END,
  DEFAULT_START,
  getDatepickerType,
} from 'components/Shared/Datepicker/utils';

import { userProgramsToSet } from 'mock/mockAccountData';
import { openLoyaltyProgramSelector } from 'modules/modals';
import { isLoggedIn } from 'modules/user/utils';
import { fetchUserLocation } from 'services/fetchUserData';
import { dateToString, isBeforeToday } from 'utils/date';
import Icons from 'utils/Icons';
import { flightClassData, tripTypeData } from 'utils/forms/formData';
import {
  historyPropType,
  userStatePropType,
  windowStatePropType,
} from 'utils/propTypes';

import LoyaltyInput from 'components/Shared/Loyalty/LoyaltyInput';
import PassengerCount from '../PassengerCount';
import * as pcConstants from '../PassengerCount/constants';
import AirportAutocomplete from '../AirportAutocomplete';

import { getDerivedState } from './getDerivedState';
import {
  transformStateToData,
  transformStateToUrl,
  validateSearchForm,
} from './utils';

const { oneWay, roundTrip } = tripTypeData.values;

const accountStates = new Set(['guest', 'loggedIn', 'optedOutGuest']);

export class SearchForm extends Component {
  static displayName = 'SearchForm';

  static propTypes = {
    history: historyPropType.isRequired,
    openLPS: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    onHideSummary: PropTypes.func,
    userState: userStatePropType.isRequired,
    windowState: windowStatePropType.isRequired,
  };

  static defaultProps = {
    onHideSummary: () => {},
  };

  constructor(props) {
    super(props);

    const { userState } = this.props;

    this.state = {
      accountStatus: 'guest',
      flightClass: flightClassData.initialSelection.value,
      destination: null,
      hideSummary: false,
      loyalty: userProgramsToSet(userState),
      origin: null,
      passengers: {
        adults: 1,
        children: 0,
        infants: 0,
      },
      showCreateAccount: false, // handles modal
      swap: false,
      tripType: tripTypeData.initialSelection.value,
      // new
      date: DEFAULT_START,
      endDate: DEFAULT_END,
      startDate: DEFAULT_START,
      // dates: undefined, // -> old api
      validationError: false,
    };

    // SimpleStorage
    this.hydrated = false;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.search !== prevState._search) {
      return getDerivedState(nextProps);
    }
    return null;
  }

  componentDidMount() {
    this.handleOriginInit(); // Promise
    this.maybeChangeToLoggedIn();
  }

  componentDidUpdate(prevProps) {
    const { userState } = this.props;
    const wasLoggedIn = isLoggedIn(prevProps.userState);
    const isNowLoggedIn = isLoggedIn(userState);

    if (wasLoggedIn !== isNowLoggedIn) {
      this.maybeChangeToLoggedIn(isNowLoggedIn);

      if (!isNowLoggedIn) {
        // handle logout
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState(this.getNewStateWithStatus('guest'));
      }
    }
  }

  /**
   * Fetch from user location API
   * sets state with found origin if not found in local storage
   *  or it fetched faster than it took to hydrate from local storage
   * @returns {Promise}
   */
  handleOriginInit = async () => {
    try {
      const foundOrigin = await fetchUserLocation();
      const { origin } = this.state;
      if (!this.hydrated || origin === null) {
        this.setState({ origin: foundOrigin });
      }
    } catch (error) {
      // pass
    }
  };

  /** Only set state if isLoggedIn is true */
  maybeChangeToLoggedIn = (isNowLoggedIn = null) => {
    const { userState } = this.props;

    if (isNowLoggedIn === true || isLoggedIn(userState)) {
      this.setState(
        this.getNewStateWithStatus('loggedIn', {
          loyalty: userProgramsToSet(userState),
        })
      );
    }
  };

  getNewStateWithStatus = (accountStatus, newState) => {
    if (!accountStates.has(accountStatus)) {
      throw new Error(`Invalid Status: ${accountStatus}`);
    }
    return { accountStatus, ...newState };
  };

  handleSelectChange = (name) => (event) => {
    const { value } = event.target;
    if (name === 'tripType') {
      return this.setState({
        tripType: value,
        // don't clear out the dates
        // date: null,
        // endDate: null,
        // startDate: null,
      });
    }
    return this.setState({
      [name]: value,
    });
  };

  updateLoyalty = (data) => {
    this.setState({ loyalty: data });
  };

  updateOrigin = (data) => {
    this.setState({ origin: data });
  };

  updateDestination = (data) => {
    this.setState({ destination: data });
  };

  onDateChange = ({ date = null, startDate = null, endDate = null }) => {
    const { tripType } = this.state;
    if (tripType === roundTrip) {
      this.setState({ endDate, startDate });
    } else if (tripType === oneWay) {
      this.setState({ date });
    }
  };

  updatePassengerCount = (data) => {
    this.setState({ passengers: data });
  };

  switchLocations = () => {
    const { origin, destination } = this.state;
    if (destination || origin) {
      this.setState((state) => ({
        origin: state.destination,
        destination: state.origin,
        swap: !state.swap,
      }));
    }
  };

  handleErrorPopup = (error = false) => {
    // always get a fresh error by setting error to false first
    this.setState({ validationError: false }, () => {
      // eslint-disable-next-line no-unused-expressions
      error && this.setState({ validationError: error });
    });
  };

  /**
   * Handles side effects from invalid search
   * @param {Object} data
   * @param {Array} data.loyalty - must be transformed from Set to Array
   * @returns {boolean} true if valid, false otherwise
   */
  validateSearchForm = ({ dates, destination, loyalty, origin, tripType }) => {
    const { error, message } = validateSearchForm({
      dates,
      destination,
      loyalty,
      origin,
      tripType,
    });

    if (!error) {
      return true;
    }

    if (error === 'loyalty') {
      const { openLPS } = this.props;
      openLPS();
    }

    this.handleErrorPopup(message);
    return false;
  };

  submitSearchForm = () => {
    const { accountStatus } = this.state;

    const searchData = transformStateToData(this.state);

    if (!this.validateSearchForm(searchData)) {
      return;
    }

    if (accountStatus === 'guest') {
      // show the CreateAccount dialog
      this.setState({ showCreateAccount: true });
      return;
    }

    const { onHideSummary, onSearch } = this.props;
    this.setState({ hideSummary: false });
    onSearch(searchData);
    onHideSummary(false);
  };

  hideSummary = () => {
    const { onHideSummary } = this.props;
    this.setState({ hideSummary: true });
    onHideSummary(true);

    // Removed the custom Event file with WindowScroll object.
    // TODO: revisit this.
    // WindowScroll.go(0);
    window.scrollTo(0, 0);
  };

  handleCreateAccount = (status) => (route) => {
    if (status === 'optedOutGuest') {
      this.setState(
        this.getNewStateWithStatus(status, { showCreateAccount: false }),
        () => {
          // show flight results
          this.submitSearchForm();
        }
      );
    } else if (
      status === 'optedInGuest' &&
      ['/signup', '/login'].includes(route)
    ) {
      const { history } = this.props;
      this.setState({ showCreateAccount: false });
      // state is serialized and passed into router state
      history.push(route, transformStateToUrl(this.state));
    }
  };

  onParentStateHydrated = () => {
    const { date, startDate, tripType } = this.state;
    const dateKeyLookup = {
      [roundTrip]: 'startDate',
      [oneWay]: 'date',
    };
    let invalidStartDate = true;

    if (tripType === roundTrip) {
      invalidStartDate = isBeforeToday(startDate);
    } else {
      invalidStartDate = isBeforeToday(date);
    }

    if (invalidStartDate) {
      const key = dateKeyLookup[tripType];
      this.setState({ [key]: DEFAULT_START, endDate: DEFAULT_END });
    }
    // else these are converted to moment by DatePicker

    this.hydrated = true;
  };

  render() {
    const {
      date,
      // dates,
      destination,
      endDate,
      flightClass = flightClassData.initialSelection.value,
      hideSummary,
      invalidDate,
      invalidLocation,
      invalidSameLocation,
      origin,
      passengers,
      showCreateAccount,
      startDate,
      swap,
      tripType,
      validationError,
    } = this.state;
    const { loaded, loading, results, windowState } = this.props;
    // const dates = { endDate, startDate };
    let summaryShowed = false;
    let summary = null;

    if (
      (loaded || loading || (results || []).length > 0) &&
      origin &&
      destination &&
      passengers &&
      !hideSummary
    ) {
      summaryShowed = true;
      summary = (
        <div className="search-summary">
          <div onClick={this.hideSummary}>
            <span>
              {origin.iata} - {destination.iata}
            </span>
            <i>
              {date && tripType === oneWay && dateToString(date)}
              {startDate && tripType === roundTrip && dateToString(startDate)}
              {endDate &&
                tripType === roundTrip &&
                ` - ${dateToString(endDate)}`}
            </i>
            <b>{Icons.UserFriends}</b>
            <u>
              {passengers.adults + passengers.children + passengers.infants}
            </u>
          </div>
        </div>
      );
    }

    return (
      <div className="search-form-container">
        {summary}
        <SimpleStorage
          blacklist={[
            'accountStatus',
            'loyalty',
            'showCreateAccount',
            'validationError',
          ]}
          // blacklist={['loyalty', 'origin', 'startDate', 'endDate', 'date']}
          onParentStateHydrated={this.onParentStateHydrated}
          parent={this}
          prefix={SearchForm.displayName}
        />
        {showCreateAccount && (
          <CreateAccount
            onAccept={this.handleCreateAccount('optedInGuest')}
            onCancel={this.handleCreateAccount('optedOutGuest')}
          />
        )}
        <div
          className={`form homepage-search${
            summaryShowed ? ' summary-shown' : ''
          }`}
        >
          <SnackbarMUI
            className="searchform-validation-error"
            message={validationError}
            onClose={this.handleErrorPopup}
            open={Boolean(validationError)}
          />
          <div className="flight-options">
            <SelectMenu
              className="flight-options-select"
              onChange={this.handleSelectChange('tripType')}
              options={tripTypeData.options}
              value={tripType}
            />
            {windowState.width >= pcConstants.MOBILE_DISPLAY_WIDTH ? (
              <Fragment>
                <PassengerCount
                  // desktop props only!
                  // display: none at a certain media query (699)
                  onChange={this.updatePassengerCount}
                  {...passengers}
                />
                <SelectMenu
                  // This is hidden with JS, not CSS
                  className="flight-options-select flight-options-select-cabin"
                  onChange={this.handleSelectChange('flightClass')}
                  options={flightClassData.options}
                  value={flightClass}
                />
              </Fragment>
            ) : null}
            <div className="search-form-loyalty-programs">
              <LoyaltyInput onChange={this.updateLoyalty} />
            </div>
          </div>
          <div className="row">
            <div
              className={`location fields${
                invalidLocation || invalidSameLocation ? ' invalid' : ''
              }`}
            >
              <div className="locations">
                <div className="airport">
                  <b className="icon">{Icons.FlightTakeOff}</b>
                  <AirportAutocomplete
                    // icon={Icons.FlightTakeOff}
                    locality="from"
                    onChange={this.updateOrigin}
                    placeholder="From"
                    value={origin}
                  />
                </div>
                <span
                  className={`switch ${swap ? 'right' : 'left'}`}
                  onClick={this.switchLocations}
                >
                  &nbsp;
                  {Icons.SwitchArrows}
                </span>
                <div className="airport">
                  <b className="icon">{Icons.FlightLanding}</b>
                  <AirportAutocomplete
                    // icon={Icons.FlightLanding}
                    locality="to"
                    onChange={this.updateDestination}
                    placeholder="To"
                    value={destination}
                  />
                </div>
              </div>
            </div>

            <div className={`calendar fields${invalidDate ? ' invalid' : ''}`}>
              <Datepicker
                date={date}
                endDate={endDate}
                startDate={startDate}
                onDateChange={this.onDateChange}
                onDatesChange={this.onDateChange}
                type={getDatepickerType({ ...windowState, tripType })}
              />
            </div>
            <PassengerCount
              // This version displays the cabin if windowWidth < that constant
              mobile
              flightClass={flightClass}
              onChange={this.updatePassengerCount}
              onChangeFlightClass={this.handleSelectChange('flightClass')}
              {...passengers}
            />
            <div className="search-submit-button-inline-wrapper">
              <Fab
                className="search-submit-button-icon"
                onClick={this.submitSearchForm}
              >
                <SearchIcon />
              </Fab>
            </div>
          </div>
          <div className="search-submit-button-wrapper">
            <Button
              className="search-submit-button"
              onClick={this.submitSearchForm}
            >
              Search
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userState: state.user,
  windowState: state.window,
});

const mapDispatchToProps = { openLPS: openLoyaltyProgramSelector };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchForm));
