import { tripTypeData } from 'utils/forms/formData';
import { createUrlParams } from 'utils/urlParams';

const { oneWay, roundTrip } = tripTypeData.values;

export const transformStateToData = (state) => {
  const {
    date,
    destination,
    endDate,
    flightClass,
    loyalty,
    origin,
    passengers,
    startDate,
    tripType,
  } = state;
  const dates = { date, endDate, startDate };
  return {
    dates,
    destination,
    flightClass,
    loyalty: Array.from(loyalty),
    origin,
    passengers,
    tripType,
  };
};

export const transformStateToUrl = (state) => {
  return createUrlParams(transformStateToData(state));
};

/**
 * @typedef {Object} ValidationError
 * @property {string} ValidationError.error one of 'dates'|'loyalty'|'location'
 * @property {string} ValidationError.message
 */

export const ERRORS = {};

/** @type ValidationError */
ERRORS.locationMissing = {
  error: 'location',
  message: 'Please enter both an origin and destination.',
};

/** @type ValidationError */
ERRORS.locationsSame = {
  error: 'location',
  message: 'Origin and destination cannot be the same.',
};

/** @type ValidationError */
ERRORS.dateMissingRoundTrip = {
  error: 'dates',
  message: 'Please enter a start and end date.',
};

/** @type ValidationError */
ERRORS.dateMissingOneWay = { error: 'dates', message: 'Please enter a date.' };

/** @type ValidationError */
ERRORS.loyalty = {
  error: 'loyalty',
  message: 'Please select at least one loyalty program.',
};

/**
 * @param {Object} data
 * @param {number[]} data.loyalty - must be transformed from Set to Array
 * @returns {ValidationError|Object} ValidationError or empty object if no errors
 */
export const validateSearchForm = ({
  dates,
  destination,
  loyalty,
  origin,
  tripType,
}) => {
  if (!(origin && destination)) {
    return ERRORS.locationMissing;
  }

  if (origin.iata === destination.iata) {
    return ERRORS.locationsSame;
  }

  if (tripType === roundTrip) {
    if (!(dates && dates.startDate && dates.endDate)) {
      return ERRORS.dateMissingRoundTrip;
    }
  } else if (tripType === oneWay) {
    if (!(dates && dates.date)) {
      return ERRORS.dateMissingOneWay;
    }
  }

  if (!Array.isArray(loyalty) || loyalty.length < 1) {
    return ERRORS.loyalty;
  }
  return {};
};
