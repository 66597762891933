import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

import { LOGIN, ROOT, SIGNUP } from 'components/App/routeConstants';

import { isLoggedIn as isLoggedInCheck } from 'modules/user/utils';
import { logoutUser as logoutUserAction } from 'modules/user/actions';
import { historyPropType, userStatePropType } from 'utils/propTypes';

import ErrorDialog from 'components/Dialogs/ErrorDialog';
import LinkButton from 'components/Shared/LinkButtonMUI';

import UserProfileDropdown from './UserProfileDropdown';

const btnClassName = 'account-menu-desktop-guest-btn';

class AccountMenuDesktop extends Component {
  static propTypes = {
    className: PropTypes.string,
    history: historyPropType.isRequired,
    logoutUser: PropTypes.func.isRequired,
    userState: userStatePropType.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      errorDialogContent: [null, null],
      errorDialogOpen: false,
    };
  }

  /* eslint-disable react/sort-comp */

  handleLogout = async () => {
    try {
      const { history, logoutUser } = this.props;
      await logoutUser();
      history.push(ROOT);
    } catch (error) {
      this.setState({
        errorDialogOpen: true,
        errorDialogContent: [
          'Error: Log Out Unsuccessful.',
          ['Log out operation failed.'],
        ],
      });
    }
  };

  openOrCloseDialogHandler = (stateKey, open = false, callback = () => {}) => {
    return () => {
      this.setState({ [stateKey]: open }, callback);
    };
  };

  render() {
    const { errorDialogContent, errorDialogOpen } = this.state;
    const { className, userState } = this.props;

    const [errorTitle, errorContent] = errorDialogContent;

    const isLoggedIn = isLoggedInCheck(userState);

    return (
      <div className={clsx('account-menu-desktop', className)}>
        {isLoggedIn ? (
          <UserProfileDropdown
            handleLogout={this.handleLogout}
            userState={userState}
          />
        ) : (
          <Fragment>
            <LinkButton className={btnClassName} to={SIGNUP}>
              Sign Up
            </LinkButton>
            <LinkButton className={btnClassName} to={LOGIN}>
              Log In
            </LinkButton>
          </Fragment>
        )}
        {errorDialogOpen && (
          <ErrorDialog
            content={errorContent}
            title={errorTitle}
            onClose={this.openOrCloseDialogHandler('errorDialogOpen', false)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userState: state.user,
});

const mapDispatchToProps = {
  logoutUser: logoutUserAction,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountMenuDesktop)
);
