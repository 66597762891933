import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

import SimpleStorage from 'components/Shared/SimpleStorage';
import { KeyCodes, isKey } from 'utils/KeyCodes';
import { windowStatePropType } from 'utils/propTypes';

import PassengerCountMobile from './PassengerCountMobile';
import PassengerCountInputButton from './PassengerCountInputButton';
import { MOBILE_DISPLAY_WIDTH, PASSENGER_TYPES } from './constants';
import {
  initQty,
  getTotal,
  makeQtyChoice,
  renderPassengerTypeButtons,
  updateQty,
  updateTempQty,
  validate,
} from './utils';

class PassengerCount extends Component {
  static displayName = 'PassengerCount';

  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    adults: PropTypes.number,
    children: PropTypes.number,
    infants: PropTypes.number,
    /* eslint-enable react/no-unused-prop-types */
    flightClass: PropTypes.string,
    mobile: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onChangeFlightClass: PropTypes.func,
    windowState: windowStatePropType.isRequired,
  };

  static defaultProps = {
    adults: -1,
    children: -1,
    infants: -1,
    flightClass: undefined,
    mobile: false,
    onChangeFlightClass: () => {},
  };

  constructor(props) {
    super(props);

    const initValues = {
      adults: 1,
      children: 0,
      infants: 0,
    };

    this.state = {
      ...initValues,
      count: 1,
      error: '',
      popoverAnchorEl: null,
      temp: {
        ...initValues,
      },
    };

    this.hydrated = false;
  }

  componentDidUpdate(prevProps) {
    this.hydrateFromLocalStorage(prevProps);
  }

  /**
   * This runs _once_ on componentDidUpdate because local storage update
   * arrives _after_ component mounts.
   * @param {Object} prevProps
   * @returns {undefined} sets state and sets this.hydrated to true
   */
  hydrateFromLocalStorage = (prevProps) => {
    const shouldCheck = PASSENGER_TYPES.some((type) => {
      const prev = prevProps[type];
      // eslint-disable-next-line react/destructuring-assignment
      const cur = this.props[type];
      return cur !== prev;
    });

    if (this.hydrated === false && shouldCheck) {
      const initQuantities = initQty(this.props);
      this.setState({
        ...initQuantities,
        temp: initQuantities,
        count: getTotal(initQuantities),
      });
      this.hydrated = true;
    }
  };

  setPopoverAnchorEl = (evt) => {
    const popoverAnchorEl = evt === null ? null : evt.currentTarget;
    this.setState({ popoverAnchorEl });
  };

  handlePopoverClose = () => this.setPopoverAnchorEl(null);

  handleKeyDown = (evt) => {
    const { popoverAnchorEl } = this.state;
    if (
      !popoverAnchorEl &&
      (isKey(evt, KeyCodes.TAB) || isKey(evt, KeyCodes.ENTER))
    ) {
      this.setPopoverAnchorEl(evt);
    }
  };

  makeQtyChoice = () => {
    const { temp } = this.state;
    const { onChange } = this.props;
    this.handlePopoverClose();
    this.setState(makeQtyChoice);
    onChange(temp);
  };

  updateTempQty = () => this.setState(updateTempQty);

  /**
   * @param {Event} [event]
   */
  cancel = () => {
    this.handlePopoverClose();
    this.updateTempQty();
    this.setState({ error: '' }); // clear error if cancelled mobile
  };

  validate = () => {
    const { error, temp } = this.state;
    const newError = validate({ ...temp });
    if (newError !== error) {
      this.setState({ error: newError });
    }
  };

  /**
   * Update passenger count on button click
   * @param {string} type one of PASSENGER_TYPES
   * @param {number} [qty=1] If included, it has to be -1
   * @throws {Error} if type is not one of PASSENGER_TYPES
   * @returns {function} normal 'event' callback
   */
  updateQty = (type, qty = 1) => () => {
    if (!PASSENGER_TYPES.includes(type)) {
      throw new Error(`Type must be one of ${PASSENGER_TYPES.join(',')}`);
    }
    if (![1, -1].includes(qty)) {
      throw new Error(`qty must be one 1 or -1.`);
    }
    this.setState(updateQty(type, qty), this.validate);
  };

  renderErrorOrSubmit = (error) => {
    return error.length > 0 ? (
      <div className="passenger-count-validation-error">{error}</div>
    ) : (
      <div className="passenger-count-submit-buttons">
        <Button color="inherit" onClick={this.cancel}>
          Cancel
        </Button>
        <Button color="primary" onClick={this.makeQtyChoice}>
          OK
        </Button>
      </div>
    );
  };

  render() {
    const {
      flightClass,
      onChangeFlightClass,
      mobile,
      windowState,
    } = this.props;
    const { count, error, popoverAnchorEl: anchorEl, temp } = this.state;

    const open = Boolean(anchorEl);
    const id = open ? 'passenger-count-popover' : undefined;

    return (
      <div
        className={clsx('passenger-count', mobile && 'passenger-count-mobile')}
      >
        <SimpleStorage
          blacklist={['error', 'popoverAnchorEl', 'temp']}
          // onParentStateHydrated={this.onParentStateHydrated}
          parent={this}
          prefix={PassengerCount.displayName}
        />
        {windowState.width < MOBILE_DISPLAY_WIDTH ? (
          <PassengerCountMobile
            error={error}
            flightClass={flightClass || ''}
            onCancel={this.cancel}
            onChangeFlightClass={onChangeFlightClass}
            onSave={this.makeQtyChoice}
            tempState={temp}
            trigger={({ onOpen }) => {
              return (
                <PassengerCountInputButton
                  count={count}
                  flightClass={flightClass}
                  id={id}
                  onClick={onOpen}
                  onKeyDown={onOpen}
                />
              );
            }}
            updateQty={this.updateQty}
          />
        ) : (
          // PassengerCountDesktop
          <Fragment>
            <PassengerCountInputButton
              count={count}
              id={id}
              onClick={this.setPopoverAnchorEl}
              onKeyDown={this.handleKeyDown}
            />
            <Popover
              anchorEl={anchorEl}
              className="passenger-count-desktop-popover"
              id={id}
              onClose={this.handleClose}
              open={open}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div className="passenger-count-content">
                {renderPassengerTypeButtons(temp, this.updateQty)}
                {this.renderErrorOrSubmit(error)}
              </div>
            </Popover>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  windowState: state.window,
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(PassengerCount);
