import React, { Component } from 'react';
import Icons from 'utils/Icons';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="homepage-footer">
        <div>
          <div className="content">
            <img
              className="logo"
              src="/img/logo-white.svg"
              alt="Treklee Logo"
            />
            <a href="/about">About</a>
            <a href="/faq">FAQ</a>
            <a href="/blog">Blog</a>
            <a href="/terms">Terms</a>
            <br />
            <a href="https://www.facebook.com/TrekleeTravel/" className="icon">
              {Icons.Facebook}
            </a>
            <a href="https://twitter.com/treklee_travel" className="icon">
              {Icons.Instagram}
            </a>
            <a
              href="https://www.instagram.com/treklee_travel/"
              className="icon"
            >
              {Icons.Twitter}
            </a>
            <a href="mailto:team@treklee.com" className="icon">
              {Icons.Email}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
