import React, { Component } from 'react';

import { Formik, Field, Form } from 'formik';
import { TextField as FormikTextField } from 'formik-material-ui';
import { LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { firebaseSendPasswordResetEmail } from 'services/firebase';
import { assignError, getEmailError } from 'utils/forms/Formik';

const initialValues = { email: '' };

const INITIAL_STATE = {
  passwordResetMessage: false,
};

class PasswordReset extends Component {
  // static propTypes = {};

  // static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }

  clearMessage = () => {
    const { passwordResetMessage } = this.state;
    if (passwordResetMessage) {
      this.setState({ passwordResetMessage: false });
    }
  };

  sendPasswordResetEmail = (emailValue) =>
    firebaseSendPasswordResetEmail(emailValue)
      .then(() => {
        this.setState({ passwordResetMessage: ['Email sent successfully!'] });
        return { error: false };
      })
      .catch(() => {
        this.setState({
          passwordResetMessage: [
            'Unable to send email. Please try again.',
            'error',
          ],
        });
        return { error: true };
      });

  render() {
    const { passwordResetMessage } = this.state;
    return (
      <div className="signup-container">
        <h1>Forgot your password?</h1>
        <h2>
          Enter the email associated with your account and we&#39;ll email you a
          link to reset your password.
        </h2>
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const { email } = values;
            const errors = {};
            assignError(errors, 'email', getEmailError(email));
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setImmediate(() => {
              this.sendPasswordResetEmail(values.email).then((res) => {
                if (res && !res.error) {
                  resetForm();
                }
                setSubmitting(false);
              });
            });
          }}
        >
          {({ dirty, isSubmitting, submitForm }) => (
            <Form className="settings-form">
              <Field
                autoComplete="email"
                // autoFocus
                component={FormikTextField}
                fullWidth
                label="Email"
                margin="dense"
                name="email"
                type="email"
              />
              {isSubmitting && <LinearProgress />}
              {(!dirty && passwordResetMessage) ||
              (passwordResetMessage && passwordResetMessage[1] === 'error') ? (
                <div className="">{passwordResetMessage[0]}</div>
              ) : null}
              <Button
                className="btn-dialog-full-width btn-dialog-email-signup btn-dialog-email-signup-submit"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
                variant="contained"
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default PasswordReset;
