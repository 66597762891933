import React from 'react';
import PropTypes from 'prop-types';

import Icons from 'utils/Icons';

export const AirportResultsDropdown = ({ onClick, results, selectedIndex }) => {
  if (!Array.isArray(results)) return [];
  if (results.length === 0) {
    return (
      <div className="airport">
        <b>{Icons.Airplane}</b>
        No matching airports
      </div>
    );
  }
  return results.map((airport, i) => {
    const { displayName, iata, name } = airport;
    return (
      <div
        index={i}
        key={`${iata}-${displayName || name}`}
        className={`airport${i === selectedIndex ? ' selected' : ''}`}
        onClick={onClick}
      >
        <b>{Icons.Airplane}</b>
        {displayName} <span>{iata}</span>
      </div>
    );
  });
};

AirportResultsDropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      iata: PropTypes.string,
    }),
  ),
  selectedIndex: PropTypes.number.isRequired,
};

AirportResultsDropdown.defaultProps = {
  results: [],
};
