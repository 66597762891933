import React, { Component } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { addEvent, removeEvent } from 'utils/browser';
import { dispatchWindowResize as windowResize } from 'modules/window';

class WindowResize extends Component {
  static propTypes = {
    dispatchWindowResize: func.isRequired,
  };

  componentDidMount() {
    const { dispatchWindowResize } = this.props;
    dispatchWindowResize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
    addEvent(window, 'resize', this.handleResize);
  }

  componentWillUnmount() {
    removeEvent(window, 'resize', this.handleResize);
  }

  handleResize = event => {
    debounce(() => {
      const { dispatchWindowResize } = this.props;
      const { innerHeight: height, innerWidth: width } = event.target;
      dispatchWindowResize({ height, width });
    }, 300)();
  };

  render() {
    return <div className="hidden" />;
  }
}

// const mapStateToProps = state => ({
//   userState: state.user,
// });

const mapDispatchToProps = { dispatchWindowResize: windowResize };

export default connect(null, mapDispatchToProps)(WindowResize);
