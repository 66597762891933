import { createConfig } from 'services/config';
import { ENDPOINTS } from 'services/endpoints';
import { requests } from 'services/requests';

/**
 * @typedef {Object} AccountDto - see Swagger
 * @description Account is synonymous with user loyalty program.
 */
/** @typedef {Object} LoyaltyProgramDto - see Swagger */
/** @typedef {Object} TrekleeUserDto - see Swagger */

/*
 * GET Find closest to user
 *   {{AIRPORTS_URL}}/airports/closest
 * Determines the closest airport to the user's location based on the
 * metadata provided in an empty GET request.
 * If no airport is found, a 404 status will be returned.
 * See the examples for exact response structure.
 */
export const fetchUserLocation = () =>
  requests.get({ url: ENDPOINTS.userLocation });

/**
 * @param {string} token
 * @returns {Promise<TrekleeUserDto>}
 */
export const getUserData = (token) =>
  requests.get({ config: createConfig({ token }), url: ENDPOINTS.users });

/**
 * @param {string} token
 * @param {Object} data must include id
 * @returns {Promise<TrekleeUserDto>}
 */
export const updateUserData = (token, data) =>
  requests.put({
    config: createConfig({ token }),
    data,
    url: `${ENDPOINTS.users}/${data.id}`,
  });

/**
 * @param {string} token
 * @returns {Promise<AccountDto[]>}
 */
export const getUserLoyaltyPrograms = (token) =>
  requests.get({ config: createConfig({ token }), url: ENDPOINTS.accounts });

/**
 * Handle saving array of programs
 * @param {string} token
 * @param {AccountDto[]} data
 * @returns {Promise<AccountDto[]>}
 */
export const saveUserLoyaltyPrograms = (token, data) =>
  requests.post({
    config: createConfig({ token }),
    data,
    url: ENDPOINTS.accountsBatch,
  });

/**
 * Handle updating array of programs
 * @param {string} token
 * @param {AccountDto[]} data
 * @returns {Promise<AccountDto[] | Error>}
 */
export const updateUserLoyaltyPrograms = (token, data) => {
  // validate that each data obj has `id`
  const invalid = data.some((obj) => typeof obj.id !== 'string');
  if (invalid) {
    return Promise.reject(
      new Error('Expected all programs to have `id` specified.')
    );
  }
  return saveUserLoyaltyPrograms(token, data);
};

/**
 * @param {string} token
 * @param {string[]} ids ex: [ "6f1de7d6dc6678266f8d60d6" ]
 * @returns {Promise[]} response 202, 400, 401
 */
export const deleteUserLoyaltyPrograms = (token, ids) => {
  return Promise.all(
    ids.map((id) => {
      return requests.delete({
        config: createConfig({ token }),
        url: `${ENDPOINTS.accounts}/${id}`,
      });
    })
  );
};
