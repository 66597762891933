import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import omit from 'lodash/omit';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Fab from '@material-ui/core/Fab';

import { historyPropType, locationPropType } from 'utils/propTypes';
import { matchesRoute } from 'utils/stringUtils';

const nameToComp = {
  Button,
  ButtonBase,
  Fab,
};

const LinkButton = (props) => {
  const {
    activeClassName,
    className,
    history,
    location,
    // match,
    muiVariation,
    onClick,
    // staticContext,
    to,
    ...rest
  } = props;

  const Btn = nameToComp[muiVariation];

  return (
    <Btn
      className={clsx(
        className,
        matchesRoute(to, location.pathname) && activeClassName
      )}
      // `children` is just another prop!
      {...omit(rest, ['match', 'staticContext'])}
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
        history.push(to);
      }}
    />
  );
};

LinkButton.propTypes = {
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  history: historyPropType.isRequired,
  location: locationPropType.isRequired,
  muiVariation: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
};

LinkButton.defaultProps = {
  activeClassName: undefined,
  className: undefined,
  muiVariation: 'Button',
  onClick: undefined,
};

export default withRouter(LinkButton);
