import { formatDisplayInteger } from 'utils/stringUtils';

const mockAirport = { shortDisplayName: 'Asheville (AVL)', iata: 'AVL' };

export const mockSearches = [
  {
    endDate: new Date('Jan 3 2020'),
    startDate: new Date('Dec 20 2019'),
  },
  {
    endDate: new Date('Jan 10 2020'),
    startDate: new Date('Jan 3 2020'),
  },
  {
    endDate: new Date('Feb 10 2020'),
    startDate: new Date('Feb 3 2020'),
  },
  {
    endDate: new Date('Jan 3 2020'),
    startDate: new Date('Dec 20 2019'),
  },
  {
    endDate: new Date('Jan 10 2020'),
    startDate: new Date('Jan 3 2020'),
  },
  {
    endDate: new Date('Feb 10 2020'),
    startDate: new Date('Feb 3 2020'),
  },
].map((item, id) => {
  /* eslint-disable no-param-reassign */
  item.cabin = 'Economy';
  item.id = id;
  item.url = '/';
  item.destination = { iata: 'TYO', shortDisplayName: 'Tokyo (TYO)' };
  item.origin = { iata: 'SFO', shortDisplayName: 'San Francisco (SFO)' };
  /* eslint-enable no-param-reassign */
  return item;
});

const mockPrograms = [
  {
    displayName: 'Air Canada Aeroplan',
    id: 1,
    points: 1000,
    status: 'GOLD',
  },
  {
    displayName: 'Lufthansa Miles & More',
    id: 2,
    points: 2100,
    status: 'GOLD',
  },
];

export const userProgramsToSet = (userState) => {
  const { programs } = userState;
  if (!programs) return new Set();
  return new Set(programs.map((program) => program.id));
};

export const MOCK_STATUSES = {
  GOLD: 'GOLD',
  PLATINUM: 'PLATINUM',
  PLATINUM_PRO: 'PLATINUM_PRO',
  EXECUTIVE_PLATINUM: 'EXECUTIVE_PLATINUM',
};

export const mockProgramsStatuses = [
  { text: 'Gold', value: MOCK_STATUSES.GOLD },
  { text: 'Platinum', value: MOCK_STATUSES.PLATINUM },
  { text: 'Platinum Pro', value: MOCK_STATUSES.PLATINUM_PRO },
  { text: 'Executive Platinum', value: MOCK_STATUSES.EXECUTIVE_PLATINUM },
];

export const MOCK_USER = {
  homeAirport: mockAirport,
  // programs: [],
  programs: mockPrograms,
  searchHistory: mockSearches,
  userName: 'Mock User',
  userEmail: 'camelot.ultra@spam.gov',
  // userPhotoURL: undefined,
};

export const getTotalPoints = (programs) => {
  const pointsTotal = programs.reduce(
    (total, program) => total + program.points,
    0
  );
  return formatDisplayInteger(pointsTotal);
};

export const mergeMockWithUserData = (userData) =>
  Object.assign({}, MOCK_USER, userData);
// Object.assign({}, userData, MOCK_USER);
