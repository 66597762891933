import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Typography from '@material-ui/core/Typography';

// import { withStyles } from '@material-ui/core/styles';

// const closeButtonStyles = {
//   common: {
//     position: 'absolute',
//     top: 8,
//   },
//   desktop: {
//     right: 0
//   },
//   mobile: {
//     left: 0,
//     // right: theme.spacing(1),
//     // color: theme.palette.grey[500],
//   },
// };

const CloseButton = props => {
  const { onClose } = props;
  return (
    <Fragment>
      <IconButton
        aria-label="close"
        className="dialog-title-close-btn-mobile"
        onClick={onClose}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        aria-label="close"
        className="dialog-title-close-btn"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Fragment>
  );
};

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const DialogTitleWithCloseButton = props => {
  const { onClose, subtitle, title, ...other } = props;
  return (
    <DialogTitle disableTypography {...other}>
      <CloseButton onClose={onClose} />
      <Typography variant="h4">{title}</Typography>
      {subtitle && (
        <div className="subtitle">
          <Typography variant="h6">{subtitle}</Typography>
        </div>
      )}
    </DialogTitle>
  );
};

DialogTitleWithCloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

DialogTitleWithCloseButton.defaultProps = {
  subtitle: undefined,
};

export default DialogTitleWithCloseButton;
