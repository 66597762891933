import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';

const styles = {
  card: {
    // margin: '0 2px',
    // maxWidth: 300,
    // width: '25%',
    padding: 6,
    boxSizing: 'border-box',
  },
  media: {
    // width:
    height: 210,
  },
  content: {
    padding: '8px 13px 0 13px',
    // marginBottom: -2,
  },
  actions: {
    padding: '0 8px',
    marginTop: 'auto',
  },
};

const DestinationItem = withStyles(styles)(props => {
  const {
    classes,
    device,
    flightClass,
    image,
    loyalty,
    name,
    points,
    price,
  } = props;

  const cardClass = clsx(
    classes.card,
    'destination-item',
    device && `destination-${device}`,
  );
  return (
    <Card className={cardClass}>
      <CardMedia
        className={classes.media}
        image={image}
        title={`Destination ${name}`}
      />
      <CardContent className={classes.content}>
        <h3>{name}</h3>
        <div>
          <span>{flightClass}</span>
        </div>
        <div className="destination-price-points">
          <span>${price.toLocaleString()}</span>
          <span>
            {points.toLocaleString()} {loyalty} points
          </span>
        </div>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button size="small" color="primary" href="#">
          Search flights
        </Button>
      </CardActions>
    </Card>
  );
});

DestinationItem.displayName = 'DestinationItem';
DestinationItem.propTypes = {
  device: PropTypes.oneOf(['mobile', 'tablet']),
};

const getDeviceByIdx = idx => {
  if (idx >= 6) {
    return 'tablet';
  }
  if (idx >= 4) {
    return 'mobile';
  }
  return undefined;
};

export const Destinations = props => {
  const { destinations } = props;
  return (
    <div className="destination-items-container">
      {destinations.slice(0, 8).map((item, idx) => {
        const device = getDeviceByIdx(idx);
        return <DestinationItem key={item.id} {...item} device={device} />;
      })}
    </div>
  );
};

Destinations.propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.shape({})),
};
Destinations.defaultProps = {
  destinations: [],
};
