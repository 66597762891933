import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';

const MenuLink = (props) => {
  const { children, childrenProps, linkProps, menuProps } = props;

  const wrappedChildren = <span {...childrenProps}>{children}</span>;

  return (
    <MenuItem {...menuProps}>
      {linkProps ? (
        <NavLink {...linkProps}>{wrappedChildren}</NavLink>
      ) : (
        wrappedChildren
      )}
    </MenuItem>
  );
};

MenuLink.propTypes = {
  children: PropTypes.node.isRequired,
  childrenProps: PropTypes.shape({}),
  linkProps: PropTypes.shape({}),
  menuProps: PropTypes.shape({}),
};

MenuLink.defaultProps = {
  childrenProps: undefined,
  linkProps: undefined,
  menuProps: undefined,
};

export default MenuLink;
