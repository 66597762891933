export const MIN = 0;
export const MIN_ADULT = 1;
export const MAX = 8;
export const PASSENGER_TYPES_MAP = {
  adults: { name: 'Adults' },
  children: { info: '1-16 years', name: 'Children' },
  infants: { info: 'Under 1', name: 'Infants' },
};
export const PASSENGER_TYPES = Object.keys(PASSENGER_TYPES_MAP);

export const MOBILE_DISPLAY_WIDTH = 460;
