import { getType } from 'utils/typeUtils';

/**
 * Update airport and _value if props.value doesn't equal ._value:
 *   1. both are null or undefined
 *   2. both are objects (compare by string)
 *   3. both are different types
 * @param {*} nextProps
 * @param {*} prevState
 * @returns {object|null}
 */
export function getDerivedStateFromProps(nextProps, prevState) {
  const { airport: prevValue } = prevState;
  const { value } = nextProps;

  // case 1
  if (!value && !prevValue) {
    return null;
  }

  // case 2
  if (getType(value) === 'object' && getType(prevValue) === 'object') {
    const { displayName: prevDisplayName } = prevValue;
    const { displayName } = value;
    if (prevDisplayName !== displayName) {
      return { airportCache: value, airport: value };
    }
  }

  // case 3
  return { airportCache: value, airport: value };
}
