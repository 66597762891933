import { func, instanceOf, oneOf, oneOfType, string } from 'prop-types';
import moment from 'moment';
import { END_DATE, MOMENT_FORMAT, START_DATE } from '../constants';

// a string is acceptable only if coming from hydrated local storage
const momentOrString = oneOfType([instanceOf(moment), string]);

const date = momentOrString;
const endDate = momentOrString;
const focusedInput = oneOf([END_DATE, START_DATE]);
const startDate = momentOrString;
const onDateChange = func;
const onDatesChange = func;
const onFocusChange = func;
const type = oneOf([
  'DesktopRange',
  'DesktopSingle',
  'MobileRange',
  'MobileSingle',
]).isRequired;

export const DEFAULT_START = moment()
  .startOf('day')
  .add(1, 'month');
export const DEFAULT_END = moment()
  .startOf('day')
  .add(1, 'month')
  .add(1, 'week');

export const SHARED_PROPS = {
  displayFormat: MOMENT_FORMAT,
  hideKeyboardShortcutsPanel: true,
  initialVisibleMonth: () => moment(),
  noBorder: true,
  // noBorder: false,
  // block: false,
  // keepOpenOnDateSelect: true,
  // showClearDate: true, // single only
  // showDefaultInputIcon: false, // default
  // customInputIcon: PropTypes.node,
  // customCloseIcon: PropTypes.node,
  // anchorDirection: PropTypes.oneOf([ANCHOR_LEFT, ANCHOR_RIGHT]), <- 'left' or 'right'
  // calendarInfoPosition="top"
  // showClearDates // range only
  // calendarInfoPosition="top"
  // renderCalendarInfo={this.calendarInfoPanel}
  readOnly: true,
};

export const SHARED_DESKTOP_PROPS = {
  anchorDirection: 'right', // this will need to be tweaked with different screen widths
  numberOfMonths: 2,
};

export const propTypesDatepicker = {
  Datepicker: {
    date,
    endDate,
    onDateChange,
    onDatesChange,
    startDate,
    type,
  },
  MobileRange: {
    // daySize: PropTypes.number,
    // initialVisibleMonth: PropTypes.func,
    // phrases: PropTypes.object,
    endDate,
    focusedInput,
    onDatesChange,
    onFocusChange,
    startDate,
  },
  MobileSingle: {
    // daySize: PropTypes.number,
    // initialVisibleMonth: PropTypes.func,
    // phrases: PropTypes.object,
    date,
    onDateChange,
    onFocusChange,
  },
};

export const defaultPropsDatepicker = {
  Datepicker: {
    // type: 'DesktopRange',
    // date: DEFAULT_START,
    // startDate: DEFAULT_START,
    // endDate: DEFAULT_END,
    date: null,
    startDate: null,
    endDate: null,
    onDateChange: () => {},
    onDatesChange: () => {},
  },
};
