import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Range, Slider, Chbx } from './Search';
import CollapseFilterPanel from './Search/CollapseFilterPanel';

export const defaultValue = {
  nonstop: true,
  stop1: true,
  stop2: true,
  points: true,
  cash: true,
  both: true,
  'outbound-time': [0, 24],
  'return-time': [0, 24],

  duration: -1,
  price: [-1, -1],
  pricePoints: [-1, -1],
  airlines: [],
  programs: [],
};

class Filters extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    filterRanges: PropTypes.shape({
      duration: PropTypes.arrayOf(PropTypes.number),
      airlines: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.number,
        }),
      ),
    }),
    defaultValue: PropTypes.shape({
      nonstop: PropTypes.bool,
      stop1: PropTypes.bool,
      stop2: PropTypes.bool,
      points: PropTypes.bool,
      cash: PropTypes.bool,
      both: PropTypes.bool,
      'outbound-time': PropTypes.arrayOf(PropTypes.number),
      'return-time': PropTypes.arrayOf(PropTypes.number),
      price: PropTypes.arrayOf(PropTypes.number),
      pricePoints: PropTypes.arrayOf(PropTypes.number),
      duration: PropTypes.number,
    }),
    loaded: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onChange: () => {},
    defaultValue,
    filterRanges: {
      airlines: [],
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      value: defaultValue,
      moreAwards: false,
      moreAirlines: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = {};
    if (nextProps.filterRanges !== prevState.filterRanges) {
      state.filterRanges = nextProps.filterRanges;
      state.airlines = {};

      state.value = { ...defaultValue };

      nextProps.filterRanges.airlines.forEach(data => {
        state.airlines[data.id] = true;
        state.value.airlines.push(data.id);
      });

      // Preselected Loyalty will store all loyalty programs selected
      const preselectLoyalty = {};
      let overrideLoyalty = false;
      (nextProps.search.loyalty || []).forEach(loyaltyId => {
        preselectLoyalty[loyaltyId] = true;
        overrideLoyalty = true;
      });

      state.programs = {};
      const selectedPrograms = [];
      const unselectedPrograms = [];
      (nextProps.filterRanges.programs || []).forEach(data => {
        // If at least one program is selected run filters base on selection
        if (overrideLoyalty) {
          if (preselectLoyalty[data.id]) {
            state.programs[data.id] = true;
            state.value.programs.push(data.id);
            selectedPrograms.push(data);
          } else {
            unselectedPrograms.push(data);
          }
        } else {
          // In case of no selection select all programs (array comes empty)
          state.programs[data.id] = true;
          state.value.programs.push(data.id);
          selectedPrograms.push(data);
        }
      });
      nextProps.filterRanges.programs = selectedPrograms.concat(
        unselectedPrograms,
      );

      state.moreAwards = false;
      state.moreAirlines = false;
    }

    return state;
  }

  // Toggle Value for checkbox Stops category
  chbxUpdate = name => () => {
    // /console.log(name, value);
    const { onChange } = this;
    this.setState(state => {
      const value = { ...state.value };
      value[name] = !value[name];
      onChange(value);
      return { value };
    });
  };

  // Resets Duration Filter
  resetDuration = () => {
    const { state } = this;
    const value = { ...state.value };
    value.duration = -1;
    this.onChange(value);
    this.setState({ value });
  };

  // Resets Stops Values to All Selected
  resetStops = () => {
    const { state } = this;
    const value = { ...state.value };
    value.nonstop = true;
    value.stop1 = true;
    value.stop2 = true;
    this.onChange(value);
    this.setState({ value });
  };

  // Reset time both return and outbond from 0 to 24h
  resetTime = () => {
    const { state } = this;
    const value = { ...state.value };
    value['return-time'] = [0, 24];
    value['outbound-time'] = [0, 24];
    this.onChange(value);
    this.setState({ value });
  };

  // Reset pricing both for USD and Points
  resetPrice = () => {
    const { state } = this;
    const value = { ...state.value };
    value.pricePoints = [-1, -1];
    value.price = [-1, -1];
    this.onChange(value);
    this.setState({ value });
  };

  // Fire onChange Callback when a value of a filter has updated
  onChange = value => {
    const { onChange } = this.props;
    onChange({ ...value });
  };

  // Toggle the value of an airline in filters list
  airlineUpdate = data => () => {
    const { onChange } = this;
    const { value } = this.state;
    let { airlines } = this.state;
    airlines = { ...airlines };
    airlines[data.id] = !airlines[data.id];

    const selectedAirlines = [];
    const airlinesIds = Object.keys(airlines);
    airlinesIds.forEach(id => {
      if (airlines[id]) {
        selectedAirlines.push(Number(id));
      }
    });

    onChange({ ...value, airlines: selectedAirlines });
    this.setState({
      airlines,
      value: { ...value, airlines: selectedAirlines },
    });
  };

  // Toggle the value of a Loyalty
  awardsUpdate = data => () => {
    const { onChange } = this;
    const { value } = this.state;
    let { programs } = this.state;
    programs = { ...programs };
    programs[data.id] = !programs[data.id];

    const selectedPrograms = [];
    const programsIds = Object.keys(programs);
    programsIds.forEach(id => {
      if (programs[id]) {
        selectedPrograms.push(Number(id));
      }
    });

    onChange({ ...value, programs: selectedPrograms });
    this.setState({
      programs,
      value: { ...value, programs: selectedPrograms },
    });
  };

  // Callback when the value of a slider has change and update the state
  sliderUpdate = name => range => {
    const { onChange } = this;
    this.setState(state => {
      const value = { ...state.value };
      value[name] = range;
      onChange(value);
      return { value };
    });
  };

  // Format Money amounts in string
  formatMoney = amount => `$${amount.toLocaleString()}`;

  // Format Points in string
  formatPoints = (amount, index) =>
    `${amount.toLocaleString()} ${index === 1 ? 'points' : ''}`;

  // Format time in hours in 12am/12pm format
  formatTime = time => {
    if (time === 24) {
      return '12am';
    }
    if (time === 0) {
      return '12am';
    }
    let hour = time % 12;
    hour = hour === 0 ? 12 : hour;
    return hour + (time >= 12 ? 'pm' : 'am');
  };

  // Format duration to hours, time is 30min per unit so 4 is 4/ 30min which translates to 2am
  formatDuration = time => {
    /** This will require some logging to understand */
    const { filterRanges } = this.props;
    if (time === filterRanges.duration[1] * 2) {
      return 'any';
    }
    const hours = Math.floor(time / 2);
    const minutes = time % 2 === 1 ? '30m' : '00m';
    return `Under ${hours}h ${minutes}`;
  };

  // Selects an award and unselects the rest of it
  awardsUnique = awardId => {
    const { onChange } = this;
    const { value } = this.state;
    let { programs } = this.state;
    programs = { ...programs };

    Object.keys(programs).forEach(id => {
      programs[id] = awardId === Number(id);
    });

    onChange({ ...value, programs: [awardId] });
    this.setState({ programs, value: { ...value, programs: [awardId] } });
  };

  // Selects an airline and unselect the rest of it
  airlineUnique = airlineId => {
    const { onChange } = this;
    const { value } = this.state;
    let { airlines } = this.state;
    airlines = { ...airlines };

    for (const property in airlines) {
      airlines[property] = airlineId === Number(property);
    }

    onChange({ ...value, airlines: [airlineId] });
    this.setState({ airlines, value: { ...value, airlines: [airlineId] } });
  };

  // Selects all awards
  resetAward = event => {
    if (event.nativeEvent.type === 'keydown') {
      if (event.nativeEvent.keyCode !== 13) {
        return false;
      }
    }

    const { onChange } = this;
    const { value } = this.state;
    let { programs } = this.state;
    programs = { ...programs };

    const selectedPrograms = [];
    for (const property in programs) {
      selectedPrograms.push(property);
      programs[property] = true;
    }

    onChange({ ...value, programs: selectedPrograms });
    this.setState({
      programs,
      value: { ...value, programs: selectedPrograms },
    });

    return true;
  };

  // Selects all airlines
  resetAirline = event => {
    if (event.nativeEvent.type === 'keydown') {
      if (event.nativeEvent.keyCode !== 13) {
        return false;
      }
    }

    const { onChange } = this;
    const { value } = this.state;
    let { airlines } = this.state;
    airlines = { ...airlines };

    const selectedAirlines = [];
    for (const property in airlines) {
      selectedAirlines.push(property);
      airlines[property] = true;
    }

    onChange({ ...value, airlines: selectedAirlines });
    this.setState({
      airlines,
      value: { ...value, airlines: selectedAirlines },
    });

    return true;
  };

  // Selects on type of stop unselects the reset
  stopUnique = label => {
    const { value } = this.state;
    const newValue = { ...value };
    if (label === 'Nonstop') {
      newValue.nonstop = true;
      newValue.stop1 = false;
      newValue.stop2 = false;
    }
    if (label === '1 Stop') {
      newValue.nonstop = false;
      newValue.stop1 = true;
      newValue.stop2 = false;
    }
    if (label === '2+ Stops') {
      newValue.nonstop = false;
      newValue.stop1 = false;
      newValue.stop2 = true;
    }
    this.setState({ value: newValue });
    this.onChange(newValue);
  };

  // Sets Filters to Default State
  onResetFilters = () => {
    const { state } = this;
    const value = { ...state.value };
    value.nonstop = true;
    value.stop1 = true;
    value.stop2 = true;
    value.duration = -1;
    value['return-time'] = [0, 24];
    value['outbound-time'] = [0, 24];
    value.pricePoints = [-1, -1];
    value.price = [-1, -1];

    let { programs } = this.state;
    programs = { ...programs };

    const selectedPrograms = [];
    for (const property in programs) {
      selectedPrograms.push(property);
      programs[property] = true;
    }
    value.programs = selectedPrograms;

    let { airlines } = this.state;
    airlines = { ...airlines };

    const selectedAirlines = [];
    for (const property in airlines) {
      selectedAirlines.push(property);
      airlines[property] = true;
    }
    value.airlines = selectedAirlines;

    this.onChange(value);
    this.setState({ value });
  };

  // Expands Awards Panel
  expandAwards = () => this.setState({ moreAwards: true });

  // Collapse Awards Panel
  collpaseAwards = () => this.setState({ moreAwards: false });

  // Expand Airlines Panel
  expandAirlines = () => this.setState({ moreAirlines: true });

  // Collapse Airlines Panel
  collpaseAirlines = () => this.setState({ moreAirlines: false });

  render() {
    const { loaded } = this.props;
    const { filterRanges, error, results } = this.props;
    const { value, airlines, programs, moreAwards, moreAirlines } = this.state;
    const { nonstop, stop1, stop2 } = value;

    let allAirlineSelected = true;

    const showFilters = loaded || (results || []).length > 0;
    // const airlinesChbx = filterRanges.airlines.filter(data => data.only).map(data => {
    const airlinesChbx = filterRanges.airlines.map(data => {
      if (!airlines[data.id]) {
        allAirlineSelected = false;
      }
      // console.log(data);
      return (
        <Chbx
          key={data.id}
          label={data.name}
          id={data.id}
          onUnique={this.airlineUnique}
          checked={airlines[data.id] || false}
          onChange={this.airlineUpdate(data)}
        />
      );
    });

    let allProgramsSelected = true;
    const awardsChbx = (filterRanges.programs || []).map(data => {
      if (!programs[data.id]) {
        allProgramsSelected = false;
      }
      return (
        <Chbx
          key={data.id}
          label={`${data.displayName}`}
          id={data.id}
          onUnique={this.awardsUnique}
          checked={programs[data.id] || false}
          onChange={this.awardsUpdate(data)}
        />
      );
    });
    /**
     * Not entirely sure what's going on with:
     * <div className="progress-loading a" style={{ width: '70%' }} />
     */
    return (
      <div
        className={`search-filter-holder${!loaded ? ' disabled' : ''}`}
        style={{ display: error ? 'none' : '' }}
      >
        <div className="search-filter-container">
          <div
            className="splash"
            style={{ display: !showFilters ? '' : 'none' }}
          >
            <div className="progress-loading a" style={{ width: '70%' }} />
            <div className="progress-loading a" style={{ width: '55%' }} />
            <div className="progress-loading a" style={{ width: '30%' }} />
            <div className="progress-loading b" style={{ width: '80%' }} />
            <div className="progress-loading line" />
            <div className="progress-loading b" style={{ width: '50%' }} />
            <div className="progress-loading b" style={{ width: '60%' }} />
            <div className="progress-loading c" style={{ width: '80%' }} />
            <div className="progress-loading b" style={{ width: '70%' }} />
            <div className="progress-loading line" />
            <div className="progress-loading a" style={{ width: '60%' }} />
            <div className="progress-loading a" style={{ width: '60%' }} />
            <div className="progress-loading b" style={{ width: '80%' }} />
            <div className="progress-loading line" />
            <div className="progress-loading b" style={{ width: '80%' }} />
            <div className="progress-loading b" style={{ width: '50%' }} />
            <div className="progress-loading c" style={{ width: '70%' }} />
            <div className="progress-loading c" style={{ width: '90%' }} />
          </div>
          <div style={{ display: showFilters ? '' : 'none' }}>
            <CollapseFilterPanel title="Loyalty Programs" defaultOpen>
              <span
                className="reset"
                role="button"
                tabIndex={0}
                onClick={this.resetAward}
                onKeyDown={this.resetAward}
                style={{ display: allProgramsSelected ? 'none' : '' }}
              >
                Reset
              </span>
              {awardsChbx.slice(0, 5)}
              <button
                type="button"
                className="link"
                style={{
                  display:
                    moreAwards && filterRanges.programs.length > 5
                      ? 'none'
                      : '',
                }}
                onClick={this.expandAwards}
              >
                View more programs
              </button>
              <div style={{ display: moreAwards ? '' : 'none' }}>
                {awardsChbx.slice(5)}
              </div>
              <button
                type="button"
                className="link"
                style={{ display: moreAwards ? '' : 'none' }}
                onClick={this.collpaseAwards}
              >
                View Less
              </button>
            </CollapseFilterPanel>
            <CollapseFilterPanel title="Price" defaultOpen>
              <span
                className="reset"
                role="button"
                tabIndex={0}
                onClick={this.resetPrice}
                onKeyDown={this.resetPrice}
                style={{
                  display:
                    (value.price[0] === -1 ||
                      value.price[0] === filterRanges.price[0]) &&
                    (value.price[1] === -1 ||
                      value.price[1] === filterRanges.price[1]) &&
                    (value.pricePoints[0] === -1 ||
                      value.pricePoints[0] === filterRanges.pricePoints[0]) &&
                    (value.pricePoints[1] === -1 ||
                      value.pricePoints[1] === filterRanges.pricePoints[1])
                      ? 'none'
                      : '',
                }}
              >
                Reset
              </span>
              <Range
                min={filterRanges.price[0]}
                max={filterRanges.price[1]}
                allowCross={false}
                defaultValue={value.price}
                format={this.formatMoney}
                onChange={this.sliderUpdate('price')}
              />
              {value.programs && value.programs.length > 1 && (
                <Range
                  min={filterRanges.pricePoints[0]}
                  max={filterRanges.pricePoints[1]}
                  allowCross={false}
                  defaultValue={value.pricePoints}
                  format={this.formatPoints}
                  onChange={this.sliderUpdate('pricePoints')}
                />
              )}
            </CollapseFilterPanel>
            <CollapseFilterPanel title="Stops" defaultOpen>
              <span
                className="reset"
                role="button"
                tabIndex={0}
                onClick={this.resetStops}
                onKeyDown={this.resetStops}
                style={{ display: nonstop && stop1 && stop2 ? 'none' : '' }}
              >
                Reset
              </span>
              <Chbx
                label="Nonstop"
                id="Nonstop"
                checked={nonstop}
                onChange={this.chbxUpdate('nonstop')}
                onUnique={this.stopUnique}
              />
              <Chbx
                label="1 Stop"
                id="1 Stop"
                checked={stop1}
                onChange={this.chbxUpdate('stop1')}
                onUnique={this.stopUnique}
              />
              <Chbx
                label="2+ Stops"
                id="2+ Stops"
                checked={stop2}
                onChange={this.chbxUpdate('stop2')}
                onUnique={this.stopUnique}
              />
            </CollapseFilterPanel>
            <CollapseFilterPanel title="Duration" defaultOpen>
              <span
                className="reset"
                role="button"
                tabIndex={0}
                onClick={this.resetDuration}
                onKeyDown={this.resetDuration}
                style={{ display: value.duration === -1 ? 'none' : '' }}
              >
                Reset
              </span>
              <Slider
                min={filterRanges.duration[0] * 2}
                max={filterRanges.duration[1] * 2}
                allowCross={false}
                defaultValue={value.duration}
                format={this.formatDuration}
                onChange={this.sliderUpdate('duration')}
              />
            </CollapseFilterPanel>
            <CollapseFilterPanel title="Times" defaultOpen>
              <span
                className="reset"
                role="button"
                tabIndex={0}
                onClick={this.resetTime}
                onKeyDown={this.resetTime}
                style={{
                  display:
                    (value['return-time'][0] === -1 ||
                      value['return-time'][0] === 0) &&
                    (value['return-time'][1] === -1 ||
                      value['return-time'][1] === 24) &&
                    (value['outbound-time'][0] === -1 ||
                      value['outbound-time'][0] === 0) &&
                    (value['outbound-time'][1] === -1 ||
                      value['outbound-time'][1] === 24)
                      ? 'none'
                      : '',
                }}
              >
                Reset
              </span>
              <div className="filter-label">Outbound</div>
              <Range
                min={0}
                max={24}
                allowCross={false}
                defaultValue={value['outbound-time']}
                format={this.formatTime}
                onChange={this.sliderUpdate('outbound-time')}
              />
              <div className="filter-label">Return</div>
              <Range
                min={0}
                max={24}
                allowCross={false}
                defaultValue={value['return-time']}
                format={this.formatTime}
                onChange={this.sliderUpdate('return-time')}
              />
            </CollapseFilterPanel>
            <CollapseFilterPanel title="Airlines" defaultOpen>
              <span
                className="reset"
                role="button"
                tabIndex={0}
                onClick={this.resetAirline}
                onKeyDown={this.resetAirline}
                style={{ display: allAirlineSelected ? 'none' : '' }}
              >
                Reset
              </span>
              {airlinesChbx.slice(0, 5)}
              <button
                type="button"
                className="link"
                style={{
                  display:
                    moreAirlines && airlinesChbx.length > 5 ? 'none' : '',
                }}
                onClick={this.expandAirlines}
              >
                View more airlines
              </button>
              <div style={{ display: moreAirlines ? '' : 'none' }}>
                {airlinesChbx.slice(5)}
              </div>
              <button
                type="button"
                className="link"
                style={{ display: moreAirlines ? '' : 'none' }}
                onClick={this.collpaseAirlines}
              >
                View Less
              </button>
            </CollapseFilterPanel>
          </div>
        </div>
      </div>
    );
  }
}

export default Filters;
