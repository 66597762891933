import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import AccountMenuDesktop from 'components/Account/AccountMenu/AccountMenuDesktop';
import AccountMenuMobile from 'components/Account/AccountMenu/AccountMenuMobile';
import LogoLink from 'components/Shared/LogoLink';

const NavBar = () => {
  return (
    <div className="header-container">
      <AppBar className="navbar-appbar" position="relative">
        <Toolbar className="toolbar" disableGutters>
          <AccountMenuMobile />
          <LogoLink />
          <AccountMenuDesktop />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
