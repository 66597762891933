import objectHas from 'has';

export const addEvent = (obj, evt, fn) => {
  if (obj.addEventListener) {
    obj.addEventListener(evt, fn, false);
  } else if (obj.attachEvent) {
    obj.attachEvent(`on${evt}`, fn);
  }
};

export const removeEvent = (obj, evt, fn) => {
  if (obj.removeEventListener) {
    obj.removeEventListener(evt, fn, false);
  } else if (obj.detachEvent) {
    obj.detachEvent(`on${evt}`, fn);
  }
};
const addEventSimple = addEvent;
const removeEventSimple = removeEvent;

export const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export const Browser = {};

Browser.nodeParse = (node, scope) => {
  const { children } = node;
  for (let iChild = 0; iChild < children.length; iChild += 1) {
    if (children[iChild].getAttribute('var')) {
      const names = children[iChild].getAttribute('var').split('.');
      let obj = scope;
      while (names.length > 0) {
        const _property = names.shift();
        if (names.length === 0) {
          obj[_property] = children[iChild];
        } else {
          if (!objectHas(obj, _property)) {
            obj[_property] = {};
          }
          obj = obj[_property];
        }
      }
    }
    Browser.nodeParse(children[iChild], scope);
  }
};

Browser.DOM = (html, scope) => {
  let node;
  if (html.constructor === String) {
    node = document.createElement('div');
    node.innerHTML = html;
  } else {
    node = html;
  }

  const _scope = scope || {};

  const toScope = (node, scope) => {
    const { children } = node;
    for (let iChild = 0; iChild < children.length; iChild += 1) {
      if (children[iChild].getAttribute('var')) {
        const names = children[iChild].getAttribute('var').split('.');
        let obj = scope;
        while (names.length > 0) {
          const _property = names.shift();
          if (names.length === 0) {
            obj[_property] = children[iChild];
          } else {
            if (!objectHas(obj, _property)) {
              obj[_property] = {};
            }
            obj = obj[_property];
          }
        }
      }
      toScope(children[iChild], scope);
    }
  };
  toScope(node, _scope);

  if (html.constructor !== String) {
    return false;
  }

  if (node.childNodes.length === 1) {
    if (!scope) {
      node.childNodes[0].nodes = _scope;
    }
    return node.childNodes[0];
  }

  const fragment = document.createDocumentFragment();
  const children = node.childNodes;
  while (children.length > 0) {
    fragment.append(children[0]);
  }
  fragment.nodes = _scope;
  return fragment;
};

Browser.getSize = () => {
  let myWidth = 0;

  let myHeight = 0;
  if (typeof window.innerWidth === 'number') {
    // Non-IE
    myWidth = window.innerWidth;
    myHeight = window.innerHeight;
  } else if (
    document.documentElement &&
    (document.documentElement.clientWidth ||
      document.documentElement.clientHeight)
  ) {
    // IE 6+ in 'standards compliant mode'
    myWidth = document.documentElement.clientWidth;
    myHeight = document.documentElement.clientHeight;
  } else if (
    document.body &&
    (document.body.clientWidth || document.body.clientHeight)
  ) {
    // IE 4 compatible
    myWidth = document.body.clientWidth;
    myHeight = document.body.clientHeight;
  }
  return { width: myWidth, height: myHeight };
};

Browser.UI = {};

Browser.UI.popupize = (button, popup, open_classname) => {
  button.popup = {};
  button.popup.mouseDownFN = function (event) {
    let { target } = event;
    while (target) {
      if (target === button || target === popup) {
        return true;
      }
      target = target.parentNode;
    }
    popup.style.display = 'none';
    open_classname && button.classList.remove(open_classname);
    button.popup.onClose && button.popup.onClose();
    removeEventSimple(document, 'mousedown', button.popup.mouseDownFN);
    removeEventSimple(document, 'touchstart', button.popup.mouseDownFN);
  };
  button.popup.close = function () {
    popup.style.display = 'none';
    open_classname && button.classList.remove(open_classname);
    button.popup.onClose && button.popup.onClose();
    removeEventSimple(document, 'mousedown', button.popup.mouseDownFN);
    removeEventSimple(document, 'touchstart', button.popup.mouseDownFN);
  };
  button.popup.toggle = function (event) {
    let { target } = event;
    while (target) {
      if (target === popup) {
        return true;
      }
      target = target.parentNode;
    }
    popup.style.display = popup.style.display === 'none' ? '' : 'none';
    if (popup.style.display === '') {
      if (open_classname && !button.classList.contains(open_classname)) {
        open_classname && button.classList.add(open_classname);
      }
      button.popup.onOpen && button.popup.onOpen();
      addEventSimple(document, 'mousedown', button.popup.mouseDownFN);
      addEventSimple(document, 'touchstart', button.popup.mouseDownFN);
    } else {
      open_classname && button.classList.remove(open_classname);
      button.popup.onClose && button.popup.onClose();
      removeEventSimple(document, 'mousedown', button.popup.mouseDownFN);
      removeEventSimple(document, 'touchstart', button.popup.mouseDownFN);
    }
  };
  button.onclick = button.popup.toggle;
};
