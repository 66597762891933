import React from 'react';
import produce from 'immer';

import {
  MIN,
  MIN_ADULT,
  MAX,
  PASSENGER_TYPES,
  PASSENGER_TYPES_MAP,
} from './constants';
import PassengerTypeButtons from './PassengerTypeButtons';

/* eslint-disable no-param-reassign */

/** component prevents qty from being anything but 1 or -1 */
export const updateQty = (type, qty) =>
  produce((state) => {
    const min = type === 'adults' ? MIN_ADULT : MIN;
    state.temp[type] = Math.max(min, Math.min(MAX, state.temp[type] + qty));
  });

export const getTotal = (passengersObj) => {
  const { adults, children, infants } = passengersObj;
  return adults + children + infants;
};

export const makeQtyChoice = produce((state) => {
  const { temp } = state;
  return { ...state, ...temp, count: getTotal(temp) };
});

export const updateTempQty = produce((state) => {
  const { adults, children, infants } = state;
  return {
    temp: {
      adults,
      children,
      infants,
    },
  };
});

export const validate = ({ adults, children, infants }) => {
  if (adults + children + infants > 16) {
    return 'You must have fewer than 17 total passengers.';
  }
  if (children + infants > 8) {
    return 'You must have fewer than 9 total child and infant passengers.';
  }
  if (infants > adults) {
    return 'You must have at least one adult per infant.';
  }
  return '';
};

/**
 * return object with initialized values if they're valid.
 * @param {Object} passengersObj props
 * @returns {Object}
 */
export const initQty = (passengersObj) => {
  return PASSENGER_TYPES.reduce((acc, key) => {
    const value = Number(passengersObj[key]);

    if (!Number.isNaN(value) && value > -1) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export const renderPassengerTypeButtons = (temp, onUpdateQty) =>
  PASSENGER_TYPES.map((type) => {
    const { info, name } = PASSENGER_TYPES_MAP[type];
    return (
      <PassengerTypeButtons
        info={info}
        key={name}
        name={name}
        type={type}
        updateQty={onUpdateQty}
        value={temp[type]}
      />
    );
  });
