import React from 'react';
import { Route } from 'react-router-dom';

import RoutesWithNavWrapper from 'components/Shared/RoutesWithNavWrapper';

import GoalsAndTrips from '../GoalsAndTrips';
import LoyaltyPrograms from '../LoyaltyPrograms';
import Recommendations from '../Recommendations';
import AccountPage from '../AccountPage';

import AccountNav from './AccountNav';
import { routesArray } from './routesArray';

const nameToCompMap = {
  Account: AccountPage,
  'Loyalty Programs': LoyaltyPrograms,
  Trips: GoalsAndTrips,
  'Credit Cards': Recommendations,
};

const AccountRoutes = () => (
  <RoutesWithNavWrapper className="account-pages-container" Navbar={AccountNav}>
    {routesArray.map((item) => {
      return (
        <Route
          key={item.index}
          path={item.url}
          exact
          component={nameToCompMap[item.name]}
        />
      );
    })}
  </RoutesWithNavWrapper>
);

export default AccountRoutes;
