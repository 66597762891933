import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import NavBar from 'components/NavBar';
import SearchLoader from 'components/Screens/SearchLoader';
import FirebaseLogin from 'components/Shared/FirebaseLogin';

import { fetchLoyaltyPrograms as fetchLoyaltyProgramsAction } from 'modules/loyalty/actions';
import { windowStatePropType } from 'utils/propTypes';

import Routes from './Routes';
import WindowResize from './WindowResize';

class App extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     fetching: false,
  //   };
  // }

  componentDidMount() {
    const { fetchLoyaltyPrograms } = this.props;
    fetchLoyaltyPrograms();
  }

  render() {
    const {
      windowState: { device },
    } = this.props;

    return (
      <div className={device === 'desktop' ? '' : 'mobile-device'}>
        <WindowResize />
        <SearchLoader />
        <FirebaseLogin />
        <NavBar />
        <Routes />
      </div>
    );
  }
}

App.propTypes = {
  fetchLoyaltyPrograms: PropTypes.func.isRequired,
  windowState: windowStatePropType.isRequired,
};

// App.defaultProps = {};

const mapStateToProps = (state) => ({
  userState: state.user,
  windowState: state.window,
});

const mapDispatchToProps = {
  fetchLoyaltyPrograms: fetchLoyaltyProgramsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
