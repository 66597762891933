import React from 'react';
// import PropTypes from 'prop-types';
// import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import Home from 'components/Screens/Home';
import Search from 'components/Screens/Search';
import AccountScreen from 'components/Screens/AccountScreen';
import PrivacyTerms from 'components/Screens/PrivacyTerms';
import PasswordReset from 'components/Account/PasswordReset';
import Login from 'components/Account/LoginSignup/Login';
import Signup from 'components/Account/LoginSignup/Signup';

import { isLoggedIn } from 'modules/user/utils';
import { userStatePropType } from 'utils/propTypes';

import {
  ACCOUNT,
  FORGOT_PASSWORD,
  HOME,
  LOGIN,
  PRIVACY,
  ROOT,
  SEARCH,
  SIGNUP,
} from './routeConstants';

/**
 * Top level routes
 */
const Routes = (props) => {
  const { userState } = props;

  return (
    <Switch>
      <Route path={ROOT} exact component={Home} />
      <Route path={HOME} exact component={Home} />
      <Route path={LOGIN} exact component={Login} />
      <Route path={SIGNUP} exact component={Signup} />
      <Route path={SEARCH} component={Search} />
      <Route path={FORGOT_PASSWORD} exact component={PasswordReset} />
      {isLoggedIn(userState) && (
        <Route path={ACCOUNT} component={AccountScreen} />
      )}
      <Route path={PRIVACY} component={PrivacyTerms} />
      <Redirect to={ROOT} />
    </Switch>
  );
};

Routes.propTypes = {
  userState: userStatePropType,
};

Routes.defaultProps = {
  userState: {
    userEmail: undefined,
  },
};

const mapStateToProps = (state) => ({
  userState: state.user,
});

export default connect(mapStateToProps, null)(Routes);
