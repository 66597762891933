import { groupBy } from 'utils/dataUtils';

/**
 * Categorize programs by type
 * @param {Object[]} data
 * @returns {Object} {airline: [...], hotel: [...], creditCard: [...]}
 */
export const getCategorizedPrograms = (data) => groupBy(data, (x) => x.type);

// for now, this only handles the `selected` prop
export const getPropsDiff = (prevProps, props) => {
  const { selected } = props;
  const newState = {};
  if (prevProps.selected !== selected) {
    newState.selected = selected;
  }
  return Object.keys(newState).length > 0 ? newState : false;
};

export const matchesSearchFilter = ({ data, searchTerm }) => {
  const searchRegExp = new RegExp(searchTerm, 'gim');
  return (
    searchTerm === '' ||
    (data.displayName || data.programName || '').search(searchRegExp) > -1
  );
};

// const categories = ['airline', 'hotel', 'creditCard'];

export function filterData({ programData, searchFilter, searchTerm }) {
  /** If there's no search term, only show popular programs */
  if (searchTerm === '') {
    return programData.filter((data) => data.popular === true);
  }
  return programData.filter((data) => searchFilter({ data, searchTerm }));
}

/**
 * Get object with arrays of transformed (optional) program data
 * Key is only defined if the array length > 0.
 * @param {object} config
 * @param {object} config.state { programs: {Object[]}, search: {String} }
 * @param {function} config.searchFilter
 * @param {function} [config.transform]
 * @returns {Object[]} filtered & transformed programs
 */
export function getFilteredProgramData({ searchFilter, state, transform }) {
  const { programs: programData, search: searchTerm } = state;

  const filteredData = filterData({
    programData,
    searchFilter,
    searchTerm,
  });

  return transform ? filteredData.map(transform) : filteredData;
}
